import { Box, Tooltip, TooltipProps, useBoolean } from '@chakra-ui/react';
import { FC } from 'react';
import { DeviceMode } from 'types';

import { useDeviceMode } from 'hooks/common';

export const TouchFriendlyTooltip: FC<TooltipProps> = ({ children, ...rest }) => {
  const device = useDeviceMode();
  const [isLabelOpen, { on, off, toggle }] = useBoolean();

  if (device === DeviceMode.Desktop) return <Tooltip {...rest}>{children}</Tooltip>;

  return (
    <Tooltip isOpen={isLabelOpen} {...rest}>
      <Box onMouseEnter={on} onMouseLeave={off} onClick={toggle}>
        {children}
      </Box>
    </Tooltip>
  );
};
