import { Box, Button, ButtonProps, Icon, IconButton } from '@chakra-ui/react';
import RcSelect from 'rc-select';
import { FC, useMemo, useState } from 'react';

import {
  Pagination as RcPagination,
  PaginationProps as RcPaginationProps,
} from 'lib/rc-pagination/src';

import { EllipsisIcon } from 'components/atoms';

import { useTranslate } from 'hooks/common';

import { LeftOutlinedIcon, RightOutlinedIcon } from 'assets/icons';

import './index.scss';
import './select.scss';


enum ItemType {
  prev = 'prev',
  next = 'next',
  page = 'page',
  jumpPrev = 'jump-prev',
  jumpNext = 'jump-next',
}

const itemRender = (page: number, type: string, current: number) => {
  const styles: ButtonProps = {
    minWidth: { base: '23px', md: '32px' },
    height: { base: '23px', md: '32px' },
    fontSize: { base: 'sm', md: 'md', lg: 'sm-md', '2xl': 'md' },
    size: 'none',
    px: 1,
  };
  if (type === ItemType.jumpNext) {
    return (
      <IconButton
        colorScheme="vulcan"
        aria-label="Jump next"
        icon={<EllipsisIcon />}
        bg="white"
        variant="outline"
        _focus={{ boxShadow: 'secondaryOutline' }}
        {...styles}
      />
    );
  }
  if (type === ItemType.jumpPrev) {
    return (
      <IconButton
        colorScheme="vulcan"
        aria-label="Jump previous"
        icon={<EllipsisIcon />}
        bg="white"
        variant="outline"
        _focus={{ boxShadow: 'secondaryOutline' }}
        {...styles}
      />
    );
  }
  if (type === ItemType.prev) {
    return (
      <IconButton
        colorScheme="vulcan"
        aria-label="Previous"
        icon={<Icon as={LeftOutlinedIcon} />}
        bg="white"
        variant="outline"
        _focus={{ boxShadow: 'secondaryOutline' }}
        {...styles}
      />
    );
  }
  if (type === ItemType.next) {
    return (
      <IconButton
        colorScheme="vulcan"
        aria-label="Next"
        icon={<Icon as={RightOutlinedIcon} />}
        bg="white"
        variant="outline"
        _focus={{ boxShadow: 'secondaryOutline' }}
        {...styles}
      />
    );
  }
  if (page === current) {
    return (
      <Button colorScheme="vulcan" _focus={{ boxShadow: 'secondaryOutline' }} {...styles}>
        {page}
      </Button>
    );
  }
  return (
    <Button
      colorScheme="vulcan"
      bg="white"
      variant="outline"
      _focus={{ boxShadow: 'secondaryOutline' }}
      {...styles}
    >
      {page}
    </Button>
  );
};

export type Paginate = {
  current: number;
  pageSize: number;
};

export type PaginationState = Paginate & {
  total: number;
  lastPage?: number;
};

export interface PaginationProps extends Omit<RcPaginationProps, 'onChange'> {
  defaultPageSize?: number;
  onChange?: (paginate: Paginate) => void;
  size?: ButtonProps['size'];
}

export const Pagination: FC<PaginationProps> = (props) => {
  const [innerPagination, setInnerPagination] = useState<Paginate>({
    current: 1,
    pageSize: 10,
  });

  const currentPagination = useMemo<Paginate>(() => {
    return {
      current: props.current || innerPagination.current,
      pageSize: props.pageSize || innerPagination.pageSize,
    };
  }, [innerPagination, props]);
  const t = useTranslate();

  const locale = {
    // Options.jsx
    items_per_page: t('value.perPage'),
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: '',
    // Pagination.jsx
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
  };

  const handleChangePage = (page: number, pageSize: number) => {
    const paginate = { current: page, pageSize };
    if (!props.current) {
      setInnerPagination(paginate);
    }
    props.onChange && props.onChange(paginate);
  };
  const size = props.size ?? 'md';

  return (
    <Box
      className={`Pagination ${size}`}
      fontSize={{ base: 'sm', md: 'md', lg: 'sm-md', '2xl': 'md' }}
      sx={{
        '& .pagination': {
          p: { base: 0, md: '10px 0' },
          flexWrap: { base: 'wrap', md: 'initial' },
          justifyContent: { base: 'flex-end', md: 'initial' },
        },
        '& .pagination > *': {
          mr: { base: '2px', md: '8px' },
          ml: { base: '2px', md: '0px' },
          mb: { base: '4px', md: 'initial' },
          '&:last-child': {
            mr: { md: '0px ' },
          },
        },
      }}
    >
      <RcPagination
        {...currentPagination}
        locale={locale}
        className={`pagination pagination--${size}`}
        itemRender={(page, type) => itemRender(page, type, currentPagination.current)}
        showSizeChanger
        {...props}
        onChange={handleChangePage}
        selectComponentClass={RcSelect}
      />
    </Box>
  );
};