import { Box, BoxProps } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';

import { TextProps, TitleProps, Typography } from 'components/atoms';

type Props = {
  value?: string;
  dateFormat?: string;
  hourFormat?: string;
  isBreakHourLine?: boolean;
  midDivider?: boolean;
  dateProps?: TitleProps;
  hourProps?: TextProps;
  defaultDisplay?: React.ReactNode;
} & BoxProps;

export const DateTimeDisplay: React.FC<Props> = ({
  value,
  dateFormat,
  hourFormat,
  isBreakHourLine,
  midDivider,
  dateProps,
  hourProps,
  defaultDisplay,
  ...props
}) => {
  if (!value) return <>{defaultDisplay}</>;
  if (dateFormat && hourFormat) {
    return (
      <Box textAlign="center" whiteSpace="nowrap" {...props}>
        <Typography.Title as="span" {...dateProps}>
          {dayjs(value).format(dateFormat)}
        </Typography.Title>
        {isBreakHourLine && <br />}
        {midDivider && ` - `}
        <Typography.Text {...hourProps}> {dayjs(value).format(hourFormat)}</Typography.Text>
      </Box>
    );
  }
  return (
    <Typography.Title whiteSpace="nowrap" {...props} {...dateProps}>
      {dayjs(value).format(dateFormat ?? hourFormat)}
    </Typography.Title>
  );
};
