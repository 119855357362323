import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from 'locales';
import { initReactI18next } from 'react-i18next';

import { userStorage } from 'configs/browser-storage';

import { Language } from 'constant/language';

import { cn } from 'locales/cn';
import { vn } from 'locales/vn';

import { ENV } from './env';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'cookie-auth-storage',
  lookup() {
    // const auth = authStorage.value?.user.language_code;
    const auth = userStorage.value?.language_code;
    return auth ?? Language.English;
  },
});

export const resources = {
  en: {
    translation: en,
  },
  cn: {
    translation: cn,
  },
  vn: {
    translation: vn,
  },
} as const;

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: ENV.ENV === 'DEV',
    fallbackLng: Language.English,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie-auth-storage', 'htmlTag'],
    },
    resources,
  });

export default i18next;
