import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { paymentApi } from 'apis/payment';

export const getPaymentSummary = createAsyncThunk(
  'payment/getPaymentSummary',
  async () => {
    const res = await paymentApi.summary();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { payment } = getState() as RootState;
      if (payment.fetchingSummaryStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getBankList = createAsyncThunk(
  'payment/getBankList',
  async () => {
    const res = await paymentApi.getBankList();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { payment } = getState() as RootState;
      if (payment.fetchingBankStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getLocationConsumedCredits = createAsyncThunk(
  'payment/consumed-credits',
  async () => {
    const res = await paymentApi.getLocationConsumedCredits();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { payment } = getState() as RootState;
      if (payment.fetchingBankStatus === 'pending') {
        return false;
      }
    },
  },
);
