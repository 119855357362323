import { ComponentProps, FC, ReactNode } from 'react';

import { SystemPermission } from 'types';

import { useHasPermissions } from 'hooks/components/auth/useHasPermissions';

export type PermissionGuardProps<C extends FC> = {
  permissions: SystemPermission | SystemPermission[];
  component?: C;
  render?: ReactNode | (() => ReactNode);
  fallback?: ReactNode;
} & ComponentProps<C>;

export const PermissionGuard = <T extends FC<any>>({
  component: Component,
  render,
  permissions,
  fallback,
  ...rest
}: PermissionGuardProps<T>) => {
  const hasPermission = useHasPermissions(permissions);
  if (!hasPermission) return fallback ?? null;
  if (Component) return <Component {...rest} />;
  if (typeof render === 'function') return render();
  return render ?? rest.children ?? null;
};
