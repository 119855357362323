import { Styles } from '@chakra-ui/theme-tools';

import { createResponsiveStyle } from './foundations/breakpoints';

export const styles: Styles = {
  global: () => ({
    body: {
      color: 'secondary',
      fontSize: createResponsiveStyle({ base: 'sm', lg: 'sm-md', '2xl': 'md' }),
      '& .date-picker-input': {
        fontSize: createResponsiveStyle({ base: 'sm', md: 'md', lg: 'sm-md', '2xl': 'md' }),
      },
    },
    '*::placeholder': {
      color: '#707070',
    },
  }),
};
