import classNames from 'classnames';
import React from 'react';

import { Text, TextProps } from './Text';

export interface TitleProps extends TextProps {
  htmlFor?: string;
  capitalize?: boolean;
  uppercase?: boolean;
  // fontSize?: { base?: string, md?: string, lg?: string, '2xl'?: string };
  // as: string;
  // fontFamily: string;
  // className: string;
}

export const Title: React.FC<TitleProps> = ({
  children,
  capitalize,
  uppercase,
  className,
  ...props
}) => {
  return (
    <Text
      className={classNames(className, {
        'text-capitalize': capitalize,
        'text-uppercase': uppercase,
      })}
      {...props} // Pass all props except children to Text component
    >
      {children}
    </Text>
  );
};
