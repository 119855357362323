import { Circle, HStack, SquareProps, StackProps } from '@chakra-ui/react';
import React from 'react';

import { TextProps, Typography } from './Typography';

// export const STATUS_COLOR = {
//   active: colors.success,
//   open: colors.tango[300],
//   opening: colors.tango[300],
//   na: 'white',
//   no_show: colors.danger,
//   completed: colors.secondary,
//   cancel: colors.danger,
//   canceled: colors.danger,
//   cancelled: colors.danger,
//   processed: colors.secondary,
//   accepted: colors.secondary,
//   rejected: colors.danger,
//   pending: colors.vulcan[300],
//   approved: colors.secondary,
//   selected: colors.success,
//   hired: colors.secondary,
//   applied: colors.tango[300],
//   'self-canceled': colors.vulcan[300],
//   'self-cancelled': colors.vulcan[300],
//   'no approved': colors.danger,
//   'no show': colors.danger,
//   unselected: colors.danger,
//   'rejected by manager': colors.danger,
//   'time-overlap rejected': colors.danger,
//   'admin-disabling rejected': colors.danger,
// };

export interface StatusCircleProps extends StackProps {
  status: string;
  title?: string;
  size?: SquareProps['size'];
  titleProps?: TextProps;
  circleProps?: SquareProps;
  color?: string;
}

export const StatusCircle: React.FC<StatusCircleProps> = ({
  status = '',
  size = '15px',
  titleProps,
  title,
  circleProps,
  color,
  ...props
}) => {
  let styles: SquareProps = {
    border: '1px solid',
    bg: color ?? 'white',
    borderColor: color ?? 'secondary',
  };

  const displayLabel = status.replaceAll('_', ' ') || 'N/A';
  return (
    <HStack {...props}>
      <Circle size={size} {...styles} {...circleProps} />
      <Typography.Text lineHeight="1.2" {...titleProps}>
        {title ?? displayLabel}
      </Typography.Text>
    </HStack>
  );
};
