import { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { getEndOfBeforeToday } from 'utils';

export const useDisableBeforeToday = (refresh?: number) => {
  const [endOfBeforeToday, setEndOfBeforeToday] = useState(getEndOfBeforeToday());
  const intervalId = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setEndOfBeforeToday(getEndOfBeforeToday());
    }, refresh || 1000 * 60);
    return () => {
      if (intervalId.current !== null && intervalId.current !== undefined) {
        clearInterval(intervalId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (current: Dayjs) => current < endOfBeforeToday;
};
