import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hqStorage } from 'configs/browser-storage';
import { IUser } from 'models/user';

interface SuperHqState {
  superHq: IUser | null;
}

const initialSuperHq = hqStorage.get()?.data || null;

const initialState: SuperHqState = {
  superHq: initialSuperHq,
};

const superHqSlice = createSlice({
  name: 'hq',
  initialState,
  reducers: {
    setHq(state, action: PayloadAction<IUser | null>) {
      state.superHq = action.payload;
    },
    updateSuperHq(state, action: PayloadAction<Partial<IUser>>) {
      if (state.superHq) {
        state.superHq = { ...state.superHq, ...action.payload };
      }
    },
    clearHq(state) {
      return { ...state, superHq: null};
    },
  },
});

export const { setHq, updateSuperHq, clearHq } = superHqSlice.actions;
export default superHqSlice.reducer;

export * from './selector';
