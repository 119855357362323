import { useSelector } from 'react-redux';

import { deviceSelector, screenSelector } from 'store/modules/config';

export const useDeviceMode = () => {
  const storedMode = useSelector(deviceSelector);
  return storedMode;
};
export const useScreenMode = () => {
  const storedMode = useSelector(screenSelector);
  return storedMode;
};
