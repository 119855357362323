import { Button, FormControl, FormErrorMessage, HStack, Input, VStack } from '@chakra-ui/react';
import { dateDisplay } from 'constant';
import { Dayjs } from 'dayjs';
import i18next from 'i18next';
import noop from 'lodash/noop';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BooleanStatus, VoidFunc } from 'types';
import { isDollar, isNullable } from 'utils';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';

import { ControlledCheckBox, Typography } from 'components/atoms';
import DatePicker from 'components/atoms/DatePicker';

import { useTranslate } from 'hooks/common';

import { mapValuesToFormState } from 'utils/form';

import { FilterResultData } from './FilterResult';

export type CreditHistoryByAreaFilterData = {
  recent: BooleanStatus;
  dateRange: [Dayjs, Dayjs] | null;
  minAvailableCredit: number | '';
  maxAvailableCredit: number | '';
  minAssignedCredit: number | '';
  maxAssignedCredit: number | '';
};

type Props = {
  initialValues?: Partial<CreditHistoryByAreaFilterData> | null;
  visible: boolean;
  onConfirm: (originData: CreditHistoryByAreaFilterData) => void;
  onReset: VoidFunc;
  onFinish: VoidFunc;
};

export const mapCreditHistoryByAreaFilterToView = (
  data?: Partial<CreditHistoryByAreaFilterData> | null,
): FilterResultData | null => {
  if (!data) return null;
  const items: FilterResultData = [];
  const {
    recent,
    dateRange,
    minAvailableCredit,
    maxAvailableCredit,
    minAssignedCredit,
    maxAssignedCredit,
  } = data;
  if (recent) {
    items.push({
      data: i18next.t('value.recentAddedLocations'),
      key: 'recent',
      value: 'recent',
    });
  }
  if (dateRange) {
    const [start, end] = dateRange;
    if (start && end) {
      items.push({
        groups: [
          {
            label: i18next.t('label.from'),
            data: start.format(dateDisplay()),
            key: 'startDate',
            value: start,
          },
          {
            label: i18next.t('label.to'),
            data: end.format(dateDisplay()),
            key: 'endDate',
            value: end,
          },
        ],
        key: 'dateRange',
        allowActions: true,
      });
    }
  }
  if (!isNullable(minAvailableCredit, { includeEmpty: true }))
    items.push({
      label: i18next.t('label.minAvailableCredits'),
      data: minAvailableCredit,
      key: 'minAvailableCredit',
      value: minAvailableCredit,
    });
  if (!isNullable(maxAvailableCredit, { includeEmpty: true }))
    items.push({
      label: i18next.t('label.maxAvailableCredits'),
      data: maxAvailableCredit,
      key: 'maxAvailableCredit',
      value: maxAvailableCredit,
    });
  if (!isNullable(minAssignedCredit, { includeEmpty: true }))
    items.push({
      label: i18next.t('label.minAssignedCredits'),
      data: minAssignedCredit,
      key: 'minAssignedCredit',
      value: minAssignedCredit,
    });
  if (!isNullable(maxAssignedCredit, { includeEmpty: true }))
    items.push({
      label: i18next.t('label.maxAssignedCredits'),
      data: maxAssignedCredit,
      key: 'maxAssignedCredit',
      value: maxAssignedCredit,
    });

  if (!items.length) return null;
  return items;
};

export const creditHistyoryByAreaFilterDefaultValues: CreditHistoryByAreaFilterData = {
  recent: 0,
  dateRange: null,
  minAvailableCredit: '',
  maxAvailableCredit: '',
  minAssignedCredit: '',
  maxAssignedCredit: '',
};

export const CreditHistoryByAreaFilter: React.FC<Props> = ({
  initialValues,
  onConfirm,
  onReset,
  onFinish = noop,
  visible,
}) => {
  const t = useTranslate();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    formState: { errors, isDirty },
    setValue,
    clearErrors,
  } = useForm<CreditHistoryByAreaFilterData>({
    defaultValues: creditHistyoryByAreaFilterDefaultValues,
    mode: 'onChange',
  });

  const handleSubmitFilterForm = (data: CreditHistoryByAreaFilterData) => {
    onConfirm(data);
    onFinish();
  };

  const handleResetFilterDropdown = () => {
    onFinish();
    reset();
    onReset();
  };

  const minCredit = watch('minAvailableCredit');
  const maxCredit = watch('maxAvailableCredit');
  const minConsumed = watch('minAssignedCredit');
  const maxConsumed = watch('maxAssignedCredit');

  useEffect(() => {
    if (!visible) return;
    if (initialValues) {
      clearErrors();
      mapValuesToFormState(initialValues, setValue);
    } else if (isDirty) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const fontSizes = createResponsiveStyle({ base: 'sm', md: 'md', lg: 'sm-md', '2xl': 'md' });

  return (
    <form onSubmit={handleSubmit(handleSubmitFilterForm)}>
      <VStack>
        <FormControl>
          <HStack spacing="20px">
            <Typography.Title fontSize={fontSizes} as="label" fontFamily="italic" htmlFor="recent">
              {t('label.recentAdded')}
            </Typography.Title>
            <ControlledCheckBox id="recent" bg="white" size="lg" name="recent" control={control} />
          </HStack>
        </FormControl>
        <FormControl position="relative">
          <FormControl>
            <Typography.Title
              fontSize={fontSizes}
              as="label"
              fontFamily="italic"
              htmlFor="dateRange"
            >
              {t('label.assigningDate')}
            </Typography.Title>
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { ref, ...res } }) => {
                return (
                  <DatePicker.RangePicker
                    {...res}
                    // showTime={{ format: FULL_HOUR_DISPLAY }}
                    picker="date"
                    placeholder={[t('label.startDate'), t('label.endDate')]}
                    format={dateDisplay()}
                    id="dateRange"
                    style={{ background: 'white' }}
                  />
                );
              }}
            />
          </FormControl>
        </FormControl>
        <FormControl isInvalid={!!errors.minAssignedCredit}>
          <Typography.Title
            fontSize={fontSizes}
            as="label"
            fontFamily="italic"
            htmlFor="minAssignedCredit"
          >
            {t('label.minimumAssignedAmount')}
          </Typography.Title>
          <Input
            id="minAssignedCredit"
            {...register('minAssignedCredit', {
              validate: (value) => {
                if (value && maxConsumed && +value >= +maxConsumed) {
                  return t('message.minimumLessThanMaximumAvailableConsumed');
                }
                if (value && !isDollar(value)) return t('message.onlyAllowTwoDecimalPlace');
                return undefined;
              },
              deps: ['maxAssignedCredit'],
            })}
            placeholder="0.00"
            background="white"
            // type="number"
            step="any"
          />
          <FormErrorMessage>{errors.minAssignedCredit?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.maxAssignedCredit}>
          <Typography.Title
            fontSize={fontSizes}
            as="label"
            fontFamily="italic"
            htmlFor="maxAssignedCredit"
          >
            {t('label.maximumAssignedAmount')}
          </Typography.Title>
          <Input
            id="maxAssignedCredit"
            {...register('maxAssignedCredit', {
              validate: (value) => {
                if (value && minConsumed && +value <= +minConsumed) {
                  return t('message.maximumGreaterThanMinimumAvailableConsumed');
                }
                if (value && !isDollar(value)) return t('message.onlyAllowTwoDecimalPlace');
                return undefined;
              },
              deps: ['minAssignedCredit'],
            })}
            placeholder="0.00"
            background="white"
            // type="number"
            step="any"
          />
          <FormErrorMessage>{errors.maxAssignedCredit?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.minAvailableCredit}>
          <Typography.Title
            fontSize={fontSizes}
            as="label"
            fontFamily="italic"
            htmlFor="minAvailableCredit"
          >
            {t('label.minimumAvailableCredits')}
          </Typography.Title>
          <Input
            id="minAvailableCredit"
            {...register('minAvailableCredit', {
              validate: (value) => {
                if (value && maxCredit && +value >= +maxCredit) {
                  return t('message.validateMinLessThanMaxCredits');
                }
                if (value && !isDollar(value)) return t('message.onlyAllowTwoDecimalPlace');
                return undefined;
              },
              deps: ['maxAvailableCredit'],
            })}
            placeholder="0.00"
            background="white"
            // type="number"
            step="any"
          />
          <FormErrorMessage>{errors.minAvailableCredit?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.maxAvailableCredit}>
          <Typography.Title
            fontSize={fontSizes}
            as="label"
            fontFamily="italic"
            htmlFor="maxAvailableCredit"
          >
            {t('label.maximumAvailableCredits')}
          </Typography.Title>
          <Input
            id="maxAvailableCredit"
            {...register('maxAvailableCredit', {
              validate: (value) => {
                if (value && minCredit && +value <= +minCredit) {
                  return t('message.validateMaxGreaterThanMinCredits');
                }
                if (value && !isDollar(value)) return t('message.onlyAllowTwoDecimalPlace');
                return undefined;
              },
              deps: ['minAvailableCredit'],
            })}
            placeholder="0.00"
            background="white"
            // type="number"
            step="any"
          />
          <FormErrorMessage>{errors.maxAvailableCredit?.message}</FormErrorMessage>
        </FormControl>

        <HStack alignSelf="flex-end" pt={{ base: '15px', md: '30px' }} spacing="20px">
          <Button
            variant="primary"
            type="submit"
            fontSize={fontSizes}
            lineHeight="1"
            size="xs"
            px="24px"
            textTransform="uppercase"
          >
            {t('button.confirm')}
          </Button>
          <Typography.Text
            cursor="pointer"
            fontSize={fontSizes}
            py="2px"
            onClick={handleResetFilterDropdown}
          >
            {t('button.resetFilters')}
          </Typography.Text>
        </HStack>
      </VStack>
    </form>
  );
};
