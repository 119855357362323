import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { applicantReducer } from './modules/applicant';
import { authReducer } from './modules/auth';
import superHqReducer from './modules/superHq-user'
import { companyReducer } from './modules/company';
import { configReducer } from './modules/config';
import { creditReducer } from './modules/credit';
import { dashboardReducer } from './modules/dashboard';
import { educationalInstituteReducer } from './modules/educational-institute';
import { hqUserReducer } from './modules/hq-user';
import { jobReducer } from './modules/job';
import { jobApplicantReducer } from './modules/job-applicant';
import { jobPostReducer } from './modules/job-posting';
import { jobTemplateReducer } from './modules/job-template';
import { jobTypeReducer } from './modules/job-type';
import { keptQueryReducer } from './modules/kept-query';
import { locationReducer } from './modules/location';
import { locationProfileReducer } from './modules/location-profile';
import { managerReducer } from './modules/manager';
import { modalReducer } from './modules/modal';
import { notificationReducer } from './modules/notification';
import { paymentReducer } from './modules/payment';
import { userRoleReducer } from './modules/user-role';

const rootReducer = combineReducers({
  auth: authReducer,
  superHq: superHqReducer,
  company: companyReducer,
  educationInstitute: educationalInstituteReducer,
  jobType: jobTypeReducer,
  hqUser: hqUserReducer,
  location: locationReducer,
  applicant: applicantReducer,
  config: configReducer,
  jobTemplate: jobTemplateReducer,
  manager: managerReducer,
  jobPost: jobPostReducer,
  jobApplicant: jobApplicantReducer,
  credit: creditReducer,
  job: jobReducer,
  locationProfile: locationProfileReducer,
  payment: paymentReducer,
  dashboard: dashboardReducer,
  notification: notificationReducer,
  userRole: userRoleReducer,
  keptQuery: keptQueryReducer,
  modal: modalReducer,
});
const store = configureStore({
  reducer: rootReducer,
  // other store configurations...
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
