import { BoxProps, Collapse, Td, Tr, useBoolean } from '@chakra-ui/react';
import classNames from 'classnames';
import get from 'lodash/get';
import React, { Fragment } from 'react';

import { Typography } from 'components/atoms';

import { TableProps } from '.';

interface TableRowProps<RecordType extends object = any>
  extends Pick<TableProps<RecordType>, 'rowStyleProps' | 'columns' | 'expandable'> {
  record: RecordType;
  recordIndex: number;
  isActiveRow: boolean;
  activeCellStyles?: BoxProps;
  scrollDirection?: 'left' | 'right' | 'all' | null;
}

export const TableRow = <RecordType extends object = any>(props: TableRowProps<RecordType>) => {
  const { columns, record, rowStyleProps, recordIndex, isActiveRow, activeCellStyles, expandable } =
    props;
  const { rowExpandable, expandedRowRender } = expandable || {};

  const rowProps =
    typeof rowStyleProps === 'function' ? rowStyleProps(record, recordIndex) : rowStyleProps;
  const canExpand =
    typeof rowExpandable === 'function' ? rowExpandable(record, recordIndex) : rowExpandable;

  const [isExpanded, { toggle }] = useBoolean();

  const getPositionSticky = (colIndex: number) => {
    if (colIndex === 0)
      return {
        left: 0,
      };
    if (colIndex === columns.length - 1)
      return {
        right: 0,
      };
    let left = columns.slice(0, colIndex).reduce((acc, curr) => {
      const colWidth = curr.fixed?.columnWidth;
      const colDirection = curr.fixed?.direction === 'left';
      if (colDirection && colWidth) return acc + colWidth;
      return acc;
    }, 0);

    let right = columns.slice(-(columns.length - 1 - colIndex)).reduce((acc, curr) => {
      const colWidth = curr.fixed?.columnWidth;
      const colDirection = curr.fixed?.direction === 'right';
      if (colDirection && colWidth) return acc + colWidth;
      return acc;
    }, 0);

    return {
      left,
      right,
    };
  };

  const row = (
    <Tr
      {...rowProps}
      className={classNames({ 'row-active': isActiveRow }, rowProps?.className)}
      background="#fff"
      boxShadow="5px 6px 15px -8px #f05e144d"
    >
      {columns.map((column, idx) => {
        const canSticky = column.fixed?.direction;
        const width = column.fixed?.columnWidth ?? column.width;
        const { left, right } = getPositionSticky(idx);
        return (
          <Td
            className={classNames({ 'fixed-column': canSticky })}
            left={canSticky === 'left' ? left + 'px' : 'initial'}
            right={canSticky === 'right' ? right + 'px' : 'initial'}
            background={canSticky && rowProps?.className === 'disable' ? 'disable' : 'white'}
            key={column.key}
            width={width}
            maxWidth={column.maxWidth}
            minWidth={column.minWidth}
            wordBreak={columns.length === 1 ? 'break-word' : 'normal'}
            {...(typeof column.cellStyleProps === 'function'
              ? column.cellStyleProps(record, recordIndex)
              : column.cellStyleProps)}
            {...(isActiveRow ? activeCellStyles : undefined)}
            // {...styleSticky(column)}
          >
            {column.render ? (
              column.render(get(record, column.dataIndex || ''), record, recordIndex, {
                isActive: isActiveRow,
                isExpanded,
                toggleExpand: toggle,
              })
            ) : (
              <Typography.Text {...column.innerCellStyleProps}>
                <>
                  {() => {
                    const value = get(record, column.dataIndex || '');
                    const renderedValue = value as React.ReactNode;
                    return renderedValue;
                  }}
                </>
              </Typography.Text>
            )}
          </Td>
        );
      })}
    </Tr>
  );

  const expandRow = (
    <Tr
      className="expand-row"
      style={{
        boxShadow: 'none',
        overflow: 'auto',
        backgroundColor: 'transparent',
        borderRadius: '0px',
      }}
    >
      {canExpand && expandedRowRender && (
        <Td className="no-padding" p="0px" colSpan={columns.length}>
          <Collapse unmountOnExit={true} in={isExpanded}>
            {expandedRowRender(record, recordIndex)}
          </Collapse>
        </Td>
      )}
    </Tr>
  );

  if (!expandable) return row;

  return (
    <Fragment>
      {row}
      {expandRow}
    </Fragment>
  );
};
