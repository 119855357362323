import get from 'lodash/get';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useIsMounted } from '.';

export const useMergedState = <T>(initialValues: T, dirtied?: boolean, onUpdate?: () => void) => {
  const [state, setState] = useState<T>(initialValues);
  const isDirty = useRef(dirtied ?? false);
  const stateRef = useRef<T>(initialValues);
  const isMounted = useIsMounted();

  const updateState = (values: Partial<T> | ((values: T) => Partial<T>)) => {
    if (!isDirty.current) {
      isDirty.current = true;
    }
    if (isMounted() === false) {
      return;
    }
    onUpdate && onUpdate();
    setState((prev) => {
      let next = prev;
      if (typeof values === 'function') {
        next = { ...next, ...values(prev) };
      } else {
        next = { ...prev, ...values };
      }
      stateRef.current = next;
      return next;
    });
  };

  const registerUpdate = (key: keyof T, accessPath?: string) => (value: any) => {
    const updatedValue = accessPath ? get(value, accessPath) : value;
    updateState({ [key]: updatedValue } as any);
  };

  const reset = (dirty?: boolean) => {
    if (isDirty.current) {
      setState(initialValues);
      isDirty.current = dirty ?? false;
    }
  };

  const resetField = (key: keyof T, config?: { dirtied?: boolean }) => () => {
    const { dirtied = true } = config || {};
    if (isDirty.current || dirtied) {
      if (!isDirty.current) isDirty.current = true;
      setState((prev) => {
        if (prev[key] === null) return prev;
        return { ...prev, [key]: null };
      });
    }
  };

  const getIsDirty = useCallback(() => {
    return isDirty.current;
  }, []);

  const tool = {
    update: updateState,
    set: setState,
    isDirty: getIsDirty,
    register: registerUpdate,
    reset,
    resetField,
  };

  return useMemo<[typeof state, typeof tool, typeof stateRef]>(
    () => [state, tool, stateRef],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty, state],
  );
};
