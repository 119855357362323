const percentToHex = (percent: number) => {
  const boundPercent = Math.max(0, Math.min(100, percent)); // bound percent from 0 to 100
  const intValue = Math.round((boundPercent / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};

export const addHexOpacity = (hexColor: string, percent: number) => {
  return `${hexColor}${percentToHex(percent)}`;
};
