import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EducationalInstituteState } from './type';

const initialState: EducationalInstituteState = {
  summary: {
    total: 0,
    init: false,
  },
  options: [],
};

export const { actions: educationalInstituteActions, reducer: educationalInstituteReducer } =
  createSlice({
    name: 'educationalInstitute',
    initialState,
    reducers: {
      updateSummary(
        state,
        action: PayloadAction<Omit<EducationalInstituteState['summary'], 'init'>>,
      ) {
        const { payload } = action;

        state.summary = {
          ...payload,
          total: payload.total,
          init: false,
        };
      },
      updateOption(state, action: PayloadAction<EducationalInstituteState['options']>) {
        const { payload } = action;
        state.options = payload;
      },
    },
  });
export * from './type';
export * from './selector';
