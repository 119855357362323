import { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { getEndOfToday } from 'utils';

export const useDisableAfterToday = (refresh?: number) => {
  const [endOfToday, setEndOfToday] = useState(getEndOfToday());
  const intervalId = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setEndOfToday(getEndOfToday());
    }, refresh || 1000 * 60);
    return () => {
      if (intervalId.current !== null && intervalId.current !== undefined) {
        clearInterval(intervalId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (current: Dayjs) => current > endOfToday;
};
