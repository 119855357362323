import { Box, HStack, StackProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { DeviceMode } from 'types';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';

import { Typography } from 'components/atoms';

import { useDeviceMode } from 'hooks/common';

export interface Section {
  label: string | ReactNode;
  count: number | string;
}

export type PageHeaderProps = {
  title: string;
  optionsSection?: Section[];
  additionRightContent?: ReactNode;
  additionEndContent?: ReactNode;
  selectionsStyleProps?: StackProps;
  endStyleProps?: StackProps;
  hideSection?: boolean;
} & StackProps;

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  optionsSection,
  justify,
  additionRightContent,
  additionEndContent,
  pb,
  selectionsStyleProps,
  hideSection = false,
  endStyleProps,
  ...props
}) => {
  const device = useDeviceMode();
  const isStartSection = (optionsSection?.length ?? 0) <= 2;

  return (
    <HStack
      className="PageHeader"
      spacing={{ base: 0, lg: '16px', '2xl': '24px' }}
      pb={pb}
      {...props}
    >
      {device === DeviceMode.Desktop && (
        <Box {...props}>
          <Typography.Title
            fontSize={{ base: 'lg', lg: '2xl', '2xl': '3xl' }}
            uppercase
            lineHeight="1.2"
          >
            {title}
          </Typography.Title>
        </Box>
      )}

      <HStack
        spacing="1rem"
        className="listSection"
        flex={1}
        justify={
          justify ?? {
            base: 'center',
            md: isStartSection ? 'flex-start' : 'center',
            lg: 'initial',
          }
        }
        {...selectionsStyleProps}
      >
        {optionsSection &&
          optionsSection.map((item: Section, index: number) => (
            <HStack
              key={index}
              flexDirection={createResponsiveStyle({
                base: 'column-reverse',
                md: 'column-reverse',
                lg: 'row',
                xl: 'row',
                '2xl': 'row',
              })}
              className="sub-title"
            >
              <Box textAlign="center">{item.label}</Box>
              <Typography.Title
                fontSize={{ base: 'lg', lg: 'xl', '2xl': '2xl' }}
                ml="0"
                variant="bold"
              >
                {item.count}
              </Typography.Title>
            </HStack>
          ))}
        {additionRightContent}
      </HStack>

      <HStack justify={justify} {...endStyleProps}>
        {additionEndContent}
      </HStack>
    </HStack>
  );
};
