import { formAnatomy as parts } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleRequiredIndicator = (props: any) => {
  return {
    marginStart: 1,
    color: mode('red.500', 'red.300')(props),
  };
};

const baseStyleHelperText = (props: any) => {
  return {
    mt: 2,
    color: mode('gray.500', 'whiteAlpha.600')(props),
    lineHeight: 'normal',
    fontSize: 'sm',
  };
};

const baseStyle = (props: any) => ({
  container: { width: '100%', position: 'relative' },
  requiredIndicator: baseStyleRequiredIndicator(props),
  helperText: baseStyleHelperText(props),
});

export const FormControl = {
  parts: parts.keys,
  baseStyle,
  variants: {
    addBottomSpace: {
      container: {
        mb: {
          base: '10px',
          lg: 0,
        },
      },
    },
  },
};

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'heading',
    fontSize: { base: 'sm', md: 'md', lg: 'sm-lg', '2xl': 'lg' },
    color: 'secondary',
    marginInlineEnd: '0px',
    mb: '0',
    lineHeight: 1,
  },
  variants: {
    addBottomSpace: {
      mb: '5px',
    },
  },
};

export const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      mt: { base: '4px', md: '5px', '2xl': '6px' },
      ml: '2px',
      lineHeight: { base: 'normal', lg: 1 },
    },
  },
  variants: {
    floatOnDesktop: {
      text: {
        position: { base: 'initial', lg: 'absolute' },
      },
    },
  },
};
