import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { jobApi } from 'apis/job';

import { Role } from 'models/user';

import { currentRoleSelector } from '../auth';

export const getAllTemplates = createAsyncThunk(
  'job/getAllTemplates',
  async () => {
    const res = await jobApi.getTemplateOptions();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { job } = getState() as RootState;
      if (job.fetchTemplateStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getAssignedLocationOptions = createAsyncThunk(
  'job/getAssignedLocationOptions',
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const role = currentRoleSelector(rootState);
    if (role === Role.AreaManager || role === Role.HqManager) {
      const res = await jobApi.getLocationOptions(role);
      return res.data;
    }
    return [];
  },
  {
    condition: (_, { getState }) => {
      const { job } = getState() as RootState;
      if (job.fetchLocationStatus === 'pending') {
        return false;
      }
    },
  },
);
export const getTotalJobs = createAsyncThunk(
  'job/getJobSummary',
  async () => {
    const res = await jobApi.getJobSummary();
    return res.data.totalJobs;
  },
  {
    condition: (_, { getState }) => {
      const { job } = getState() as RootState;
      if (job.fetchTotalJob === 'pending') {
        return false;
      }
    },
  },
);

export const getTotalJobsHistory = createAsyncThunk(
  'job/getJobHistorySummary',
  async (_, thunkApi) => {
    const res = await jobApi.getJobHistorySummary();
    return res.data.number_total;
  },
  {
    condition: (_, { getState }) => {
      const { job } = getState() as RootState;
      if (job.fetchTotalJobHistory === 'pending') {
        return false;
      }
    },
  },
);
