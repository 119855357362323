import { createSlice } from '@reduxjs/toolkit';

import { getBankList, getLocationConsumedCredits, getPaymentSummary } from './action';
import { PaymentState } from './type';

const initialState: PaymentState = {
  summary: {
    paymentTotal: 0,
    processedTotal: 0,
    unpaidTotal: 0,
  },
  fetchingSummaryStatus: null,
  banks: null,
  fetchingBankStatus: null,
  consumedCredits: {
    consumed_credits_previous_month: 0,
    consumed_credits_current_month: 0,
  },
  fetchingConsumedCreditStatus: null,
};

const { actions, reducer: paymentReducer } = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentSummary.fulfilled, (state, action) => {
      state.summary = action.payload;
      state.fetchingSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getPaymentSummary.pending, (state, action) => {
      state.fetchingSummaryStatus = action.meta.requestStatus;
    });
    builder.addCase(getPaymentSummary.rejected, (state, action) => {
      state.fetchingSummaryStatus = action.meta.requestStatus;
    });

    builder.addCase(getBankList.fulfilled, (state, action) => {
      state.banks = action.payload;
      state.fetchingBankStatus = action.meta.requestStatus;
    });
    builder.addCase(getBankList.pending, (state, action) => {
      state.fetchingBankStatus = action.meta.requestStatus;
    });
    builder.addCase(getBankList.rejected, (state, action) => {
      state.fetchingBankStatus = action.meta.requestStatus;
    });

    builder.addCase(getLocationConsumedCredits.fulfilled, (state, action) => {
      state.consumedCredits = action.payload;
      state.fetchingConsumedCreditStatus = action.meta.requestStatus;
    });
    builder.addCase(getLocationConsumedCredits.pending, (state, action) => {
      state.fetchingConsumedCreditStatus = action.meta.requestStatus;
    });
    builder.addCase(getLocationConsumedCredits.rejected, (state, action) => {
      state.fetchingConsumedCreditStatus = action.meta.requestStatus;
    });
  },
});
const paymentActions = {
  ...actions,
  getPaymentSummary,
  getBankList,
  getLocationConsumedCredits,
};
export { paymentReducer, paymentActions };
export * from './type';
export * from './selector';
