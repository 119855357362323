import noop from 'lodash/noop';

import { useQuery } from '.';
import { useToasts } from './useToasts';

export const useExportQuery = <Func extends (...any: any[]) => any = any>(
  exportFunc: Func,
  filename: ((payload: any, response: any) => string) | string,
  config?: {
    defaultErrorMessage?: string;
    onStart?: (...payload: any[]) => void;
    onFinish?: (...payload: any[]) => void;
  },
) => {
  const { defaultErrorMessage, onStart = noop, onFinish = noop } = config || {};
  const { notiFailed } = useToasts();
  const [, loading, , tool] = useQuery<null, null, Func>(null, exportFunc, {
    preventInitCall: true,
  });

  const downloadFile = (data: any, filename: string) => {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  };

  const exportData = async (payload: any) => {
    try {
      onStart(payload);
      const res = await tool.refetch(payload);
      if (!res.status) throw new Error(res.message);
      downloadFile(res.data, typeof filename === 'function' ? filename(payload, res) : filename);
      onFinish(payload);
      return res;
    } catch (error: any) {
      onFinish(payload);
      notiFailed(error.message || (defaultErrorMessage ?? 'Download Failed'));
      return error;
    }
  };
  const result: [Func, boolean, typeof tool] = [exportData as Func, loading, tool];
  return result;
};
