import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { VoidFunc } from 'types';

import { Typography } from 'components/atoms';

import { useTranslate } from 'hooks/common';

import { useTotalJobs } from './useTotalJobs';

export const useJobPostingSummary = () => {
  const [total, fetchTotalJobs] = useTotalJobs();
  const t = useTranslate();

  const sections: Array<{
    label: JSX.Element;
    count: number;
  }> = [
    {
      label: (
        <Typography.Text>
          <Trans t={t} i18nKey={'message.totalNumberOfJobs'} components={[<br key={0} />]} />
        </Typography.Text>
      ),
      count: total ?? 0,
    },
  ];
  return useMemo<[typeof sections, VoidFunc, number]>(() => {
    return [sections, fetchTotalJobs, total];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
};
