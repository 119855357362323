import { httpClient } from 'apis';
import {
  DataResponse,
  ListAxiosResponse,
  ObjectAxiosResponse,
  Payload,
  PrimitiveResponse,
  Query,
} from 'types';

import { INotification } from 'models/notification';

import { mapPaginationState, mapQueryToParams } from './utils';

const entity = 'notification';
const basePath = `portal/${entity}` as const;

export const notificationApi = {
  async list(payload: Payload<null, null, Query>): Promise<DataResponse<INotification[]>> {
    try {
      const { pagination, query } = payload;
      const { search, sort } = query ?? {};
      const res: ListAxiosResponse<INotification> = await httpClient.get(`${basePath}/index`, {
        params: mapQueryToParams({ search, sort }, pagination),
      });
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getUnreadCount(): Promise<PrimitiveResponse<number>> {
    try {
      const res: ObjectAxiosResponse<{
        total_unread: number;
      }> = await httpClient.get(`${basePath}/unread-count`);
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.total_unread,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
};

export type NotificationApi = typeof notificationApi;
