import { SetValueConfig, UseFormSetValue } from 'react-hook-form';

export const mapValuesToFormState = <T extends Record<string | number, any>>(
  data: T,
  setter: UseFormSetValue<Required<T>>,
  options?: SetValueConfig,
) => {
  for (const key in data) {
    setter(key as any, data[key] as any, options);
  }
};
