import { ENV } from 'configs';
import { AuthRouteGuard, RolePermissionRouteGuard } from 'guards';
import { Fragment, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import { DeviceMode } from 'types';
import { isIOS } from 'utils';

import { Loading } from 'components/molecules';
import { MainLayout } from 'components/templates/MainLayout';

import { useTranslate } from 'hooks/common';
import { useTrackingDeviceScreen } from 'hooks/common/useTrackingDeviceScreen';

import Error404 from 'pages/Error404';

import { Config } from 'containers/Config';
import { ErrorBoundary } from 'containers/ErrorBoundary';
import { ProfileProvider } from 'containers/ProfileEditContainer';

import { RouteConfig, publicRoutes } from './routes';
import { useAuthorizedRoutes } from './useAuthorizedRoutes';

const renderRoute = (routes: RouteConfig[], t: any) => {
  return routes.map((route) => {
    const {
      element: Element,
      guard: Guard = Fragment,
      roles,
      permissions,
      excludeMainLayout,
    } = route;
    const shouldExcludeMainLayout = excludeMainLayout === true;
    let guardProps: any = {};
    if (Guard === RolePermissionRouteGuard) {
      guardProps = { requiredRoles: roles, requiredPermissions: permissions };
    }
    let metaDataTitle = route.metaData?.title;
    if (metaDataTitle) {
      metaDataTitle = t(metaDataTitle) || metaDataTitle;
    } else {
      metaDataTitle = ENV.INSTANCE.VIETNAM ? 'Jod' : 'Jobs-On-Demand';
    }
    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Guard {...guardProps}>
            <Helmet>
              <title>{metaDataTitle}</title>
            </Helmet>
            <Config
              pageTitle={t(route.title) ?? route.title}
              pageBreakLineTitle={route.breakLineTitle}
            />
            {shouldExcludeMainLayout ? (
              <ErrorBoundary>{Element ? <Element /> : <Error404 />}</ErrorBoundary>
            ) : (
              <MainLayout>
                <ErrorBoundary>{Element ? <Element /> : <Error404 />}</ErrorBoundary>
              </MainLayout>
            )}
          </Guard>
        }
      />
    );
  });
};

const AuthorizedRoutes = () => {
  const routes = useAuthorizedRoutes();
  const t = useTranslate();
  return (
    <Suspense fallback={<Loading h="100%" tip={t('title.loadPage')} />}>
      <Routes>
        {renderRoute(routes, t)} <Route path="*" element={<Error404 />}></Route>
      </Routes>
    </Suspense>
  );
};

const RoutesExport = () => {
  const { device } = useTrackingDeviceScreen();
  const t = useTranslate();
  // const user = useSelector(currentUserSelector);
  // if (!localStorage.getItem('clevertap') && user) {
  //   clevertap.onUserLogin.push({
  //     Site: {
  //       Name: `${user.first_name} ${user.last_name}`,
  //       Identity: user.id,
  //       Email: user.email,
  //     },
  //   });
  //   localStorage.setItem('clevertap', 'true');
  // }
  return (
    <>
      {device === DeviceMode.Mobile && isIOS() && (
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>
      )}
      <Suspense fallback={<Loading h="100%" tip={t('title.loadPage')} height="100vh" />}>
        <ProfileProvider>
          <Routes>{renderRoute(publicRoutes, t)}</Routes>
          <AuthRouteGuard minH="100vh">
            <AuthorizedRoutes />
          </AuthRouteGuard>
        </ProfileProvider>
      </Suspense>
    </>
  );
};

export * from './routes';
export default RoutesExport;
