import { createSlice } from '@reduxjs/toolkit';

import { getRejectedApplicantTotal } from './actions';
import { JobApplicantState } from './type';

const initialState: JobApplicantState = {
  rejectedApplicantTotal: 0,
  isFetchingRejectedApplicantTotal: null,
};

const { actions, reducer: jobApplicantReducer } = createSlice({
  name: 'job-applicant',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getRejectedApplicantTotal.fulfilled, (state, action) => {
      state.rejectedApplicantTotal = action.payload;
      state.isFetchingRejectedApplicantTotal = action.meta.requestStatus;
    });
    builder.addCase(getRejectedApplicantTotal.pending, (state, action) => {
      state.isFetchingRejectedApplicantTotal = action.meta.requestStatus;
    });
    builder.addCase(getRejectedApplicantTotal.rejected, (state, action) => {
      state.isFetchingRejectedApplicantTotal = action.meta.requestStatus;
    });
  },
});

const jobApplicantActions = { ...actions, getRejectedApplicantCount: getRejectedApplicantTotal };

export { jobApplicantActions, jobApplicantReducer };

export * from './type';
export * from './selector';
