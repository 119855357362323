import { JobStatusText } from 'models/job';

import { useTranslate } from 'hooks/common';

export const useJobStatusTextLabels = () => {
  const t = useTranslate();
  const JOB_STATUS_TEXT_LABEL: Record<JobStatusText, string> = {
    [JobStatusText.Pending]: t('value.pending'),
    [JobStatusText.Open]: t('value.open'),
    [JobStatusText.Active]: t('value.active'),
    [JobStatusText.Completed]: t('value.completed'),
    [JobStatusText.Cancel]: t('value.cancelled'),
    [JobStatusText.ExpiredNoApplicants]: t('value.expiredWithNoApplicants'),
    [JobStatusText.ExpiredNoSelection]: t('value.expiredNoSelection'),
  };

  return JOB_STATUS_TEXT_LABEL;
};
