import { EnglishTranslation } from 'locales';
import { BooleanStatus, Option } from 'types';

import { colors } from 'themes/foundations/colors';

import { ICompany } from 'models/company';

import { IEducationInstitute } from './educational-institute';
import { JobStatus, WorkerStatus } from './job';

export enum ApplicantIdentityStatus {
  Valid = '1',
  Invalid = '-1',
  Unverified = '0',
  Unavailable = '-2',
}

export enum ApplicantIdentityType {
  FIN = 'FIN',
  NRIC = 'NRIC',
}

export const APPLICANT_IDENTITY_STATUS_LABEL: Record<ApplicantIdentityStatus, string> = {
  [ApplicantIdentityStatus.Unverified]: 'value.unverifiedIdentity',
  [ApplicantIdentityStatus.Valid]: 'value.validIdentity',
  [ApplicantIdentityStatus.Invalid]: 'value.invalidIdentity',
  [ApplicantIdentityStatus.Unavailable]: 'value.unavailableIdentity',
};

export const APPLICANT_IDENTITY_STATUS_COLOR: Record<ApplicantIdentityStatus, string> = {
  [ApplicantIdentityStatus.Valid]: colors.success,
  [ApplicantIdentityStatus.Invalid]: colors.danger,
  [ApplicantIdentityStatus.Unverified]: colors.disable,
  [ApplicantIdentityStatus.Unavailable]: colors.disable,
};

export const APPLICANT_IDENTITY_STATUS_LIST: Option[] = Object.entries(
  APPLICANT_IDENTITY_STATUS_LABEL,
).map(([value, label]) => ({
  value,
  label,
}));

export interface IApplicant {
  id: number;
  display_name: string;
  age: number;
  rate: number;
  email: string;
  status: BooleanStatus;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  unique_id: string;
  contact_number: string;
  earning: number;
  education_id: number;
  referral_code?: string;
  country_code?: string;
  otp_code?: string;
  total_working_hours?: number;
  total_completed_jobs?: number;
  avatar?: string | null;
  company_id?: number | null;
  email_verified_at?: string;
  full_name: string;
  job_title?: string;
  language_code?: string;
  created_at: string;
  updated_at: string;
  gender?: 1 | 0;
  unsubscribe_news?: 1 | 0;
  has_sun_of_jod_badge?: 1 | 0;

  identity_document: string | null;
  FIN_url?: string;
  identity_status: ApplicantIdentityStatus | null;
  identity_type: ApplicantIdentityType | null;
  is_deleted?: 0 | 1;

  company?: ICompany;
  reason_for_disable?: string;
  education?: IEducationInstitute;
  food_hygiene_certificate_required: 1 | 0;
  food_hygiene_certificate?: string;
  food_hygiene_certificate_url?: string;
  food_hygiene_certificate_urls?: { name: string; food_hygiene_certificate_url: string }[];
  unsubscribe_new: 0 | 1;
  open_job_notification_status: 0 | 1;
  avatar_url: string;

  user_type: string;

  job_user_status?: number;
  location_id?: number;
  location_name?: string;
  company_location_name?: string;
  company_location_logo_filename?: string;
  image?: string;
  company_name: string;
  company_image?: string;
  candidate?: {
    name: string;
    age: number;
    is_food_hygiene_certified: boolean;
    total_working_hours: number;
    total_completed_jobs: number;
    average_rating: number;
    jod_rating: number;
    image?: string;
    company_name: string;
    company_image?: string;
  };

  work_experiences?: Array<{
    app_user_id: number;
    job_type_id: number;
    duration: number;
    job_type: {
      id: number;
      title: string;
      image_url: string;
    } | null;
    job_type_other: string | null;
    is_current?: 0 | 1;
  }>;

  location: {
    id: number;
    name: string;
  };
  apply_date: string;
  address: string | null;
  domicile: string | null;
  jod_badges?: Array<{
    icon: string;
    name: string;
    label: string;
    status: 0 | 1;
    tooltip: string;
  }>;

  bank_code: string;
  cardholder: string;
  bank_account: string;
  citizen_identity_card_urls: { id?: number; identity_document_url: string; name: string }[];
  certificate_urls: { id: number; certificate_url: string; name: string; content: string }[];
}

export enum JobApplicantStatus {
  Applied = 1,
  Selected = 2,
  Unselected = 3,
  PrevSelectSelfCanceled = 4,
  RejectByManager = 5,
  TimeOverlapRejected = 6,
  AdminDisable = 9,
  PostSelectSelfCanceled = 8,
  AdminCompanyDisable = 12,
  AdminOutletDisable = 13,
  AdminApplicantDisable = 14,
}

export enum JobApplicantStatusText {
  Applied = 'job_user_applied',
  Selected = 'job_user_selected',
  Unselected = 'job_user_unselected',
  PrevSelectSelfCanceled = 'job_user_pre_selection_self_canceled',

  RejectByManager = 'job_user_reject_by_manager',
  TimeOverlapReject = 'job_user_time_overlap_reject',
  AdminDisable = 'job_user_admin_disable_applicant',
  PostSelectSelfCanceled = 'job_user_post_selection_self_canceled',
  AdminCompanyDisable = 'job_user_admin_disable_company_rejected',
  AdminOutletDisable = 'job_user_admin_disable_outlet_rejected',
  AdminApplicantDisable = 'job_user_admin_disable_applicant_rejected',
}

export type JobApplicantStatusLabel =
  | 'Applied'
  | 'Selected'
  | 'Unselected'
  | 'Pre-selection Self-Cancelled'
  | 'Rejected by Manager'
  | 'Time-overlap Rejected'
  | 'Admin-disabling Rejected'
  | 'Post-selection Self-Cancelled'
  | 'Disabled by Super Admin (Company)'
  | 'Disabled by Super Admin (Outlet Location)'
  | 'Disabled by Super Admin (Applicant)';

export const JOB_APPLICANT_STATUS_LABEL: Record<JobApplicantStatus, keyof EnglishTranslation> = {
  [JobApplicantStatus.Applied]: 'value.applied',
  [JobApplicantStatus.Selected]: 'value.selected',
  [JobApplicantStatus.Unselected]: 'value.unselected',
  [JobApplicantStatus.PrevSelectSelfCanceled]: 'value.preSelectSelfCancelled',
  [JobApplicantStatus.RejectByManager]: 'value.rejected',
  [JobApplicantStatus.TimeOverlapRejected]: 'value.timeOverlapReject',
  [JobApplicantStatus.AdminDisable]: 'value.adminDisable',
  [JobApplicantStatus.PostSelectSelfCanceled]: 'value.postSelectSelfCanceled',
  [JobApplicantStatus.AdminCompanyDisable]: 'value.adminCompanyDisable',
  [JobApplicantStatus.AdminOutletDisable]: 'value.adminOutletDisable',
  [JobApplicantStatus.AdminApplicantDisable]: 'value.adminApplicantDisable',
};

export const JOB_APPLICANT_STATUS_COLORS: Record<
  JobApplicantStatus | JobApplicantStatusText,
  string
> = {
  [JobApplicantStatus.Applied]: colors.tango[300],
  [JobApplicantStatus.Selected]: colors.success,
  [JobApplicantStatus.Unselected]: colors.danger,
  [JobApplicantStatus.PrevSelectSelfCanceled]: colors.disable,
  [JobApplicantStatus.RejectByManager]: colors.danger,
  [JobApplicantStatus.TimeOverlapRejected]: colors.danger,
  [JobApplicantStatus.AdminDisable]: colors.danger,
  [JobApplicantStatus.PostSelectSelfCanceled]: colors.disable,
  [JobApplicantStatus.AdminCompanyDisable]: colors.danger,
  [JobApplicantStatus.AdminOutletDisable]: colors.danger,
  [JobApplicantStatus.AdminApplicantDisable]: colors.danger,

  [JobApplicantStatusText.Applied]: colors.tango[300],
  [JobApplicantStatusText.Selected]: colors.success,
  [JobApplicantStatusText.Unselected]: colors.danger,
  [JobApplicantStatusText.PrevSelectSelfCanceled]: colors.disable,
  [JobApplicantStatusText.RejectByManager]: colors.danger,
  [JobApplicantStatusText.TimeOverlapReject]: colors.danger,
  [JobApplicantStatusText.AdminDisable]: colors.danger,
  [JobApplicantStatusText.PostSelectSelfCanceled]: colors.disable,
  [JobApplicantStatusText.AdminCompanyDisable]: colors.danger,
  [JobApplicantStatusText.AdminOutletDisable]: colors.danger,
  [JobApplicantStatusText.AdminApplicantDisable]: colors.danger,
};

export const JOB_APPLICANT_STATUS_TEXT_LABEL: Record<
  JobApplicantStatusText,
  keyof EnglishTranslation
> = {
  [JobApplicantStatusText.Applied]: 'value.applied',
  [JobApplicantStatusText.PrevSelectSelfCanceled]: 'value.preSelectSelfCancelled',
  [JobApplicantStatusText.TimeOverlapReject]: 'value.timeOverlapReject',
  [JobApplicantStatusText.AdminDisable]: 'value.adminDisable',
  [JobApplicantStatusText.Selected]: 'value.selected',
  [JobApplicantStatusText.Unselected]: 'value.unselected',
  [JobApplicantStatusText.PostSelectSelfCanceled]: 'value.postSelectSelfCanceled',
  [JobApplicantStatusText.RejectByManager]: 'value.rejected',
  [JobApplicantStatusText.AdminCompanyDisable]: 'value.adminCompanyDisable',
  [JobApplicantStatusText.AdminOutletDisable]: 'value.adminOutletDisable',
  [JobApplicantStatusText.AdminApplicantDisable]: 'value.adminApplicantDisable',
};

// export const JOB_APPLICANT_STATUS_LIST: Option[] = Object.entries(JOB_APPLICANT_STATUS_LABEL).map(
//   ([value, label]) => ({
//     value: +value,
//     label,
//   }),
// );

// export const JOB_APPLICANT_STATUS_TEXT_LIST: Option[] = Object.entries(
//   JOB_APPLICANT_STATUS_TEXT_LABEL,
// ).map(([value, label]) => ({
//   value,
//   label,
// }));

export interface IJobApplicant extends IApplicant {
  app_user_id: number;
  job_id: number;
  applicant_name: string;
  avatar_url: string;
  food_hygiene_certificate_required: 0 | 1;
  total_working_hours: number;
  total_completed_jobs: number;
  rating: number;
  job_title: string;
  job_user_apply_date: string;
  job_start_date: string;
  job_end_date: string;
  job_hourly_rate: number;

  job_user_status: JobApplicantStatus;
  job_status: JobStatus;
  manager_feedback_slot: string | null;
  manager_rating_slot: number | null;
  manager_feedback_job: string | null;
  manager_rating_job: number | null;
}

export interface IApplicantJobSlot {
  applicant_id?: any;
  job_id: number;
  job_title: string;
  job_status: JobStatus;
  slot_id: number;
  slot_start_date: string;
  slot_end_date: string;
  job_start_date: string;
  job_end_date: string;
  job_user_status: JobApplicantStatus;
  slot_user_status: WorkerStatus;
  manager_rating_slot: number | null;
  manager_feedback_slot: string | null;
  applicant_feedback_slot: string | null;
  applicant_rating_slot: number | null;
  payment_processed_date?: string;
  location_id: number;
  location_name: string;
  location_url_logo: string;
  company_id: number;
  company_name: string;
}
