import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { KeptQueryState } from './type';

const initialState: KeptQueryState = {
  jobList: null,
};
const { actions, reducer: keptQueryReducer } = createSlice({
  name: 'kept-query',
  initialState,
  reducers: {
    cacheJobQuery(state, action: PayloadAction<KeptQueryState['jobList']>) {
      state.jobList = action.payload;
    },
  },
});

const keptQueryActions = { ...actions };
export { keptQueryReducer, keptQueryActions };

export * from './selector';
export * from './type';
