import { Icon, IconProps, Box } from '@chakra-ui/react';
import React from 'react';

export type StarProps = {
  bgColor?: string;
  borderColor?: string;
  filled?: boolean;
  fillPercent?: number;
  size?: IconProps['boxSize'];
} & IconProps;

export const Star: React.FC<StarProps> = ({
  bgColor = '#feffff',
  borderColor = '#324562',
  filled = false,
  fillPercent,
  size,
  ...props
}) => {
  return (
    <Box position="relative">
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="16.017"
        height="15.321"
        viewBox="0 0 16.017 15.321"
        boxSize={size}
        {...props}
      >
        <path
          id="Path_784"
          data-name="Path 784"
          d="M14.979,17.229a.439.439,0,0,0-.355-.3l-4.65-.676-2.08-4.214a.439.439,0,0,0-.788,0l-2.08,4.214-4.65.676a.439.439,0,0,0-.244.75L3.5,20.96,2.7,25.591a.439.439,0,0,0,.638.463L7.5,23.868l4.159,2.187a.439.439,0,0,0,.638-.463L11.5,20.96l3.365-3.28A.439.439,0,0,0,14.979,17.229Z"
          transform="translate(0.508 -11.296)"
          fill={filled && fillPercent === undefined ? borderColor : bgColor}
          stroke={borderColor}
          strokeWidth="1"
        />
      </Icon>
      {fillPercent !== undefined && (
        <Box position="absolute" inset="0" overflow="hidden" width={`${fillPercent}%`}>
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="16.017"
            height="15.321"
            viewBox="0 0 16.017 15.321"
            boxSize={size}
            {...props}
          >
            <path
              id="Path_784"
              data-name="Path 784"
              d="M14.979,17.229a.439.439,0,0,0-.355-.3l-4.65-.676-2.08-4.214a.439.439,0,0,0-.788,0l-2.08,4.214-4.65.676a.439.439,0,0,0-.244.75L3.5,20.96,2.7,25.591a.439.439,0,0,0,.638.463L7.5,23.868l4.159,2.187a.439.439,0,0,0,.638-.463L11.5,20.96l3.365-3.28A.439.439,0,0,0,14.979,17.229Z"
              transform="translate(0.508 -11.296)"
              fill={borderColor}
              stroke={borderColor}
              strokeWidth="1"
            />
          </Icon>
        </Box>
      )}
    </Box>
  );
};
