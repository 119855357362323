import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicantState } from './type';

const initialState: ApplicantState = {
  summary: {
    totalActive: 0,
    totalDisable: 0,
    total: 0,
    init: false,
  },
};

export const { actions: applicantActions, reducer: applicantReducer } = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    updateSummary(state, action: PayloadAction<Omit<ApplicantState['summary'], 'init'>>) {
      const { payload } = action;
      state.summary = {
        ...payload,
        init: false,
      };
    },
  },
});

export * from './type';
export * from './selector';
