import { PATH_NAME } from 'configs';
import { Navigate } from 'react-router-dom';

import { MenuConfig, navMenuConfig } from './nav-config';
import { useAuthorizedMenu } from './useAuthorizedMenu';

export const RedirectFirstPage = () => {
  const menu = useAuthorizedMenu(navMenuConfig);

  const getPath = (menu: MenuConfig[]): string | null => {
    const [first] = menu;
    if (!first) return null;
    if (first?.subMenu?.length) {
      return getPath(first.subMenu as MenuConfig[]);
    }

    return first.path;
  };

  return <Navigate to={getPath(menu) ?? PATH_NAME.ACCOUNT_SETTINGS} />;
};
