import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { notificationApi } from 'apis/notification';

export const getUnreadCount = createAsyncThunk(
  'notification/getUnreadCount',
  async () => {
    const res = await notificationApi.getUnreadCount();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { notification } = getState() as RootState;
      if (notification.fetchUnreadCountStatus === 'pending') {
        return false;
      }
    },
  },
);
