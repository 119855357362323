import { chakra } from '@chakra-ui/react';
export default function PickerButton(props: any) {
  return (
    <chakra.button
      bg="#3182ce"
      px="7px"
      color="white"
      borderRadius="2px"
      _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
      {...props}
    />
  );
}
