import { RootState } from 'store/root-reducer';

export const companySummarySelector = ({ company }: RootState) => company.summary;

export const availableCompanyOptionSelector = ({ company }: RootState) =>
  company.options?.filter((item) => item.hq_user_id === null && item.status === 1);

export const companyOptionSelector = ({ company }: RootState) => company.options;

export const companyHasHQOptionSelector = ({ company }: RootState) => company.hasHQOptions;

export const hqCompanyOptionSelector = ({ company }: RootState) =>
  company.options?.filter((item) => item.hq_user_id !== null);

export const enabledCompanyOptionSelector = ({ company }: RootState) =>
  company.options?.filter((item) => item.status === 1);

export const companyBannerSelector = ({ company }: RootState) => company.banner;
