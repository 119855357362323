export type CssUnits = 'rem' | 'em' | 'px' | '%' | 'vh' | 'vw';

export function convertRemToPixels(rem: number | string) {
  if (typeof rem === 'string') {
    const [value] = rem.split('rem');
    rem = +value;
  }
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const addUnit = (value: number, unit?: CssUnits) => {
  return `${value}${unit || 'px'}`;
};

export const secondToHours = (second: number) => second / 3600;
