import { ComponentStyleConfig, SystemStyleObject } from '@chakra-ui/react';
import { addHexOpacity } from 'utils';

import { colors } from 'themes/foundations/colors';

type Dict = Record<string, any>;

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    h: 12,
    minW: 12,
    fontSize: 'lg',
    px: 6,
  },
  md: {
    h: { base: 10, md: 12, lg: 10, '2xl': 12 },
    minW: { base: 10, md: 12, lg: 10, '2xl': 12 },
    fontSize: { base: 'md', md: 'sm' },
    px: 4,
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: 'sm',
    px: 3,
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: 'xs',
    px: 2,
  },
};

export const Button: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'tango',
  },
  baseStyle: {
    borderRadius: '4px',
    colorScheme: 'tango',
    fontFamily: 'bold',
    fontWeight: 'medium',
    _focus: {
      boxShadow: 'none',
    },
  },
  sizes,
  variants: {
    primary: {
      bg: 'primary',
      color: 'white',
      border: '2px solid transparent',
      fontSize: 'sm',
      px: { md: '20px', '2xl': '40px' },
      textTransform: 'uppercase',
      _disabled: {
        bgColor: 'tango.100',
        boxShadow: `0 3px 6px ${addHexOpacity(colors.tango[600], 30)}`,
        opacity: 1,
      },
      _hover: {
        _disabled: {
          bg: 'tango.100',
        },
      },
    },
    tango300: {
      bg: 'tango.300',
      color: 'white',
      border: '2px solid transparent',
      fontSize: 'sm',
      px: { md: '20px', '2xl': '40px' },
      _hover: {
        _disabled: {
          bg: 'tango.300',
        },
      },
    },
    secondary: {
      bg: 'secondary',
      color: 'white',
      border: '2px solid transparent',
      fontSize: 'sm',
      textTransform: 'uppercase',
      px: { md: '20px', '2xl': '40px' },
      _focus: {
        boxShadow: 'secondaryOutline',
      },
      _hover: {
        _disabled: {
          bg: 'secondary',
        },
      },
    },
    bold: {
      bg: 'primary',
      color: 'white',
    },
    uneffect: {
      bg: 'primary',
      color: 'white',
      border: '1px solid transparent',
    },
    outline: (props: Dict) => {
      const { colorScheme: c } = props;
      return {
        color: `${c}.500`,
        _hover: {
          bg: `${c}.300`,
          color: 'white',
          borderColor: `${c}.300`,
        },
        _disabled: {
          _hover: {
            color: `${c}.500`,
            borderColor: `${c}.500`,
          },
        },
      };
    },
    iconOutline: (props: Dict) => {
      const { colorScheme: c } = props;
      return {
        borderRadius: { base: '4px', lg: '8px' },
        border: '2px solid',
        borderColor: `${c}.500`,
        color: `${c}.500`,
        bg: 'white',
        h: { base: '35px !important', lg: '40px !important', '2xl': '43px !important' },
        minW: { base: '35px !important', lg: '40px !important', '2xl': '43px !important' },
        _hover: {
          bg: `${c}.300`,
          color: 'white',
          borderColor: `${c}.300`,
          _disabled: {
            color: `${c}.500`,
          },
        },
      };
    },
    secondaryOutline: {
      bg: 'white',
      border: '2px solid',
      borderColor: 'secondary',
      _focus: {
        boxShadow: 'secondaryOutline',
      },
    },
    primaryOutline: {
      bg: 'white',
      border: '2px solid',
      borderColor: 'primary',
      color: 'primary',
    },
    mixedOutline: {
      bg: 'white',
      border: '2px solid',
      borderColor: 'primary',
      color: 'secondary',
      textTransform: 'uppercase',
    },
    primaryHoverOutline: {
      bg: 'primary',
      color: 'white',
      border: '2px solid transparent',
      fontSize: 'sm',
      px: { md: '20px', '2xl': '40px' },
      _hover: {
        bg: 'white',
        color: 'secondary',
        border: '2px solid',
        borderColor: 'primary',
      },
    },
  },
};
