import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Listener choice
 */
export enum EListener {
  // listen rect only on init
  ON_INIT,
  //  listen rect on init + scroll
  ON_SCROLL,
  //  listen rect on init + resize
  ON_RESIZE,
  //  listen rect on init + scroll + resize
  ON_SCROLL_AND_RESIZE,
}

type Coordinates = { top: number; right: number; bottom: number; left: number };

/**
 * useBoundingClientRect hook
 * @param {EListener} pListener
 * @return ClientRect
 */
export function useCoordinates<T extends HTMLElement>(
  pListener: EListener = EListener.ON_INIT,
): [React.RefObject<T>, Coordinates | null] {
  const pRef = useRef<T>(null);
  // define offset state
  const [rect, setRect] = useState<Coordinates | null>(null);

  /**
   * getBoundingClientRect properties
   */
  const getBoundingClientRect = useCallback((): Coordinates | null => {
    if (!pRef?.current) return null;
    let box = pRef?.current.getBoundingClientRect();

    // if pRef exist
    return {
      top: box.top + window.pageYOffset,
      right: box.right + window.pageXOffset,
      bottom: box.bottom + window.pageYOffset,
      left: box.left + window.pageXOffset,
    };
  }, [pRef]);

  /**
   *  update offset state
   */
  const update = (): void => {
    // set new offset
    setRect(getBoundingClientRect());
  };

  // each time pRef change
  useEffect(() => {
    // check if ref exist
    if (!pRef.current) return;
    // update offset state
    update();

    // listen on resize
    if (pListener === EListener.ON_RESIZE || pListener === EListener.ON_SCROLL_AND_RESIZE) {
      window.addEventListener('resize', update);
    }
    // listen on scroll
    if (pListener === EListener.ON_SCROLL || pListener === EListener.ON_SCROLL_AND_RESIZE) {
      document.addEventListener('scroll', update);
    }

    // stop listen
    return () => {
      if (pListener === EListener.ON_RESIZE || pListener === EListener.ON_SCROLL_AND_RESIZE) {
        window.removeEventListener('resize', update);
      }
      if (pListener === EListener.ON_SCROLL || pListener === EListener.ON_SCROLL_AND_RESIZE) {
        document.removeEventListener('scroll', update);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pRef.current]);

  // get initial mount
  const initialMount = useRef(true);

  useEffect(() => {
    // update offset state
    if (initialMount.current) {
      update();
      initialMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return offset
  return [pRef, rect];
}
