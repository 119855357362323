import { AxiosResponse, CancelToken } from 'axios';
import { Order } from 'types';

export enum HttpStatusCode {
  Unauthorized = 401,
  Forbidden = 403,
}

export type ErrorDescription = Record<string, string[]>;

export type GeneralResponse = {
  message: string;
  status: boolean;
};

export type ErrorResponse = {
  description?: ErrorDescription;
  isCancel?: boolean;
  isForbidden?: boolean;
};

export type PaginationResponse = {
  current: number;
  pageSize: number;
  total?: number;
  lastPage?: number;
};

export type Payload<
  Body extends any = unknown,
  Params extends any = unknown,
  Query extends any = unknown,
> = {
  cancelToken?: CancelToken;
} & (Body extends object ? { body: Body } : {}) &
  (Params extends object ? { params: Params } : {}) &
  (Query extends object ? { query?: Query } : {}) & {
    pagination?: Pick<PaginationResponse, 'current' | 'pageSize'>;
  };

export type DataResponse<T extends object | null | undefined> = GeneralResponse &
  ErrorResponse &
  (T extends null ? {} : { data: T; pagination?: PaginationResponse });

export type PrimitiveResponse<Primitive> = GeneralResponse & ErrorResponse & { data: Primitive };
export type ArrayResponse<T> = GeneralResponse & ErrorResponse & { data: Array<T> };

export type ListAxiosResponse<T> = AxiosResponse<{
  data: { total: number; limit: number; page: number; last_page: number; data: T[] };
  message: string;
}>;

export type ObjectAxiosResponse<T extends object> = AxiosResponse<{
  data: T;
  message: string;
}>;
export type ArrayAxiosResponse<T> = AxiosResponse<{
  data: Array<T>;
  message: string;
}>;

export type AnyAxiosResponse<T> = AxiosResponse<{
  data: T;
  message: string;
}>;
export type MessageAxiosResponse = AxiosResponse<{
  message: string;
}>;

export type SortParams = { sort_field: string; sort_order: Order };

export type HttpClientError = {
  systemMessage: string;
  code: number;
  isCancel: boolean;
  isForbidden: boolean;
  description: ErrorDescription;
  message: string;
};
