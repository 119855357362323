import { PATH_NAME } from 'configs';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { authSelector } from 'store/modules/auth';

export type GuestRouteGuardProps = {
  children?: React.ReactNode; // Add children property
};

export const GuestRouteGuard: FC<GuestRouteGuardProps> = (props) => {
  const auth = useSelector(authSelector);
  if (auth.isAuthenticated) {
    return <Navigate to={PATH_NAME.ROOT} />;
  }
  return <> {props.children} </>;
};
