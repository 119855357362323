import { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
  baseStyle: {
    field: {
      border: 'secondary',
      color: 'secondary',
      borderColor: 'vulcan.300',
      fontFamily: 'heading',
      fontSize: 'md',
      _placeholder: {
        color: '#707070',
        fontFamily: 'italic',
      },
      outline: 0,
    },
  },
  variants: {
    outline: {
      field: {
        _focus: {
          zIndex: 1,
          borderColor: 'tangoOpacity.20',
          boxShadow: 'tangoOpacity.20',
        },
      },
      icon: {
        width: '50px',
        height: '50px',
        fontSize: '1.5rem',
        _groupFocus: {
          transform: 'rotate(-45deg)',
          color: 'blue',
        },
      },
    },
  },
};
