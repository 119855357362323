import { JobStatus } from 'models/job';

import { useTranslate } from '../../common';

export const useJobStatusLabels = () => {
  const t = useTranslate();
  const JOB_STATUS_LABEL: Record<any, string> = {
    [JobStatus.Pending]: t('value.pending'),
    [JobStatus.Open]: t('value.open'),
    [JobStatus.Active]: t('value.active'),
    [JobStatus.Completed]: t('value.completed'),
    [JobStatus.Cancel]: t('value.cancelled'),
    [JobStatus.ExpiredNoApplicants]: t('value.expiredWithNoApplicants'),
    [JobStatus.ExpiredNoSelection]: t('value.expiredNoSelection'),
    // [JobStatus.CompletedWoApplicant]: t('value.completedWithoutAppliedApplicant'),
    // [JobStatus.CompletedWoManager]: t('value.completedWithoutHiringManager'),
  };

  return JOB_STATUS_LABEL;
};

export const useReportJobStatusLabels = () => {
  const t = useTranslate();
  const JOB_STATUS_LABEL: Record<JobStatus, string> = {
    [JobStatus.Pending]: t('value.pending'),
    [JobStatus.Open]: t('value.open'),
    [JobStatus.Active]: t('value.active'),
    [JobStatus.Completed]: t('value.completed'),
    [JobStatus.Cancel]: t('value.cancelled'),
    // [JobStatus.CompletedWoApplicant]: t('value.completedWithoutAppliedApplicant'),
    // [JobStatus.CompletedWoManager]: t('value.completedWithoutHiringManager'),
    [JobStatus.ExpiredNoApplicants]: t('value.expiredWithNoApplicants'),
    [JobStatus.ExpiredNoSelection]: t('value.expiredNoSelection'),
  };

  return JOB_STATUS_LABEL;
};
