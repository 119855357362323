import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'types';

import { banksSelector, isFetchingBanksSelector, paymentActions } from 'store/modules/payment';
import { AppDispatch } from 'store/root-reducer';
export const useBankList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const banks = useSelector(banksSelector);
  const loading = useSelector(isFetchingBanksSelector);

  useEffect(() => {
    if (!banks?.length) {
      dispatch(paymentActions.getBankList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result: [Option[], boolean] = [banks ?? [], loading];

  return result;
};
