import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { dashboardActions } from 'store/modules/dashboard';

import { DashboardApi, dashboardApi } from 'apis/dashboard';

import { IJobOverview } from 'models/dashboard';
import { AppDispatch } from 'store/root-reducer';
import { useQuery } from 'hooks/common';

export const useJobOverview = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [data, , , control] = useQuery<IJobOverview, null, DashboardApi['getJobOverview']>(
    null,
    dashboardApi.getJobOverview,
    {
      preventInitCall: false,
    },
  );

  const {
    total_active_jobs,
    total_open_jobs,
    total_completed_jobs_of_current_month,
    job_charts: jobs,
  } = data || {};

  const jobData = useMemo(() => {
    return jobs?.map((data) => data.total_completed_jobs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const timeData = useMemo(() => {
    return jobs?.map((data) => [data.month, data.year]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(dashboardActions.getCreditOverview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reFetch = async (locationId?: number) => {
    control.refetch(locationId);
  };

  const result: [
    {
      total_active_jobs?: number;
      total_open_jobs?: number;
      total_completed_jobs_of_current_month?: number;
      jobData?: number[];
      timeData?: string[][];
    },
    typeof reFetch,
  ] = [
    {
      total_active_jobs,
      total_open_jobs,
      total_completed_jobs_of_current_month,
      jobData,
      timeData,
    },
    reFetch,
  ];

  return result;
};
