import { createSlice } from '@reduxjs/toolkit';

import { getInternalPermissions, getPartnerPermissions, getRoleAsOptions, getSuperHqRoleAsOptions } from './action';
import { UserRoleState } from './type';

const initialState: UserRoleState = {
  internalRoleOptions: null,
  fetchInternalRoleOptionStatus: null,
  internalPermissions: null,
  fetchInternalPermissionStatus: null,
  partnerPermissions: null,
  fetchPartnerPermissionStatus: null,
  superHqRoleOptions: null,
  fetchSuperHqRoleOptionStatus: null
};

const { actions, reducer: userRoleReducer } = createSlice({
  name: 'userRole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRoleAsOptions.fulfilled, (state, action) => {
      state.internalRoleOptions = action.payload;
      state.fetchInternalRoleOptionStatus = action.meta.requestStatus;
    });
    builder.addCase(getRoleAsOptions.pending, (state, action) => {
      state.fetchInternalRoleOptionStatus = action.meta.requestStatus;
    });
    builder.addCase(getRoleAsOptions.rejected, (state, action) => {
      state.fetchInternalRoleOptionStatus = action.meta.requestStatus;
    });

    builder.addCase(getSuperHqRoleAsOptions.fulfilled, (state, action) => {
      state.superHqRoleOptions = action.payload;
      state.fetchSuperHqRoleOptionStatus = action.meta.requestStatus;
    });
    builder.addCase(getSuperHqRoleAsOptions.pending, (state, action) => {
      state.fetchSuperHqRoleOptionStatus = action.meta.requestStatus;
    });
    builder.addCase(getSuperHqRoleAsOptions.rejected, (state, action) => {
      state.fetchSuperHqRoleOptionStatus = action.meta.requestStatus;
    });

    builder.addCase(getInternalPermissions.fulfilled, (state, action) => {
      state.internalPermissions = action.payload;
      state.fetchInternalPermissionStatus = action.meta.requestStatus;
    });
    builder.addCase(getInternalPermissions.pending, (state, action) => {
      state.fetchInternalPermissionStatus = action.meta.requestStatus;
    });
    builder.addCase(getInternalPermissions.rejected, (state, action) => {
      state.fetchInternalPermissionStatus = action.meta.requestStatus;
    });

    builder.addCase(getPartnerPermissions.fulfilled, (state, action) => {
      state.partnerPermissions = action.payload;
      state.fetchPartnerPermissionStatus = action.meta.requestStatus;
    });
    builder.addCase(getPartnerPermissions.pending, (state, action) => {
      state.fetchPartnerPermissionStatus = action.meta.requestStatus;
    });
    builder.addCase(getPartnerPermissions.rejected, (state, action) => {
      state.fetchPartnerPermissionStatus = action.meta.requestStatus;
    });
  },
});

const userRoleActions = {
  ...actions,
  getRoleAsOptions,
  getSuperHqRoleAsOptions,
  getInternalPermissions,
  getPartnerPermissions,
};
export * from './selector';
export * from './type';
export { userRoleReducer, userRoleActions };
