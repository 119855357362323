import { WorkerStatusText } from 'models/job';

import { useTranslate } from 'hooks/common';

export const useWorkerStatusTextLabels = () => {
  const t = useTranslate();
  const WORKER_STATUS_LABEL_TEXT: Record<WorkerStatusText, string> = {
    [WorkerStatusText.Approved]: t('value.hired'),
    [WorkerStatusText.Rejected]: t('value.rejected'),
    [WorkerStatusText.RejectedPartially]: t('value.rejectedPartially'),
    [WorkerStatusText.SelfCanceled]: t('value.selfCancelled'),
    [WorkerStatusText.ACK1]: t('value.ack1'),
    [WorkerStatusText.ACK2]: t('value.ack2'),
    [WorkerStatusText.ClockIn]: t('value.clockedIn'),
    [WorkerStatusText.ClockOut]: t('value.clockedOut'),
    [WorkerStatusText.AdminDisable]: t('value.adminDisable'),
    [WorkerStatusText.NoShow]: t('value.noShow'),
    // [WorkerStatusText.NoHiring]: t('value.noHiring'),
    // [WorkerStatusText.NoApplicant]: t('value.noApplicants')
  };

  return WORKER_STATUS_LABEL_TEXT;
};
