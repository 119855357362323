import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { locationProfileActions } from 'store/modules/location-profile';

import { Role } from 'models/user';

import { SystemPermission } from 'types';

import { Loading } from 'components/molecules';
import { RedirectFirstPage } from 'components/organisms/NavMenu/RedirectFirstPage';

import { useRouter, useTranslate } from 'hooks/common';
import { useCurrentPermission, useCurrentRole } from 'hooks/components/auth';
import { AppDispatch } from 'store/root-reducer';
export type RolePermissionRouteGuardProps = {
  requiredRoles?: Role[];
  requiredPermissions?: SystemPermission[];
  children?: React.ReactNode; // Add children property
};

export const RolePermissionRouteGuard: FC<RolePermissionRouteGuardProps> = ({
  requiredRoles,
  requiredPermissions,
  children,
  ...props
}) => {
  const router = useRouter();
  const t = useTranslate();
  const dispatch = useDispatch<AppDispatch>();
  const currentRole = useCurrentRole();
  const [currentPermission, checKHasPermission] = useCurrentPermission();
  const isAuthorized = useMemo(() => {
    if (!currentRole || !currentPermission) return false;
    const isValidRole = requiredRoles?.length ? requiredRoles.includes(currentRole) : true;
    const isValidPermission = requiredPermissions?.length
      ? checKHasPermission(requiredPermissions)
      : true;

    return isValidRole && isValidPermission;
  }, [checKHasPermission, currentPermission, currentRole, requiredPermissions, requiredRoles]);

  useEffect(() => {
    if (currentRole === Role.LocationManager) {
      dispatch(locationProfileActions.getLocationProfileInfo());
    }
  }, [dispatch, currentRole]);

  if (!currentRole || !currentPermission) {
    return <Loading h="100%" loading tip="Authorizing..." {...props} />;
  }
  if (isAuthorized === true) {
    return <>{children}</>;
  }

  if (router.pathname === PATH_NAME.ROOT) {
    return <RedirectFirstPage />;
  }

  return (
    <Box p="20px">
      <Alert status="error">
        <AlertIcon />
        {t('message.youNoPermissionAccessPage')}
      </Alert>
    </Box>
  );
};
