import { createSlice } from '@reduxjs/toolkit';

import { getLocationProfileInfo } from './action';
import { LocationProfileState } from './type';

const initialState: LocationProfileState = {
  summary: {
    total: 0,
    init: false,
  },
  locationProfileInfo: null,
  fetchSummaryStatus: null,
  fetchLocationProfileStatus: null,
};

const { actions, reducer: locationProfileReducer } = createSlice({
  name: 'locationProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocationProfileInfo.fulfilled, (state, action) => {
      const { payload } = action;
      state.locationProfileInfo = payload;
      state.fetchLocationProfileStatus = action.meta.requestStatus;
    });
    builder.addCase(getLocationProfileInfo.pending, (state, action) => {
      state.fetchLocationProfileStatus = action.meta.requestStatus;
    });
    builder.addCase(getLocationProfileInfo.rejected, (state, action) => {
      state.fetchLocationProfileStatus = action.meta.requestStatus;
    });
  },
});

const locationProfileActions = {
  ...actions,
  getLocationProfileInfo,
};

export { locationProfileReducer, locationProfileActions };

export * from './type';
export * from './selector';
