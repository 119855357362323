import { Option } from 'types';

import { useWorkerStatusLabels } from './useWorkerStatusLabels';

export const useWorkerStatusList = () => {
  const workerStatusLabels = useWorkerStatusLabels();
  const WORKER_STATUS_LIST: Option<number>[] = Object.entries(workerStatusLabels).map(
    ([value, label]) => ({
      value: +value,
      label,
    }),
  );
  return WORKER_STATUS_LIST;
};
