import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  availableCompanyOptionSelector,
  companyActions,
  companyOptionSelector,
  enabledCompanyOptionSelector,
} from 'store/modules/company';

import { CompanyApi, companyApi } from 'apis/company';

import { CompanyOption } from 'models/company';

import { useQuery } from 'hooks/common';
import { AppDispatch } from 'store/root-reducer';
export const useCompanyOptions = (type?: 'enable' | 'available') => {
  const dispatch = useDispatch<AppDispatch>();
  const getSelectorFunc = () => {
    switch (type) {
      case 'enable':
        return enabledCompanyOptionSelector;
      case 'available':
        return availableCompanyOptionSelector;
      default:
        return companyOptionSelector;
    }
  };
  const storedOptions = useSelector(getSelectorFunc());

  const [data, loading, , tool] = useQuery<CompanyOption[], any, CompanyApi['getCompanyOptions']>(
    null,
    companyApi.getCompanyOptions,
  );

  useEffect(() => {
    if (data) {
      dispatch(companyActions.setOptions(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return useMemo<[typeof storedOptions, boolean, typeof tool.refetch, typeof data]>(
    () => [storedOptions, loading, tool.refetch, data],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedOptions, loading],
  );
};
