import { ComponentStyleConfig } from '@chakra-ui/react';
import { colors } from 'themes/foundations/colors';

export const Tag: ComponentStyleConfig = {
  variants: {
    outlineShadow: {
      container: {
        bg: 'white',
        boxShadow: `0 0 4px ${colors.tangoOpacity[30]}`,
      },
    },
  },
};
