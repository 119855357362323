export const getFileExtension = (file: File): string => {
  const splitFileName = file.name.split('.');
  if (splitFileName.length === 1 || (splitFileName[0] === '' && splitFileName.length === 2)) {
    return '';
  }
  return splitFileName.pop()?.toLowerCase() || '';
};
export const getFilenameExtension = (filename: string): string => {
  const splitFileName = filename.split('.');
  if (splitFileName.length === 1 || (splitFileName[0] === '' && splitFileName.length === 2)) {
    return '';
  }
  return splitFileName.pop()?.toLowerCase() || '';
};
