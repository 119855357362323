import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ModalState = Record<string, boolean>;

const initialState: ModalState = {};

const { actions, reducer: modalReducer } = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggle: (state, action: PayloadAction<{ modalKey: string; open: boolean }>) => {
      state[action.payload.modalKey] = action.payload.open;
    },
    open: (state, action: PayloadAction<string>) => {
      state[action.payload] = true;
    },
    close: (state, action: PayloadAction<string>) => {
      state[action.payload] = false;
    },
  },
});

const modalActions = actions;
export { modalReducer, modalActions };
