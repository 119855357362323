export const ENV = {
  LOCAL_KEY: process.env.REACT_APP_LOCAL_KEY || 'local-jod-key',
  USER_KEY: 'user-jod-key',
  HQ_USER_KEY: 'hq-user-key',
  SESSION_KEY: process.env.REACT_APP_SESSION_KEY || 'session-jod-key',
  COOKIE_KEY: process.env.REACT_APP_COOKIE_KEY || 'cookie-jod-key',
  API_ENDPOINT:
    process.env.REACT_APP_API_ENDPOINT || 'https://api-v2-demo.jod.com.sg/api',
  ENV: process.env.REACT_APP_ENV || 'DEV',
  COUNTRY_CODE: process.env.REACT_APP_COUNTRY_CODE || '65',
  TIMEZONE: process.env.REACT_APP_TIMEZONE || 'Singapore',
  CURRENCY_CODE: process.env.REACT_APP_CURRENCY_CODE || 'USD',
  CURRENCY_LOCALE: process.env.REACT_APP_CURRENCY_LOCALE || 'en-US',
  PROCESS_ENV: process.env.NODE_ENV,
  INSTANCE: {
    SINGAPORE: Boolean(
      process.env.REACT_APP_COUNTRY_CODE === '65' || !process.env.REACT_APP_COUNTRY_CODE,
    ),
    VIETNAM: Boolean(process.env.REACT_APP_COUNTRY_CODE === '84'),
  },
};

if (process.env.REACT_APP_HOT_LOAD_ENV && process.env.NODE_ENV === 'development') {
  // local
  // ENV.API_ENDPOINT = 'https://api-dev-v2.jod.demo-application.net/api';
}