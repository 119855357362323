import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ConfigState } from './type';

const initialState: ConfigState = {
  headerTitle: '',
  counterFlag: 0,
};

export const { reducer: configReducer, actions: configActions } = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setHeaderTitle(
      state,
      action: PayloadAction<Pick<ConfigState, 'headerTitle' | 'headerTitleBreakLine'>>,
    ) {
      const { headerTitle, headerTitleBreakLine } = action.payload;
      if (state.headerTitle !== headerTitle) {
        state.headerTitle = headerTitle;
      }
      if (state.headerTitleBreakLine !== headerTitleBreakLine) {
        state.headerTitleBreakLine = headerTitleBreakLine;
      }
    },
    setDevice(state, action: PayloadAction<ConfigState['device']>) {
      if (action.payload !== state.device) {
        state.device = action.payload;
      }
    },
    setScreen(state, action: PayloadAction<ConfigState['screen']>) {
      if (action.payload !== state.screen) {
        state.screen = action.payload;
      }
    },
    increaseCounter(state, action: PayloadAction<number | undefined>) {
      state.counterFlag += action.payload ?? 1;
    },
    decreaseCounter(state, action: PayloadAction<number | undefined>) {
      state.counterFlag -= action.payload ?? 1;
    },
    resetCounter(state) {
      state.counterFlag = 0;
    },
  },
});

export * from './type';
export * from './selectors';
