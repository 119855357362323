import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { fontSizes } from './Text';

export interface ParagraphProps extends TextProps {
  size?: keyof typeof fontSizes;
}
export const Paragraph: React.FC<ParagraphProps> = ({ children, size, ...props }) => {
  return (
    <Text as="p" fontSize={size ? fontSizes[size] : undefined} {...props}>
      {children}
    </Text>
  );
};
