import { createAsyncThunk } from '@reduxjs/toolkit';

import { creditApi } from 'apis/credit';

export const getAvailableCreditOfCompany = createAsyncThunk(
  'location/getAvailableAssignCreditByCompany',
  async () => {
    const res = await creditApi.getAvailableCreditCompany();
    return res.data.availableCredits;
  },
);

export const getTotalLocationAvailableCreditByCompany = createAsyncThunk(
  'location/getTotalLocationAvailableCreditByCompany',
  async () => {
    const res = await creditApi.getTotalLocationAvailableCreditByCompany();
    return res.data.totalAvailableCredits;
  },
);

export const getTotalLocationAvailableCredit = createAsyncThunk(
  'location/getTotalLocationAvailableCreditByArea',
  async () => {
    const res = await creditApi.getTotalLocationAvailableCredit();
    return res.data.totalAvailableCredits;
  },
);
