import { Avatar, AvatarProps, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends Omit<AvatarProps, 'src'> {
  applicantImage?: string;
  applicantName?: string;
  companyImage?: string;
  companyName?: string;
  subLogoSize?: BoxProps['boxSize'];
  cornerDistance?: string;
  subLogoProps?: Omit<AvatarProps, 'src'>;
}

export const ApplicantAvatar: FC<Props> = ({
  applicantImage,
  applicantName,
  companyImage,
  companyName,
  subLogoSize = '40px',
  cornerDistance,
  subLogoProps,
  ...props
}) => {
  return (
    <Avatar
      position="relative"
      boxSize="80px"
      src={applicantImage}
      name={applicantName}
      borderRadius="15px"
      bg="tango.300"
      color="white"
      {...props}
    >
      {(companyImage || companyName) && (
        <Avatar
          src={companyImage}
          boxSize={subLogoSize}
          name={companyName}
          bg="tango.600"
          color="white"
          pos="absolute"
          top={`-${cornerDistance ?? 15}`}
          left={`-${cornerDistance ?? 15}`}
          borderRadius="50%"
          {...subLogoProps}
        />
      )}
    </Avatar>
  );
};
