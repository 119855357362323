import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { userRoleApi } from 'apis/user-role';

import { PermissionType } from 'models/user';

export const getRoleAsOptions = createAsyncThunk(
  'userRole/getInternalRoleAsOptions',
  async () => {
    const res = await userRoleApi.getInternalRoleAsOptions();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { userRole } = getState() as RootState;
      if (userRole.fetchInternalRoleOptionStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getSuperHqRoleAsOptions = createAsyncThunk(
  'userRole/getSuperHqRoleAsOptions',
  async () => {
    const res = await userRoleApi.getSuperHqRoleAsOptions();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { userRole } = getState() as RootState;
      if (userRole.fetchInternalRoleOptionStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getInternalPermissions = createAsyncThunk(
  'userRole/getInternalPermissions',
  async () => {
    const res = await userRoleApi.getPermissions({ params: { type: PermissionType.Internal } });
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { userRole } = getState() as RootState;
      if (userRole.fetchInternalRoleOptionStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getPartnerPermissions = createAsyncThunk(
  'userRole/getPartnerPermissions',
  async () => {
    const res = await userRoleApi.getPermissions({ params: { type: PermissionType.Partner } });
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { userRole } = getState() as RootState;
      if (userRole.fetchInternalRoleOptionStatus === 'pending') {
        return false;
      }
    },
  },
);
