import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { ENV } from 'configs';
import { DATE_BE } from 'constant';
import i18next from 'i18next';
import { DataResponse, ListAxiosResponse, Payload, Query } from 'types';
import { createFormData } from 'utils';
import { IApplicant, IApplicantJobSlot } from 'models/applicant';
import { WorkerStatusText } from 'models/job';

import { ApplicantFilterData } from 'components/molecules/ApplicantFilter';
import { ApplicantFormData } from 'components/organisms/ApplicantForm';
import { ApplicantValues } from 'components/organisms/ApplicantForm2';
import { Sorter } from 'components/organisms/Table';

import { concatParams, mapErrorDescriptions, mapPaginationState, mapQueryToParams } from './utils';

const entity = 'applicantuser';

const basePath = `portal/${entity}` as const;

const applicantPayloadScheme: Record<string, keyof ApplicantFormData> = {
  first_name: 'firstName',
  last_name: 'lastName',
  display_name: 'displayName',
  date_of_birth: 'birthDate',
  unique_id: 'uuid',
  country_code: 'countryCode',
  contact_number: 'contactNumber',
  email: 'email',
  food_hygiene_pass: 'isFoodHygiene',
  referral_code: 'referralCode',
  gender: 'gender',
  unsubscribe_news: 'unsubscribeNews',
  education_id: 'school',
  open_job_notification_status: 'newJobNotification',
  company_id: 'company',
  avatar: 'avatar',
  has_sun_of_jod_badge: 'hasSunOfJODBadge',
  identity_document: 'finDocument',
  food_hygiene_certificate: 'foodHygieneFile',
};

export const applicantApi = {
  async list(
    payload: Payload<
      null,
      null,
      { sort?: Sorter | null; search?: string | null } & Partial<ApplicantFilterData>
    >,
  ): Promise<DataResponse<IApplicant[]>> {
    try {
      const { pagination, query } = payload;

      const {
        sort,
        search,
        recent,
        applicantStatus,
        minRating,
        maxRating,
        amountJob,
        identityStatus,
      } = query || {};

      const filterTypes = concatParams(recent ? 'recent' : '', applicantStatus ?? '');

      const res: ListAxiosResponse<IApplicant> = await httpClient.get(`${basePath}/index`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            filter_types: filterTypes,
            min_rating: minRating,
            max_rating: maxRating,
            amount_job: amountJob,
            identity_status: identityStatus?.value,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },

  async summary(): Promise<
    DataResponse<{ totalActive: number; totalDisable: number; total: number }>
  > {
    try {
      const res: AxiosResponse<{
        data: {
          total: number;
          total_enable: number;
          total_disable: number;
        };
        message: string;
      }> = await httpClient.get(`${basePath}/summary`);
      const {
        data: {
          data: { total, total_enable: totalActive, total_disable: totalDisable },
        },
      } = res;
      return {
        status: true,
        data: {
          totalActive,
          totalDisable,
          total,
        },
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async create(payload: Payload<ApplicantValues>): Promise<DataResponse<null>> {
    try {
      const { body } = payload;
      const bodyData = new FormData();
      const append = (key: string, value: string | Blob | undefined | null) => {
        if (value) {
          bodyData.append(key, value);
        }
      };
      append('first_name', body.first_name);
      append('last_name', body.lastName);
      append('display_name', body.displayName);
      append('date_of_birth', body.birthDate?.format(DATE_BE));
      append('unique_id', body.uuid);
      // append('country_code', body.countryCode);
      append('contact_number', body.contactNumber);
      append('email', body.email);
      append('food_hygiene_certificate_required', body.isFoodHygiene ? '1' : '0');
      if (ENV.INSTANCE.SINGAPORE) {
        append('food_hygiene_certificate', body.foodHygieneFile);
      }
      append('referral_code', body.referralCode);
      append('gender', body.gender);
      append('unsubscribe_news', body.unsubscribeNews ? '1' : '0');
      append('has_sun_of_jod_badge', body.hasSunOfJODBadge ? '1' : '0');
      append('education_id', body.school?.value);
      append('open_job_notification_status', body.newJobNotification ? '1' : '0');
      append('company_id', body.company?.value);
      append('avatar', body.avatar);
      if (ENV.INSTANCE.SINGAPORE) {
        append('identity_document', body.identity_document);
      }
      if (ENV.INSTANCE.VIETNAM) {
        for (let i = 0; i < body.citizen_identity_card_urls.length; i++) {
          append('identity_document[]', body.citizen_identity_card_urls[i].file);
        }
      }

      for (let i = 0; i < body.certificates.length; i++) {
        append(`certificates[${i}]`, body.certificates[i].file);
        append(`certificates_info[${i}][name]`, body.certificates[i].name);
        append(`certificates_info[${i}][content]`, body.certificates[i].content);
      }

      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.post(
        `${basePath}/store`,
        bodyData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      // clevertap.event.push("Account Creation Complete");

      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.createdApplicantUnsuccess'), description } =
        error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(applicantPayloadScheme, description),
        message,
      });
    }
  },
  async update(payload: Payload<ApplicantValues, { id: string }>): Promise<DataResponse<null>> {
    try {
      const { body, params } = payload;
      const bodyData = new FormData();
      const append = (key: string, value: string | Blob | undefined | null) => {
        if (value) {
          bodyData.append(key, value);
        }
      };
      append('first_name', body.first_name);
      append('last_name', body.lastName);
      append('display_name', body.displayName);
      append('date_of_birth', body.birthDate?.format(DATE_BE));
      append('unique_id', body.uuid);
      // append('country_code', body.countryCode);
      append('contact_number', body.contactNumber);
      append('email', body.email);
      if (ENV.INSTANCE.SINGAPORE) {
        append('food_hygiene_certificate_required', body.isFoodHygiene ? '1' : '0');
        append('food_hygiene_certificate', body.foodHygieneFile);
      }
      append('referral_code', body.referralCode);
      append('gender', body.gender);
      append('unsubscribe_news', body.unsubscribeNews ? '1' : '0');
      append('has_sun_of_jod_badge', body.hasSunOfJODBadge ? '1' : '0');
      append('education_id', body.school?.value);
      append('open_job_notification_status', body.newJobNotification ? '1' : '0');
      append('company_id', body.company?.value);
      append('avatar', body.avatar);
      if (ENV.INSTANCE.SINGAPORE) {
        append('identity_document', body.identity_document);
      }
      if (ENV.INSTANCE.VIETNAM) {
        for (let i = 0; i < body.citizen_identity_card_urls.length; i++) {
          const { id, file } = body.citizen_identity_card_urls[i];
          if (id && file) {
            append('card_ids[]', String(id));
            append('identity_document[]', file);
          }
        }
      }

      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.post(
        `${basePath}/edit/${params.id}`,
        bodyData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message, description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(applicantPayloadScheme, description),
        message: message || i18next.t('message.updatedApplicantUnsuccess'),
      });
    }
  },
  async disable(id: string, reason?: string, isSendMail?: number): Promise<DataResponse<null>> {
    try {
      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.delete(
        `${basePath}/disable/${id}`,
        {
          params: {
            reason,
            isSendMail,
          },
        },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.disableApplicantUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
        data: error.data,
      });
    }
  },
  async enable(id: string): Promise<DataResponse<null>> {
    try {
      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.put(
        `${basePath}/enable/${id}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.enableApplicantUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async updateStatus(
    id: string,
    action: 'enable' | 'disable',
    reason?: string,
    isSendMail?: number,
  ): Promise<DataResponse<null>> {
    if (action === 'enable') {
      return applicantApi.enable(id);
    }
    return applicantApi.disable(id, reason, isSendMail);
  },
  async getDetail(id: string | number): Promise<DataResponse<IApplicant>> {
    try {
      const res: AxiosResponse<{ data: IApplicant; message: string }> = await httpClient.get(
        `${basePath}/detail/${id}`,
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        message,
        data,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
  async getJobSlotHistory(
    payload: Payload<null, { id: string | number }, Query & { slotUserStatus?: WorkerStatusText }>,
  ): Promise<DataResponse<IApplicantJobSlot[]>> {
    try {
      const { pagination, query, params } = payload;
      const { sort, slotUserStatus } = query || {};

      const res: ListAxiosResponse<IApplicantJobSlot> = await httpClient.get(
        `${basePath}/indexSlotByAppUserId`,
        {
          params: mapQueryToParams(
            { sort },
            pagination,
            {
              app_user_id: params.id,
              slot_user_status: slotUserStatus,
            },
            {
              sortDefaultByCreatedAt: true,
            },
          ),
        },
      );
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async updateIdentityStatus(id: number, status: 'valid' | 'invalid'): Promise<DataResponse<null>> {
    try {
      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.patch(
        `${basePath}/${id}/${status === 'valid' ? 'mark-valid-identity' : 'mark-invalid-identity'}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t('message.updateApplicantIdentityStatusUnsuccess') } =
        error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async downloadDocument(id: number): Promise<DataResponse<any>> {
    try {
      const res = await httpClient.get(`${basePath}/${id}/download-identity-document`, {
        responseType: 'blob',
      });

      return {
        status: true,
        data: res.data,
        message: i18next.t('message.downloadSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async downloadDocumentVn(url: string): Promise<DataResponse<any>> {
    try {
      const res = await httpClient.get(url, {
        responseType: 'blob',
      });

      return {
        status: true,
        data: res.data,
        message: i18next.t('message.downloadSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async deleteCertificate(payload: {
    applicantId: number;
    certificateId: number;
  }): Promise<DataResponse<any>> {
    try {
      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.delete(
        `users/${payload.applicantId}/files/${payload.certificateId}`,
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async createOrUpdateCertificate(payload: {
    applicantId: number;
    certificateId?: number;
    file?: File;
    name: string;
    content: string;
  }) {
    try {
      const bodyData = createFormData({
        file: payload.file,
        name: payload.name,
        content: payload.content,
      });
      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.post(
        `users/${payload.applicantId}/files/${payload.certificateId ?? ''}`,
        bodyData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async updateAverageRating(payload: { applicantId: number; rate: string }) {
    try {
      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.put(
        `portal/users/${payload.applicantId}/rate`,
        { rate: payload.rate },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async updateSlotRating(payload: { applicantId: number; slotId: number; rate: string }) {
    try {
      const res: AxiosResponse<{ message: string; data: IApplicant }> = await httpClient.put(
        `portal/users/${payload.applicantId}/slots/${payload.slotId}/update-manager-rating-slot`,
        { rate: payload.rate },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type ApplicantApi = typeof applicantApi;
