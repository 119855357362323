import { fontSizes } from 'components/atoms';

import { Input } from './input';

export const Textarea = {
  baseStyle: {
    color: 'secondary',
    fontFamily: { base: 'regular', lg: 'heading' },
    _placeholder: {
      color: '#707070',
      fontFamily: 'italic',
      opacity: 0.5,
    },
  },
  variants: {
    outline: {
      borderRadius: '10px',
      _focus: {
        zIndex: 1,
      },
      fontSize: { ...fontSizes.md, base: 'md' },
    },
    viewOnly: {
      ...Input.variants.viewOnly.field,
      fontSize: { ...fontSizes.md, base: 'sm', md: 'md' },
    },
  },
};
