import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { creditActions, creditSelector } from 'store/modules/credit';
import { AppDispatch } from 'store/root-reducer';
export const useTotalLocationAvailableCreditOfCompany = () => {
  const dispatch = useDispatch<AppDispatch>();
  const credit = useSelector(creditSelector);

  useEffect(() => {
    dispatch(creditActions.getTotalLocationAvailableCreditByCompany());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return credit.totalLocationAvailableCreditOfCompany;
};
