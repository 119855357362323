import { createAsyncThunk } from '@reduxjs/toolkit';

import { authApi } from 'apis/auth';

export const getCurrentUserPermission = createAsyncThunk(
  'auth/getCurrentUserPermission',
  async () => {
    const res = await authApi.getCurrentPermission();
    return res.data;
  },
);
