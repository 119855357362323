import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICreditOverview } from 'models/dashboard';

import { getAdminLogo, getCreditOverview } from './actions';
import { DashboardState } from './type';

const initialState: DashboardState = {
  adminLogo: null,
  creditOverview: null,
};

export const { actions, reducer: dashboardReducer } = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateAdminLogo(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.adminLogo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminLogo.fulfilled, (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.adminLogo = payload;
    });
    builder.addCase(getAdminLogo.rejected, (state) => {
      state.adminLogo = null;
    });

    builder.addCase(
      getCreditOverview.fulfilled,
      (state, action: PayloadAction<ICreditOverview[]>) => {
        const { payload } = action;
        state.creditOverview = payload;
      },
    );
    builder.addCase(getCreditOverview.rejected, (state) => {
      state.creditOverview = null;
    });
  },
});

const dashboardActions = {
  ...actions,
  getAdminLogo,
  getCreditOverview,
};

export { dashboardActions };

export * from './type';
export * from './selector';
