import { BoxProps, Center, Stack, StackProps } from '@chakra-ui/react';

import { useTranslate } from 'hooks/common';

import { TableProps } from '.';
import { CardItem } from './CardItem';

interface CardViewProps<RecordType extends object = any>
  extends StackProps,
    Pick<
      TableProps<RecordType>,
      | 'activeRowKey'
      | 'rowStyleProps'
      | 'noDataPlaceholder'
      | 'data'
      | 'columns'
      | 'expandable'
      | 'collapsible'
      | 'cardStyles'
      | 'error'
      | 'gridMobileProps'
      | 'defaultMobileRowExpandKeys'
    >,
    Required<Pick<TableProps<RecordType>, 'rowKey'>> {
  activeCellStyles: BoxProps;
  allExpandable?: boolean;
}

export const CardView = <RecordType extends object = any>({
  data,
  columns,
  collapsible,
  activeRowKey,
  cardStyles,
  rowStyleProps,
  activeCellStyles,
  noDataPlaceholder,
  expandable,
  allExpandable,
  error,
  gridMobileProps,
  defaultMobileRowExpandKeys,
  rowKey,
  ...props
}: CardViewProps<RecordType>) => {
  const t = useTranslate();
  return (
    <Stack as="ul" spacing="6px" {...props}>
      {!!data?.length &&
        data.map((record, recordIndex) => {
          const isActiveRow =
            activeRowKey !== undefined && rowKey(record, recordIndex) === activeRowKey;
          const rowProps =
            typeof rowStyleProps === 'function'
              ? rowStyleProps(record, recordIndex)
              : rowStyleProps;
          const key = rowKey(record, recordIndex) ?? recordIndex;
          const isDefaultExpand = !!defaultMobileRowExpandKeys?.find(
            (item) => item.toString() === key.toString(),
          );
          return (
            <CardItem
              collapsible={collapsible}
              key={key}
              columns={columns}
              activeCellStyles={isActiveRow ? activeCellStyles : undefined}
              isActive={isActiveRow}
              record={record}
              cardStyles={cardStyles}
              recordIndex={recordIndex}
              expandable={expandable}
              gridMobileProps={gridMobileProps}
              defaultExpanded={isDefaultExpand}
              allExpandable={allExpandable}
              {...rowProps}
            />
          );
        })}

      {(data?.length === 0 || (error && !data)) && (
        <Center py={{ base: '20px', md: 'auto' }}>
          {noDataPlaceholder ?? t('message.noData')}
        </Center>
      )}
    </Stack>
  );
};
