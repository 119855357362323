import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { DEFAULT_PAGE_SIZE } from 'constant';
import i18next from 'i18next';
import { AnyAxiosResponse, DataResponse, ListAxiosResponse, Option, Payload, Query } from 'types';
import { removeEmpty, removeNullish } from 'utils';

import { IEducationInstitute } from 'models/educational-institute';

import { EducationalInstituteFilterData } from 'components/molecules/EducationalInstituteFilter';

import { concatParams, mapErrorDescriptions, mapQueryToParams } from './utils';

export type EducationFormData = {
  name: string;
  description?: string;
  address: string;
};

const entity = 'education';
const basePath = `portal/${entity}` as const;

const educationPayloadScheme = {
  name: 'name',
  description: 'description',
  address: 'address',
};

export const educationApi = {
  async addInstitute(payload: Payload<EducationFormData>): Promise<DataResponse<null>> {
    try {
      const { body } = payload;

      const res: AxiosResponse<{
        data: IEducationInstitute;
        message: string;
      }> = await httpClient.post(`${basePath}/store`, removeNullish(removeEmpty({ ...body })));
      return {
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
        description: mapErrorDescriptions(educationPayloadScheme, error.description),
      });
    }
  },
  async updateInstitute(
    id: number | string,
    payload: Payload<EducationFormData>,
  ): Promise<DataResponse<{ data: IEducationInstitute; message: string }>> {
    try {
      const { body } = payload;
      const res: AxiosResponse<{
        data: IEducationInstitute;
        message: string;
      }> = await httpClient.put(`${basePath}/edit/${id}`, removeNullish({ ...body }));
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
        description: mapErrorDescriptions(educationPayloadScheme, error.description),
      });
    }
  },
  async getInstituteDetail(id: string): Promise<DataResponse<IEducationInstitute>> {
    try {
      const res: AxiosResponse<{
        data: IEducationInstitute;
        message: string;
      }> = await httpClient.get(`${basePath}/show/${id}`);
      return {
        data: res.data.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async disableInstitute(id: number): Promise<DataResponse<null>> {
    try {
      const res: AxiosResponse<{
        data: IEducationInstitute;
        message: string;
      }> = await httpClient.delete(`${basePath}/disable/${id}`);
      return {
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t('message.disableEducationalInstitutesUnsuccess') } =
        error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async enableInstitute(id: number): Promise<DataResponse<null>> {
    try {
      const res: AxiosResponse<{
        data: IEducationInstitute;
        message: string;
      }> = await httpClient.put(`${basePath}/enable/${id}`);
      return {
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t('message.enableEducationalInstitutesUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getListInstitute(
    payload: Payload<null, null, Query & Partial<EducationalInstituteFilterData>>,
  ): Promise<DataResponse<IEducationInstitute[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, recent, educationStatus } = query || {};
      const filterTypes = concatParams(recent ? 'recent' : '', educationStatus ?? '');

      const res: ListAxiosResponse<IEducationInstitute> = await httpClient.get(
        `${basePath}/index`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              filter_types: filterTypes,
            },
            {
              sortDefaultByCreatedAt: true,
            },
          ),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: {
          current: data.page || 1,
          pageSize: +data.limit || DEFAULT_PAGE_SIZE,
          total: data.total,
        },
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async summary(): Promise<DataResponse<{ total: number }>> {
    try {
      const res: AxiosResponse<{ data: { total: number }; message: string }> = await httpClient.get(
        `${basePath}/summary`,
      );
      return {
        status: true,
        data: res.data.data,
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getOptions(): Promise<DataResponse<Option[]>> {
    try {
      const res: AnyAxiosResponse<Option[]> = await httpClient.get(`${basePath}/option`);
      const { data, message } = res.data;

      return {
        status: true,
        data,
        message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
};

export type EducationApi = typeof educationApi;
