import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CompanyState } from './type';

const initialState: CompanyState = {
  summary: {
    totalActive: 0,
    totalDisable: 0,
    total: 0,
    init: false,
  },
  options: null,
  hasHQOptions: null,
  banner: null,
};

export const { actions: companyActions, reducer: companyReducer } = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateSummary(state, action: PayloadAction<Omit<CompanyState['summary'], 'init'>>) {
      const { payload } = action;
      state.summary = {
        ...payload,
        init: false,
      };
    },
    updateBanner(state, action: PayloadAction<CompanyState['banner']>) {
      const { payload } = action;
      state.banner = payload;
    },
    setOptions(state, action: PayloadAction<CompanyState['options']>) {
      const { payload } = action;
      state.options = payload;
    },
    setHasHQOptions(state, action: PayloadAction<CompanyState['options']>) {
      const { payload } = action;
      state.hasHQOptions = payload;
    },
    updateCompanyOptionAvailableCredits(
      state,
      action: PayloadAction<{ companyId: number; amount: number }>,
    ) {
      const { companyId, amount } = action.payload;
      const updateItem = state.options?.find((item) => item.value === companyId);
      if (updateItem) {
        updateItem.available_credits += amount;
      }
    },
  },
});

export * from './type';
export * from './selector';
