import { typography } from './typography';
import { colors } from './colors';
import { shadows } from './shadows';
import { radii } from './radius';
import { breakpoints } from './breakpoints';

export const foundations = {
  ...typography,
  colors,
  shadows,
  radii,
  breakpoints,
};
