import axios, { Canceler } from 'axios';
import { DEFAULT_PAGE_SIZE } from 'constant';
import noop from 'lodash/noop';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { AsyncReturnType, DataResponse } from 'types';
import { removeNullish } from 'utils';

import { PaginationState } from 'components/molecules';

import { useDeepMemo, useMergedState } from 'hooks/common';

type State<D, F> = {
  loading: boolean;
  error: any;
  data: D[] | null;
  pagination: PaginationState;
  filter: F | null;
};

export const usePaginationQuery = <
  Data extends object = any,
  Func extends (...any: any[]) => any = any,
  Payload extends object = any,
  Filter extends object = any,
>(
  queryPayload: Payload | null,
  queryFnc: Func,
  config?: {
    initialState?: Partial<State<Data, Filter>> | null;
    initialPagination?: Partial<PaginationState> | null;
    preventInitCall?: boolean;
    needCancel?: boolean;
    intervalTime?: number; // ms
    cancelIfOutdated?: boolean;
    resetDataWhenReceiveError?: boolean;
    onChange?: (data: Data[]) => void;
    onSuccess?: (res: AsyncReturnType<Func>) => void;
    onError?: (error: any) => void;
  },
) => {
  const {
    initialState: initialData = {},
    preventInitCall,
    needCancel,
    intervalTime,
    cancelIfOutdated,
    resetDataWhenReceiveError,
    initialPagination,
    onChange = noop,
    onSuccess = noop,
    onError = noop,
  } = config || {};

  const initialState = useMemo(
    () => ({
      loading: false,
      error: null,
      data: null,
      pagination: {
        pageSize: DEFAULT_PAGE_SIZE,
        current: 1,
        total: 0,
        ...initialPagination,
      },
      filter: null,
      ...removeNullish(initialData ?? {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const canceler = useRef<Canceler | null>(null);
  const interval = useRef<number | null>(null);

  const [state, stateController, stateRef] = useMergedState<State<Data, Filter>>(initialState);

  const memoizedPayload = useDeepMemo(() => ({ pagination: state.pagination, ...queryPayload }), {
    pagination: state.pagination,
    ...queryPayload,
  });

  const cancel = (message?: string) => {
    canceler.current && canceler.current(message);
  };

  const refetch = async (args: typeof queryPayload): Promise<DataResponse<Data[]>> => {
    try {
      if (state.loading && cancelIfOutdated) {
        cancel();
      }
      stateController.update({ loading: true, error: null });
      const payload: any = args || { pagination: state.pagination };
      if (needCancel || cancelIfOutdated) {
        payload.cancelToken = new axios.CancelToken(function executor(c) {
          canceler.current = c;
        });
      }
      const res: DataResponse<Data[]> = await queryFnc(payload);
      onSuccess(res);
      stateController.update((prev) => ({
        error: null,
        data: res.data as Data[],
        loading: false,
        pagination: {
          ...prev.pagination,
          ...payload.pagination,
          total: res.pagination?.total,
          lastPage: res.pagination?.lastPage,
        },
      }));
      return res;
    } catch (error: any) {
      stateController.update(
        resetDataWhenReceiveError
          ? { error, loading: false, data: null }
          : { error, loading: false },
      );
      onError(error);
      return error;
    }
  };

  const stopInterval = () => {
    interval.current && clearInterval(interval.current);
  };

  const hotUpdateItems = (
    condition: (item: Data) => boolean,
    values: ((item: Data) => Partial<Data>) | Partial<Data>,
  ) => {
    stateController.set((prevState) => {
      // return prevState
      if (!prevState.data?.length) return prevState;
      let hasUpdate = false;

      let clonedData = prevState.data.map((item) => {
        if (condition(item)) {
          if (!hasUpdate) {
            hasUpdate = true;
          }
          const updates = typeof values === 'function' ? values(item) : values;
          return { ...item, ...updates };
        }
        return item;
      });

      if (!hasUpdate) return prevState;

      return {
        ...prevState,
        data: clonedData,
      };
    });
  };

  const reset = useCallback(() => {
    if (stateController.isDirty()) {
      stateController.set(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState, stateController.isDirty]);

  const calcItemNo = (index: number) => {
    return (state.pagination.current - 1) * state.pagination.pageSize + index + 1;
  };

  useEffect(() => {
    onChange(state.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  useEffect(() => {
    if (!preventInitCall) {
      refetch(queryPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (intervalTime) {
      interval.current = setInterval(refetch, intervalTime, memoizedPayload);
    }
    return () => {
      interval.current !== null && clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalTime, memoizedPayload]);

  const tool = {
    error: state.error,
    filter: state.filter,
    ref: stateRef,
    ...stateController,
    cancel,
    stopInterval,
    refetch: refetch as Func,
    updateItems: hotUpdateItems,
    calcItemNo,
    reset,
  };

  const result: [typeof state.data, boolean, typeof state.pagination, typeof tool] = [
    state.data,
    state.loading,
    state.pagination,
    tool,
  ];

  return result;
};
