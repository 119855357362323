import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getAvailableCreditOfCompany, getTotalLocationAvailableCreditByCompany } from './action';
import { getTotalLocationAvailableCredit } from './action';
import { CreditState } from './type';

const initialState: CreditState = {
  availableCreditsOfCompany: null,
  totalLocationAvailableCreditOfCompany: null,
  totalLocationAvailableCredit: null,
};

const { actions, reducer: creditReducer } = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    setAvailableCredits(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.availableCreditsOfCompany = payload;
    },
    setAvailableCreditLocationCompany(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.totalLocationAvailableCreditOfCompany = payload;
    },
    setAvailableCreditLocation(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.totalLocationAvailableCredit = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAvailableCreditOfCompany.fulfilled,
      (state, action: PayloadAction<number>) => {
        const { payload } = action;
        state.availableCreditsOfCompany = payload;
      },
    );
    builder.addCase(getAvailableCreditOfCompany.rejected, (state) => {
      state.availableCreditsOfCompany = null;
    });
    builder.addCase(
      getTotalLocationAvailableCreditByCompany.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.totalLocationAvailableCreditOfCompany = action.payload;
      },
    );
    builder.addCase(getTotalLocationAvailableCreditByCompany.rejected, (state) => {
      state.totalLocationAvailableCreditOfCompany = null;
    });
    builder.addCase(
      getTotalLocationAvailableCredit.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.totalLocationAvailableCredit = action.payload;
      },
    );
    builder.addCase(getTotalLocationAvailableCredit.rejected, (state) => {
      state.totalLocationAvailableCredit = null;
    });
  },
});

const creditActions = {
  ...actions,
  getAvailableCreditOfCompany,
  getTotalLocationAvailableCreditByCompany,
  getTotalLocationAvailableCredit,
};

export { creditReducer, creditActions };

export * from './type';
export * from './selector';
