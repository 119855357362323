import { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { applicantActions, applicantSummarySelector } from 'store/modules/applicant';

import { ApplicantApi, applicantApi } from 'apis/applicant';

import { InternalPermission } from 'types';

import { Typography } from 'components/atoms';
import { AppDispatch } from 'store/root-reducer';
import { useQuery, useTranslate } from 'hooks/common';

export const useApplicantSummary = (refreshOnUnmounted: boolean = false) => {
  const dispatch = useDispatch<AppDispatch>();
  const storedSummary = useSelector(applicantSummarySelector);
  const t = useTranslate();

  const [summary, loading, , tool] = useQuery<
    { totalActive: number; totalDisable: number; total: number },
    any,
    ApplicantApi['summary']
  >(null, applicantApi.summary, {
    preventInitCall: storedSummary.init,
    permission: [
      InternalPermission.VIEW_APPLICANT,
      InternalPermission.CREATE_APPLICANT,
      InternalPermission.UPDATE_APPLICANT,
    ],
  });

  const summarySections = useMemo(() => {
    const { totalActive, totalDisable, total } = storedSummary;
    return [
      {
        label: (
          <Typography.Text>
            <Trans t={t} i18nKey={'label.totalNumberOfApplicants'} components={[<br key={0} />]} />
          </Typography.Text>
        ),
        count: total,
      },
      {
        label: (
          <Typography.Text>
            <Trans t={t} i18nKey={'label.numberOfActiveApplicants'} components={[<br key={0} />]} />
          </Typography.Text>
        ),
        count: totalActive,
      },
      {
        label: (
          <Typography.Text>
            <Trans
              t={t}
              i18nKey={'label.numberOfDisableApplicants'}
              components={[<br key={0} />]}
            />
          </Typography.Text>
        ),
        count: totalDisable,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedSummary]);

  useEffect(() => {
    if (summary) {
      dispatch(applicantActions.updateSummary(summary));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  useEffect(() => {
    return () => {
      refreshOnUnmounted && tool.refetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof summarySections, boolean, typeof tool.refetch, typeof storedSummary]>(
    () => [summarySections, loading, tool.refetch, storedSummary],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summarySections, loading],
  );
};
