import { Button, ButtonProps } from '@chakra-ui/react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { FC } from 'react';
import { DeviceMode } from 'types';

import { colors } from 'themes/foundations/colors';

import { useDeviceMode } from 'hooks/common';

import { addHexOpacity } from 'utils/opacity';

import { fontSizes } from './Typography';

// export interface PrimaryButtonProps extends Omit<ButtonProps, 'bg'> {
export interface PrimaryButtonProps extends ButtonProps {
  // bg?: 'primary' | 'secondary';
  padding?: string;
  animateDuration?: string;
  uppercase?: boolean;
}

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  children,
  bg = 'primary',
  animateDuration,
  onClick = noop,
  className,
  uppercase,
  ...props
}) => {
  const device = useDeviceMode();

  switch (device) {
    case DeviceMode.Mobile:
    case DeviceMode.Tablet:
      return (
        <Button
          className={classNames('PrimaryButton', className, {
            'text-uppercase': uppercase || true,
          })}
          border={props.variant ? undefined : '2px solid'}
          {...props}
          borderColor={props.variant ? undefined : bg}
          bg={props.variant ? undefined : bg}
          boxShadow={`0 3px 6px ${
            bg === 'primary'
              ? addHexOpacity(colors.tango[600], 30)
              : addHexOpacity(colors.vulcan[500], 20)
          }`}
          colorScheme={bg === 'primary' ? 'tango' : 'vulcan'}
          onClick={onClick}
          _disabled={
            props.variant
              ? undefined
              : {
                  opacity: 0.1,
                  cursor: 'not-allowed',
                }
          }
          _hover={{}}
        >
          {children}
        </Button>
      );

    default:
      if (props.disabled || props.isLoading || props.isDisabled)
        return (
          <Button
            className={classNames('PrimaryButton', className, {
              'text-uppercase': uppercase || true,
            })}
            border={props.variant ? undefined : '2px solid'}
            fontSize={fontSizes.sm}
            {...props}
            borderColor={props.variant ? undefined : bg}
            bg={props.variant ? undefined : bg}
            _disabled={
              props.variant
                ? undefined
                : {
                    opacity: 0.1,
                    cursor: 'not-allowed',
                  }
            }
          >
            {children}
          </Button>
        );

      return (
        <Button
          as="button"
          className={classNames('PrimaryButton', className, {
            'text-uppercase': uppercase || true,
          })}
          pos="relative"
          role="group"
          overflow="hidden"
          borderRadius="4px"
          boxShadow={`0 3px 6px ${
            bg === 'primary' ? colors.tangoOpacity[30] : colors.vulcanOpacity[30]
          }`}
          onClick={onClick}
          type={props.type ?? 'button'}
          backgroundColor="transparent"
          borderColor="transparent"
          {...props}
        >
          {children}
          <Button
            as="span"
            pos="absolute"
            inset="0"
            transitionDuration={animateDuration || '500ms'}
            _groupHover={{ transform: 'translateX(-100%)' }}
            border="2px solid"
            borderRadius="4px"
            {...props}
            zIndex="docked"
            borderColor={bg}
            bg={bg}
            _hover={{
              bg: bg,
            }}
          >
            {children}
          </Button>
          <Button
            className="layer-down"
            as="span"
            pos="absolute"
            inset="0"
            border="2px solid"
            color="vulcan.600"
            variant="none"
            w="100%"
            borderRadius="4px"
            {...props}
            borderColor={bg}
            bg="white"
          >
            {children}
          </Button>
        </Button>
      );
  }
};