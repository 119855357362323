import { createSlice } from '@reduxjs/toolkit';

import { getUnreadCount } from './action';
import { NotificationState } from './type';

const initialState: NotificationState = {
  unreadCount: 0,
  fetchUnreadCountStatus: null,
};

const { actions, reducer: notificationReducer } = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUnreadCount.fulfilled, (state, action) => {
      state.unreadCount = action.payload;
      state.fetchUnreadCountStatus = action.meta.requestStatus;
    });
    builder.addCase(getUnreadCount.pending, (state, action) => {
      state.fetchUnreadCountStatus = action.meta.requestStatus;
    });
    builder.addCase(getUnreadCount.rejected, (state, action) => {
      state.fetchUnreadCountStatus = action.meta.requestStatus;
    });
  },
});
const notificationActions = {
  ...actions,
  getUnreadCount,
};

export { notificationReducer, notificationActions };
export * from './type';
export * from './selector';
