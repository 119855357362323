import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { useRef } from 'react';

import { CaretDownIcon } from 'assets/icons';

const RatingInput = ({
  onValueChange,
  ...props
}: { onValueChange: (value?: string) => void } & InputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <InputGroup maxW="120px">
      <Input
        ref={ref}
        fontSize="lg"
        type="number"
        step={0.5}
        textAlign="center"
        max={5}
        min={0}
        readOnly
        {...props}
      />
      <InputRightElement>
        <Stack spacing="4px" alignItems="center">
          <IconButton
            w="17px"
            h="16px"
            minW={0}
            minH={0}
            aria-label="increase"
            icon={<CaretDownIcon />}
            colorScheme="tango"
            variant="link"
            transform="rotate(180deg)"
            onClick={() => {
              ref.current?.stepUp();
              onValueChange(ref.current?.value);
            }}
          />
          <IconButton
            w="16px"
            h="16px"
            minW={0}
            minH={0}
            aria-label="decrease"
            icon={<CaretDownIcon />}
            colorScheme="tango"
            variant="link"
            onClick={() => {
              ref.current?.stepDown();
              onValueChange(ref.current?.value);
            }}
          />
        </Stack>
      </InputRightElement>
    </InputGroup>
  );
};

export default RatingInput;
