import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isFetchingLocationOption,
  locationActions,
  locationOptionSelector,
} from 'store/modules/location';
import { AppDispatch } from 'store/root-reducer';
export const useLocationOptions = (preventCall?: boolean) => {
  const dispatch = useDispatch<AppDispatch>();
  const storedOptions = useSelector(locationOptionSelector);
  const isLoading = useSelector(isFetchingLocationOption);

  useEffect(() => {
    if (!preventCall) {
      dispatch(locationActions.getLocationOptions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof storedOptions, boolean]>(
    () => [storedOptions, isLoading],

    [isLoading, storedOptions],
  );
};
