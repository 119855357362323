import { RootState } from 'store/root-reducer';

export const paymentSummarySelector = ({ payment }: RootState) => payment.summary;
export const isFetchingPaymentSummarySelector = ({ payment }: RootState) =>
  payment.fetchingSummaryStatus === 'pending';

export const banksSelector = ({ payment }: RootState) => payment.banks;
export const isFetchingBanksSelector = ({ payment }: RootState) =>
  payment.fetchingBankStatus === 'pending';
export const consumedCreditsSelector = ({ payment }: RootState) => payment.consumedCredits;
export const isFetchingConsumedCreditsSelector = ({ payment }: RootState) =>
  payment.fetchingConsumedCreditStatus === 'pending';
