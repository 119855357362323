export enum Language {
  English = 'en',
  Chinese = 'cn',
  Vietnamese = 'vn',
}

export const LANGUAGES = [
  {
    code: Language.English,
    label: 'English',
  },
  {
    code: Language.Chinese,
    label: 'Chinese',
  },
  {
    code: Language.Vietnamese,
    label: 'Tiếng Việt',
  },
];
