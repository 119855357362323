import { createResponsiveStyle } from 'themes/foundations/breakpoints';

// TODO: implement custom sizes
export const Input = {
  baseStyle: {
    field: {
      color: 'secondary',
      fontFamily: { base: 'regular', lg: 'heading' },

      _placeholder: {
        color: '#707070',
        opacity: 0.5,
        fontFamily: 'italic',
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: '10px',
        height: createResponsiveStyle({ base: '40px', '2xl': '48px' }),
        _focus: {
          zIndex: 1,
        },
        fontSize: createResponsiveStyle({ base: 'md', md: 'md', lg: 'sm-md', '2xl': 'md' }),
      },
      addon: {
        borderRadius: '10px',
        height: createResponsiveStyle({ base: '40px', '2xl': '48px' }),
        _focus: {
          zIndex: 1,
        },
        fontSize: createResponsiveStyle({ base: 'sm', md: 'md', lg: 'sm-md', '2xl': 'md' }),
      },
    },
    viewOnly: {
      field: {
        _disabled: {
          borderColor: 'inherit',
          bg: 'white',
          borderWidth: '1px',
        },
      },
    },
  },
};
