import {Gender} from "types";
import {useTranslate} from "./useTranslate";

export const useGenderLabels = ()=>{
  const t = useTranslate();
  const GENDER_LABEL: Record<Gender, string> = {
    [Gender.Female]: t('value.female'),
    [Gender.Male]: t('value.male'),
    [Gender.Other]: t('value.other'),
  };

  return GENDER_LABEL;
}