import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EducationApi, educationApi } from 'apis/education';
import {
  educationalInstituteActions,
  educationalInstituteOptionSelector,
} from 'store/modules/educational-institute';
import { useQuery } from 'hooks/common';
import { Option } from 'types';
import { AppDispatch } from 'store/root-reducer';
export const useEducationalInstituteOptions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const storedOptions = useSelector(educationalInstituteOptionSelector);

  const [data, loading, , tool] = useQuery<Option[], any, EducationApi['getOptions']>(
    null,
    educationApi.getOptions,
    {
      preventInitCall: storedOptions.length > 0,
    },
  );

  useEffect(() => {
    if (data) {
      dispatch(educationalInstituteActions.updateOption(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => {
      tool.refetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof storedOptions, boolean, typeof tool.refetch, typeof data]>(
    () => [storedOptions, loading, tool.refetch, data],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedOptions, loading],
  );
};
