import { Box, BoxProps, Center } from '@chakra-ui/react';
import { Fragment, ReactNode, forwardRef, useRef } from 'react';

import { colors } from 'themes/foundations/colors';

import { Loading } from 'components/molecules';

export interface CardProps extends BoxProps {
  round?: BoxProps['borderRadius'];
  centered?: boolean;
  fitParent?: boolean;
  showBackdrop?: boolean;
  loading?: boolean;
  backdropFilterBlur?: string;
  backdropBrightness?: string;
  showBehindBlur?: boolean;
  behindBlurStyleProps?: BoxProps;
  children?: ReactNode;
  renderChildren?: (container: React.RefObject<HTMLDivElement>) => ReactNode;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      renderChildren,
      children,
      centered,
      fitParent,
      showBackdrop,
      loading = false,
      className,
      backdropFilterBlur,
      backdropBrightness,
      showBehindBlur,
      behindBlurStyleProps,
      ...props
    },
    ref,
  ) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const CenterWrapper = centered ? Center : Fragment;

    const getStyles = (): BoxProps => {
      let styles: BoxProps = {
        boxShadow: 'sm',
        p: '10px',
        bg: 'white',
        borderRadius: props.round || 'xl',
        position: 'relative',
      };
      if (fitParent) {
        styles.boxSize = '100%';
      }
      if (showBehindBlur) {
        styles.position = 'relative';
        styles.boxShadow = 'none';
      }
      return styles;
    };

    return (
      <Box
        className={`Card ${className ?? ''}`}
        {...getStyles()}
        {...props}
        sx={{
          '&.disable': {
            bg: 'disable',
          },
          '&.row-edit': {
            bg: colors.vulcan[200],
          },
        }}
        ref={ref ?? cardRef}
      >
        {showBehindBlur && (
          <Box
            position="absolute"
            inset="0"
            borderRadius={getStyles().borderRadius || props.borderRadius}
            filter="blur(1px)"
            zIndex="hide"
            {...behindBlurStyleProps}
          />
        )}
        <CenterWrapper>{renderChildren ? renderChildren(cardRef) : children}</CenterWrapper>
        {loading && (
          <Loading
            zIndex="overlay"
            position="absolute"
            inset="0"
            borderRadius={getStyles().borderRadius}
          />
        )}
      </Box>
    );
  },
);

Card.displayName = 'Card';
