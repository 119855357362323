import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
  variants: {
    bold: {
      fontFamily: 'heading',
    },
    italic: {
      fontFamily: 'italic',
    },
  },
};
