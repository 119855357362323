import { PATH_NAME } from 'configs';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { authSelector } from 'store/modules/auth';

import { Loading, LoadingProps } from 'components/molecules';

export type AuthRouteGuardProps = { children: React.ReactNode } & Pick<LoadingProps, 'minH'>;
export const AuthRouteGuard: FC<AuthRouteGuardProps> = ({ children, minH }) => {
  const auth = useSelector(authSelector);
  if (auth.init) return <Loading h="100%" loading tip="Authenticating..." minH={minH} />;

  if (!auth.init && !auth.isAuthenticated) return <Navigate to={PATH_NAME.LOGIN} />;

  return <> {children} </>;
};
