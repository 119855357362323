import { Text } from './Text';
import { Title } from './Title';
import { Paragraph } from './Paragraph';

export const Typography = {
  Title: Title,
  Text: Text,
  Paragraph: Paragraph,
};

export * from './Text';
export * from './Title';
export * from './Paragraph';
