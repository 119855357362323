import orderBy from 'lodash/orderBy';
import { Option, Primitive } from 'types';

export const createArray = <T extends Function | Primitive>(
  num: number,
  item: ((index: number) => T) | T,
): Array<T> => {
  return Array.from(
    {
      length: num,
    },
    (_: any, i: number) => (typeof item === 'function' ? item(i) : item),
  );
};

export const orderOptions = <T extends Option>(data: T[]) => {
  return orderBy(data, ['label'], 'asc');
};
