export const typography = {
  fonts: {
    heading: 'Roboto Bold',
    body: 'Roboto',
    mono: 'Roboto',
    italic: 'Roboto Italic',
    bold: 'Roboto Bold',
    regular: 'Roboto',
  },
  fontSizes: {
    sm: '0.75rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    '2xl': '2.5rem',
    '3xl': '3.5rem',
    'sm-sm': '0.625rem',
    'sm-md': '0.875rem',
    'sm-lg': '1.25rem',
    'sm-xl': '1.75rem',
    'sm-2xl': '2.25rem',
    'sm-3xl': '3rem',
  },
};
/** screen: 1920
 * 12 sm
 * 16 md
 * 24 lg
 * 32 xl
 * 40 2xl
 * 56 3xl
 */

/** screen: 1280 => < 1920
 * 8 sm
 * 14 md
 * 20 lg
 * 28 xl
 * 36 2xl
 * 48 3xl
 */
