import { Option } from 'types';
import { useJobStatusTextLabels } from './useJobStatusTextLabels';

export const useJobStatusTextList = () => {
  const jobStatusTextLabels = useJobStatusTextLabels();

  const JOB_STATUS_TEXT_LIST: Option[] = Object.entries(jobStatusTextLabels).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return JOB_STATUS_TEXT_LIST
};
