import { Box, TableProps } from '@chakra-ui/react';
import { DATE_TIME_BE, dateHourADisplay } from 'constant';
import dayjs from 'dayjs';
import { withPermission } from 'hocs/withPermission';
import noop from 'lodash/noop';
import React, { useEffect } from 'react';
import { Query } from 'types';
import { addNumberSymbolPrefix } from 'utils';

import { CreditApi, creditApi } from 'apis/credit';

import { ICompany } from 'models/company';
import { ICreditLog } from 'models/credit';

import { InternalPermission } from 'types';

import { Typography } from 'components/atoms';
import { Action, ColumnsType, Filter, Sorter, Table } from 'components/organisms/Table';

import { useMergedState, usePaginationQuery, useTranslate } from 'hooks/common';
import { useCounterFlag } from 'hooks/components/config';

import { Paginate, PaginationState } from '.';
import { CompanyCreditHistoryFilterData } from './CompanyCreditHistoryFilter';

export type CreditHistoryListByCompanyProps = {
  company?: ICompany | null;
  companyId: number | string;
  size?: TableProps['size'];
  query?: Query<CompanyCreditHistoryFilterData> | null;
  isParentLoading?: boolean;
  onChangePagination?: (value: PaginationState) => void;
};

export const CreditHistoryListByCompany: React.FC<CreditHistoryListByCompanyProps> = ({
  company,
  size,
  companyId,
  query: parentQuery,
  isParentLoading,
  onChangePagination = noop,
}) => {
  const requestCompanyId = company?.id ?? companyId;
  const [data, loading, pagination, tool] = usePaginationQuery<
    ICreditLog,
    CreditApi['companyCreditHistoryById']
  >(
    {
      query: {
        companyId: requestCompanyId,
      },
    },
    creditApi.companyCreditHistoryById,
    { preventInitCall: !!company || !!parentQuery },
  );

  const [query, queryControl] = useMergedState<Query<CompanyCreditHistoryFilterData>>({
    sort: null,
    search: null,
    filterGroup: null,
  });

  const resetFlag = useCounterFlag().value;
  const t = useTranslate();

  const handleRefreshData = (params?: {
    pagination?: Partial<typeof pagination>;
    query?: Partial<typeof query>;
  }) => {
    const { sort, search, filterGroup } = params?.query ?? { ...query, ...parentQuery };
    tool.refetch({
      pagination: { ...pagination, ...params?.pagination },
      query: {
        sort,
        companyId: requestCompanyId,
        search,
        ...filterGroup,
      },
    });
  };

  const columns: ColumnsType<ICreditLog>[] = [
    {
      key: 'date',
      title: t('label.date'),
      dataIndex: 'date',
      cellStyleProps: {
        py: { base: 0, md: 3 },
        textAlign: { base: 'left', md: 'center' },
      },
      render: (date: string) => (
        <Typography.Text>{dayjs(date, DATE_TIME_BE).format(dateHourADisplay())}</Typography.Text>
      ),
    },
    {
      key: 'assigned_credits',
      title: t('label.assignedCredits'),
      dataIndex: 'assigned_credits',
      allowSort: true,
      render: (value: number) => (
        <Typography.Title
          fontFamily={{ base: 'regular', md: 'heading' }}
          textAlign={{ base: 'left', md: 'center' }}
          display="block"
          fontSize={{ base: 'sm', md: 'md', lg: 'sm-lg', '2xl': 'lg' }}
        >
          {addNumberSymbolPrefix(value)}
        </Typography.Title>
      ),
    },
    {
      key: 'available_credits',
      title: t('label.availableCredits'),
      dataIndex: 'available_credits',
      allowSort: true,
      render: (value: number) => (
        <Typography.Title
          fontFamily={{ base: 'regular', md: 'heading' }}
          textAlign={{ base: 'left', md: 'center' }}
          display="block"
          fontSize={{ base: 'sm', md: 'md', lg: 'sm-lg', '2xl': 'lg' }}
        >
          {value.toLocaleString('en-US')}
        </Typography.Title>
      ),
    },
    {
      key: 'comment',
      title: t('label.comment'),
      dataIndex: 'comment',
      innerCellStyleProps: {
        noOfLines: 3,
      },
      renderMobile: (value: string) => (
        <Box>
          <Typography.Text size="sm" breakWord>
            {value}
          </Typography.Text>
        </Box>
      ),
    },
  ];

  const handleTableChange = (
    pagination: Paginate | null,
    sort: Sorter | null,
    filter: Filter | null,
    action: Action,
  ) => {
    switch (action) {
      case 'sort':
        queryControl.update({ sort });
        break;
      case 'pagination':
        pagination && handleRefreshData({ pagination });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (company) {
      queryControl.reset();
      handleRefreshData({ pagination: { current: 1 }, query: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id]);

  useEffect(() => {
    onChangePagination(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    if (queryControl.isDirty()) {
      handleRefreshData({ pagination: { current: 1 } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, queryControl.isDirty]);

  useEffect(() => {
    if (parentQuery) {
      handleRefreshData({ pagination: { current: 1 } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentQuery]);

  useEffect(() => {
    if (resetFlag) {
      queryControl.reset();
      handleRefreshData({ pagination: { current: 1 }, query: {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFlag]);

  return (
    <Table
      styleProps={{ size }}
      loading={isParentLoading ? false : loading}
      columns={columns}
      data={data}
      pagination={pagination}
      rowKey={(record) => record.id}
      sorter={query.sort}
      onChange={handleTableChange}
    />
  );
};

export const CreditHistoryListByCompanyWithPermission = withPermission(
  InternalPermission.VIEW_COMPANY_CREDIT_HISTORY,
  CreditHistoryListByCompany,
);
