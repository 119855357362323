import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { jobTypeActions, jobTypeSummarySelector } from 'store/modules/job-type';

import { JobTypeApi, jobTypeApi } from 'apis/job-type';

import { InternalPermission } from 'types';

import { Typography } from 'components/atoms';

import { useQuery } from 'hooks/common';
import { AppDispatch } from 'store/root-reducer';
import i18next from 'configs/i18n';

export const useJobTypeSummary = (refreshOnUnmounted: boolean = false) => {
  const dispatch = useDispatch<AppDispatch>();
  const storedSummary = useSelector(jobTypeSummarySelector);

  const [summary, loading, , tool] = useQuery<{ total: number }, any, JobTypeApi['summary']>(
    null,
    jobTypeApi.summary,
    {
      preventInitCall: storedSummary.init,
      permission: [
        InternalPermission.VIEW_JOB_TYPE,
        InternalPermission.CREATE_JOB_TYPE,
        InternalPermission.UPDATE_JOB_TYPE,
      ],
    },
  );

  const summarySections = useMemo(() => {
    const { total } = storedSummary;
    return [
      {
        label: (
          <Typography.Text>
            {i18next.t('message.totalNumberOf')}
            <br />
            {i18next.t('title.jobTypes')}
          </Typography.Text>
        ),
        count: total,
      },
    ];
  }, [storedSummary]);

  useEffect(() => {
    if (summary) {
      dispatch(jobTypeActions.updateSummary(summary));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  useEffect(() => {
    return () => {
      refreshOnUnmounted && tool.refetch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof summarySections, boolean, typeof tool.refetch, typeof storedSummary]>(
    () => [summarySections, loading, tool.refetch, storedSummary],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summarySections, loading],
  );
};
