import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { locationApi } from 'apis/location';

import { Role } from 'models/user';

import { currentRoleSelector } from '../auth';

export const getFirstLocation = createAsyncThunk(
  'location/getFirstLocation',
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const role = currentRoleSelector(rootState);
    switch (role) {
      case Role.HqManager: {
        const res = await locationApi.getFirstLocationByHqCompany();
        return res.data;
      }
      case Role.AreaManager: {
        const res = await locationApi.getFirstAreaLocation();
        return res.data;
      }
    }
  },
  {
    condition: (_, { getState }) => {
      const { location } = getState() as RootState;
      if (location.fetchFirstLocationStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getLocationSummary = createAsyncThunk(
  'location/summary',
  async (arg, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const role = currentRoleSelector(rootState);
    let total = 0;
    switch (role) {
      case Role.AreaManager:
        {
          const res = await locationApi.summaryArea();
          total = res.data.total;
        }
        break;
      case Role.HqManager:
        {
          const res = await locationApi.summaryHq();
          total = res.data.total;
        }
        break;
      case Role.LocationManager:
        break;
      default:
        {
          const res = await locationApi.summary();
          total = res.data.total;
        }
        break;
    }
    return total;
  },
  {
    condition: (_, { getState }) => {
      const { location } = getState() as RootState;
      if (location.fetchSummaryStatus === 'pending') {
        return false;
      }
    },
  },
);

export const getLocationOptions = createAsyncThunk(
  'location/getLocationOptions',
  async () => {
    const res = await locationApi.getOptions();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { location } = getState() as RootState;
      if (location.fetchOption === 'pending') {
        return false;
      }
    },
  },
);
