import { createContext, useContext, useState, ReactNode, SetStateAction, Dispatch } from 'react';

interface ProfileContextValue {
  isSuperHq: boolean;
  setIsSuperHq: Dispatch<SetStateAction<boolean>>; // Use Dispatch and SetStateAction
}

const ProfileContext = createContext<ProfileContextValue | undefined>(undefined);

interface ProfileProviderProps {
  children: ReactNode;
}

export function ProfileProvider({ children }: ProfileProviderProps) {
  const [isSuperHq, setIsSuperHq] = useState(false);

  const contextValue: ProfileContextValue = {
    isSuperHq,
    setIsSuperHq,
  };

  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  );
}

export function useProfileContext() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfileContext must be used within a ProfileProvider');
  }
  return context;
}


