import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobPostState } from './type';

const initialState: JobPostState = {
  summary: {
    total: 0,
    init: false,
  },
  options: [],
};

export const { actions: jobPostActions, reducer: jobPostReducer } = createSlice({
  name: 'jobPost',
  initialState,
  reducers: {
    updateSummary(state, action: PayloadAction<Omit<JobPostState['summary'], 'init'>>) {
      const { payload } = action;

      state.summary = {
        ...payload,
        total: payload.total,
        init: false,
      };
    },
    updateOption(state, action: PayloadAction<JobPostState['options']>) {
      const { payload } = action;
      state.options = payload;
    },
  },
});

export * from './type';
export * from './selector';
