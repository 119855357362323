import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

export const useNow = (delay?: number) => {
  const [now, setNow] = useState(dayjs.tz(undefined));
  const intervalId = useRef<NodeJS.Timeout | null>();

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setNow(dayjs.tz(undefined));
    }, delay || 1000);
    return () => {
      if (intervalId.current !== null && intervalId.current !== undefined) {
        clearInterval(intervalId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return now;
};
