import { FC, Fragment } from 'react';

import { IApplicant } from 'models/applicant';

import { useTranslate } from 'hooks/common';

import { getWorkExperience } from 'constant/applicant';

import { Typography } from './Typography';

type Props = {
  experiences: Required<IApplicant>['work_experiences'];
};

export const WorkExperience: FC<Props> = ({ experiences }) => {
  const t = useTranslate();
  return (
    <>
      {experiences.map((work, idx) => (
        <Fragment key={idx}>
          <Typography.Text variant="italic">
            {work?.job_type?.title ?? work.job_type_other ?? t('label.unknown')} -{' '}
            {t(getWorkExperience(work.duration).label as any)}
          </Typography.Text>
          <br />
        </Fragment>
      ))}
    </>
  );
};
