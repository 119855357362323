import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VoidFunc } from 'types';

import { jobActions, totalJobsSelector } from 'store/modules/job';
import { AppDispatch } from 'store/root-reducer';
export const useTotalJobs = () => {
  const dispatch = useDispatch<AppDispatch>();
  const options = useSelector(totalJobsSelector);

  const fetchTotalJobs = () => {
    dispatch(jobActions.getTotalJobs());
  };

  useEffect(() => {
    fetchTotalJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result: [number, VoidFunc] = [options, fetchTotalJobs];

  return result;
};
