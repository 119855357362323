import React from 'react';
import { Center, Text } from '@chakra-ui/react';

export const EllipsisIcon: React.FC = () => {
  return (
    <Center>
      <Text>•••</Text>
    </Center>
  );
};
