import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobTypeState } from './type';

const initialState: JobTypeState = {
  summary: {
    total: 0,
    init: false,
  },
};

export const { actions: jobTypeActions, reducer: jobTypeReducer } = createSlice({
  name: 'jobType',
  initialState,
  reducers: {
    updateSummary(state, action: PayloadAction<Omit<JobTypeState['summary'], 'init'>>) {
      const { payload } = action;

      state.summary = {
        ...payload,
        total: payload.total,
        init: false,
      };
    },
  },
});

export * from './type';
export * from './selector';
