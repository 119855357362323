import { mode } from '@chakra-ui/theme-tools';

import { Checkbox } from './checkbox';

const baseStyleControl = (props: any) => {
  const { colorScheme: c } = props;
  return {
    cursor: 'pointer',
    boxSize: '40px',
    border: '2px solid',
    borderColor: 'secondary',
    bg: 'white',
    borderRadius: '10px',
    _before: {
      content: `""`,
      display: 'inline-block',
      pos: 'relative',
      w: 5,
      h: 5,
      borderRadius: '50%',
      border: '2px solid',
      borderColor: 'inherit',
      color: 'white',
    },
    _checked: {
      borderColor: 'secondary',
      bg: 'white',
      _hover: {
        borderColor: 'secondary',
        bg: 'white',
      },
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        border: '2px solid',
        borderRadius: '50%',
        w: 5,
        h: 5,
        bg: mode(`${c}.500`, `${c}.200`)(props),
        borderColor: mode(`${c}.500`, `${c}.200`)(props),
        // color: mode('white', 'gray.900')(props),
      },
    },
    _focus: {
      boxShadow: 'none',
    },
  };
};

const baseStyle = (props: any) => ({
  label: Checkbox.baseStyle(props).label,
  control: baseStyleControl(props),
});

const sizes = {
  md: {
    control: { w: '40px', h: '40px' },
    label: { fontSize: { base: 'sm', md: 'md', lg: 'sm-md', '2xl': 'md' } },
  },
  lg: {
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' },
  },
  sm: {
    control: {
      width: '35px',
      height: '35px',
      borderRadius: '8px',
      _before: {
        w: '18px',
        h: '18px',
      },
      _checked: {
        _before: {
          w: '18px',
          h: '18px',
        },
      },
    },
    label: { fontSize: 'md' },
  },
  ss: {
    control: {
      width: '30px',
      height: '30px',
      borderRadius: '6px',
      _before: {
        w: '14px',
        h: '14px',
      },
      _checked: {
        _before: {
          w: '14px',
          h: '14px',
        },
      },
    },
    label: { fontSize: 'sm' },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'vulcan',
};

export const Radio = {
  parts: ['container', 'label', 'control'],
  baseStyle,
  sizes,
  defaultProps,
};
