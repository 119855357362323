import { omit } from '@chakra-ui/utils';
import dayjs from 'dayjs';
import { CachedFilter, Option, Query } from 'types';

import { PaginationState } from 'components/molecules';
import { Sorter } from 'components/organisms/Table';

import { isNullable } from './validation';

export const parseToJSON = (stringify: string): any | null => {
  try {
    if (stringify === null) {
      throw new Error('Cannot parse JSON data of: ' + stringify);
    }
    return JSON.parse(stringify);
  } catch (error: any) {
    console.error(`[getJSON] ${error.message}`);
    return null;
  }
};

const remove = <T extends object>(condition: (data: any) => boolean, data: T): T => {
  try {
    const result = Object.entries(data);
    return result.reduce((acc, curr) => {
      const [key, data] = curr;
      if (condition(data)) {
        acc[key] = data;
      }
      return acc;
    }, {} as any);
  } catch (error) {
    return data;
  }
};

export const removeNullish = <T extends object>(data: T): T => {
  return remove((item) => item !== null && item !== undefined, data);
};

export const removeEmpty = <T extends object>(data: T): T => {
  return remove((item) => item !== '', data);
};
export const removeFalsy = <T extends object>(data: T): T => {
  return remove((item) => !!item, data);
};
export const removeNullishAndEmpty = <T extends object>(data: T): T => {
  return remove((item) => ![null, undefined, ''].includes(item), data);
};

export const parseToFormData = (
  obj: Record<string, string | FileList | File | number | undefined | null>,
) => {
  const formData = new FormData();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      if (element !== null && element !== undefined) {
        if (element instanceof FileList) {
          const file = element.item(0);
          file && formData.append(key, file, file.name);
        } else if (element instanceof File) {
          formData.append(key, element, element.name);
        } else {
          formData.append(key, element.toString());
        }
      }
    }
  }
  return formData;
};

export const getFilenameFromUrl = (url: string) => {
  // eslint-disable-next-line no-useless-escape
  const matches = url.match(/\/([^\/?#]+)[^\/]*$/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return undefined;
};

export const calcPersonAge = (birthDate: string) => {
  return dayjs().diff(birthDate, 'years');
};

export function capitalizeFirstLetter(string?: string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractErrorValidation = (data?: string): Record<string, string[]> | null => {
  if (!data) return null;
  const result: Record<string, string[]> = parseToJSON(data);
  if (!result) return null;
  return result;
};

export const booleanToBit = (value: boolean) => (value === true ? 1 : 0);

export const mapQuery = (query: Query) => {
  const { filter, sort, search } = query;
  return {
    filter: filter ?? undefined,
    sort: sort
      ? {
          sort_field: sort?.field || sort?.key,
          sort_order: sort.order,
        }
      : undefined,
    search: search ?? undefined,
  };
};

export const mapSorterToParams = (sort?: Sorter) => {
  return sort
    ? {
        sort_field: sort?.field || sort?.key,
        sort_order: sort.order,
      }
    : undefined;
};

export const recoveryOptions = (
  cookedStr?: string | null,
  src?: Option[],
): Option[] | undefined => {
  if (!cookedStr || !src) return undefined;
  const result = cookedStr
    .split(',')
    .map((item) => src.find((option) => option.value === item))
    .filter((item) => item) as Option[];

  return result;
};

export const mapCachedFilter = (cachedFilter?: CachedFilter) => {
  if (!cachedFilter) return null;
  return { pagination: cachedFilter.pagination, query: mapQuery(cachedFilter.query ?? {}) };
};

export const hasFilter = (
  query: Query & { [key: string]: any },
  pagination: PaginationState,
  additionCondition?: (item: any) => boolean,
) => {
  const omittedSorter = omit(query, ['sort']);

  if (pagination.current !== 1) return false;

  return Object.values(omittedSorter).some((item) => {
    if (additionCondition && additionCondition(item)) {
      return true;
    }
    return !isNullable(item, { includeEmpty: true });
  });
};

export const removeMultipleWhitespace = (str?: string | null) => {
  if (!str) return str;
  return str.replace(/\s\s+/g, ' ');
};
