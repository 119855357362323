import { RootState } from 'store/root-reducer';

export const locationProfileSummarySelector = ({ locationProfile }: RootState) =>
  locationProfile.summary;
export const locationProfileInfoSelector = ({ locationProfile }: RootState) =>
  locationProfile.locationProfileInfo;

export const isFetchingSummarySelector = ({ locationProfile }: RootState) =>
  locationProfile.fetchSummaryStatus === 'pending';
export const isFetchingProfileInfoSelector = ({ locationProfile }: RootState) =>
  locationProfile.fetchLocationProfileStatus === 'pending';
export const assignedLocationSelector = ({ locationProfile }: RootState) =>
  locationProfile.locationProfileInfo?.location_user_location;
