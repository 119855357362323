import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { locationProfileApi } from 'apis/location-profile';

export const getLocationProfileInfo = createAsyncThunk(
  'locationProfile/getLocationProfileInfo',
  async () => {
    const res = await locationProfileApi.getProfileInfo();
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { locationProfile } = getState() as RootState;
      if (locationProfile.fetchLocationProfileStatus === 'pending') {
        return false;
      }
    },
  },
);
