import {
  Box,
  BoxProps,
  Center,
  HStack,
  Image,
  ImageProps,
  Input,
  InputProps,
  useBoolean,
  useConst,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import uuid from 'lodash/uniqueId';
import React, { useEffect, useRef, useState } from 'react';

import { colors } from 'themes/foundations/colors';

import { Loading } from 'components/molecules';

import { useTranslate } from 'hooks/common';

import { ExternalLinkIcon, UploadIcon } from 'assets/icons';

import { Typography } from './Typography';

export interface UploadButtonProps
  extends InputProps,
    Pick<BoxProps, 'w' | 'h' | 'borderRadius' | 'bg' | 'boxSize'> {
  allowFileExtensions?: string[];
  previewImage?: boolean;
  placeholder?: string;
  resetFlag?: number;
  defaultImage?: string;
  defaultFileName?: string;
  afterUploadLabel?: string;
  defaultFile?: FileList;
  isUploadLogo?: boolean;
  isUploadFile?: boolean;
  styleProps?: BoxProps;
  labelStyles?: BoxProps;
  isViewFile?: boolean;
  defaultFileUrl?: string;
  defaultFileUrls?: string[];
  isDisabled?: boolean;
  isIconUpload?: boolean;
  hasBase64?: boolean;
  imageCover?: ImageProps['objectFit'];
  isViewOnly?: boolean;
}

export const UploadButton = React.forwardRef<HTMLInputElement, UploadButtonProps>(
  (
    {
      w,
      h,
      boxSize,
      bg = 'white',
      borderRadius,
      allowFileExtensions,
      previewImage,
      placeholder,
      isUploadLogo,
      isUploadFile,
      resetFlag = 0,
      defaultImage,
      defaultFileName,
      defaultFileUrl,
      defaultFileUrls,
      afterUploadLabel,
      defaultFile,
      isViewFile,
      isDisabled,
      hasBase64 = true,
      onChange = noop,
      onBlur = noop,
      styleProps,
      labelStyles,
      isIconUpload,
      imageCover,
      isViewOnly,
      ...props
    },
    ref,
  ) => {
    const id = useConst(props.id ?? uuid('file-input'));
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [fileList, setFileList] = useState<FileList | null>(null);
    const [loading, { on, off }] = useBoolean();
    const t = useTranslate();
    const [imageBase64, setImageBase64] = useState<string | null>(null);
    const allowFilesString = allowFileExtensions
      ? allowFileExtensions.map((item) => `.${item}`).join(',')
      : undefined;

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (isViewOnly) return;
      const { target } = e;

      setFileList(e.target.files);

      const file = target.files?.item(0);
      setFile(file ?? null);
      onChange(e);
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      if (isViewOnly) return;
      onBlur(e);
    };

    const handleClick = () => {
      if (isViewOnly) return;
      inputRef.current && inputRef.current.click();
    };

    // const handleViewFile = () => {
    //   const a = document.createElement('a');
    //   a.target = '_blank';
    //   if (defaultFileUrl && !file) {
    //     a.href = defaultFileUrl;
    //   } else if (file) {
    //     const url = URL.createObjectURL(file);
    //     a.href = url;
    //   }
    //   a.click();
    // };

    const fileListLength = fileList
      ? fileList.length
      : defaultFileUrls
      ? defaultFileUrls.length
      : defaultFileUrl
      ? 1
      : 0;

    useEffect(() => {
      const fileReader = new FileReader();
      if (file && previewImage && hasBase64) {
        on();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          off();
          const result = fileReader.result;
          if (result && isUploadLogo) {
            setImageBase64(result.toString());
          }
        };
      } else {
        if (hasBase64) {
          setImageBase64(null);
        }
      }
      return () => {
        if (previewImage) {
          fileReader.abort();
          off();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file, hasBase64]);

    useEffect(() => {
      if (resetFlag > 0) {
        setFile(null);
        setFileList(null);
      }
    }, [resetFlag]);

    useEffect(() => {
      const element = document.getElementById(id) as HTMLInputElement;
      if (element) {
        inputRef.current = element;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (defaultFile) {
        setFileList(defaultFile);
      }
    }, [defaultFile]);

    // useEffect(() => {
    //   if (defaultFile) {
    //     setFile(defaultFile.item(0));
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const renderLabel = () => {
      if (file) return afterUploadLabel ?? file.name ?? t('placeholder.uploaded');
      if (defaultFileUrl) return afterUploadLabel ?? placeholder ?? t('placeholder.uploaded');
      return placeholder ?? t('placeholder.upload');
    };

    const controlStyles = () => {
      let defaultStyles = {
        opacity: 1,
        cursor: isViewOnly ? 'default' : 'pointer',
      };
      if (isDisabled) {
        defaultStyles.opacity = 0.5;
        defaultStyles.cursor = 'not-allowed';
      }
      return defaultStyles;
    };

    const widthStyle = () => {
      if (w) return w;
      if (defaultFileUrl) return '100%';
      return '100%';
    };

    return (
      <HStack spacing="5px">
        <Box
          {...controlStyles()}
          borderRadius={borderRadius}
          overflow="hidden"
          w={widthStyle()}
          boxSize={boxSize}
          h={h}
          bg={bg}
          minW={isUploadFile ? { base: '90%', md: '180px', lg: '200px', '2xl': '220px' } : ''}
          position="relative"
          onClick={handleClick}
          border={imageBase64 || defaultImage ? 'none' : `2px dotted ${colors.tango[300]}`}
          {...styleProps}
        >
          {!imageBase64 &&
            (isUploadLogo || isUploadFile) &&
            !defaultImage &&
            (isUploadFile ? (
              <Center boxSize="100%">
                <Typography.Text
                  variant={file || defaultFileUrl ? 'bold' : undefined}
                  fontStyle="italic"
                >
                  {renderLabel()}
                </Typography.Text>
              </Center>
            ) : (
              <Center boxSize="100%" flexDirection="column">
                {!isIconUpload && (
                  <Center
                    w={{ base: '30px', lg: '35px' }}
                    h={{ base: '30px', lg: '35px' }}
                    borderRadius="5px"
                    border={`2px solid ${colors.tango[300]}`}
                  >
                    <UploadIcon />
                  </Center>
                )}
                <Typography.Text textAlign="center" fontStyle="italic" {...labelStyles}>
                  {placeholder || t('placeholder.uploadLogo')}
                </Typography.Text>
              </Center>
            ))}
          {previewImage && isUploadLogo && (
            <Loading boxSize="100%" loading={loading}>
              {(imageBase64 || defaultImage) && (
                <Image
                  title={file?.name}
                  borderRadius={borderRadius}
                  boxSize="100%"
                  src={imageBase64 ?? defaultImage}
                  objectFit={imageCover}
                />
              )}
            </Loading>
          )}
          {!previewImage && (placeholder || file || defaultFileName) && (
            <Center boxSize="100%" position="absolute" px="4px">
              <Typography.Text
                color="white"
                whiteSpace="nowrap"
                title={file?.name || defaultFileName}
                isTruncated
                fontWeight={placeholder ? 'bold' : ''}
                fontSize={{ base: 'md', md: 'sm', lg: '' }}
                {...labelStyles}
              >
                {file?.name || defaultFileName || placeholder}
              </Typography.Text>
            </Center>
          )}
          <Input
            id={id}
            visibility="hidden"
            onChange={handleChange}
            onBlur={handleBlur}
            as="input"
            type="file"
            w="100%"
            h="100%"
            position="absolute"
            top="0"
            left="0"
            accept={allowFilesString}
            opacity={0}
            ref={ref}
            disabled={isDisabled}
            {...props}
          />
        </Box>
        {/* {isViewFile && !imageBase64 && (file || defaultFileUrl) && (
          <Box
          // {...controlStyles()}
          >
            <ExternalLinkIcon onClick={handleViewFile} />
          </Box>
        )} */}
        {isViewFile && !imageBase64 && (fileList || defaultFileUrl || defaultFileUrls) && (
          <Box>
            {Array.from(Array(fileListLength).keys()).map((index) => (
              <Box key={index}>
                <ExternalLinkIcon
                  width="20px"
                  height="20px"
                  onClick={() => {
                    const a = document.createElement('a');
                    a.target = '_blank';
                    if (!fileList) {
                      if (defaultFileUrls) {
                        a.href = defaultFileUrls[index];
                      } else if (defaultFileUrl) {
                        a.href = defaultFileUrl;
                      }
                    } else if (fileList) {
                      const file = fileList.item(index);
                      if (file) {
                        const url = URL.createObjectURL(file);
                        a.href = url;
                      }
                    }
                    a.click();
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
      </HStack>
    );
  },
);

UploadButton.displayName = 'UploadButton';
