import { ComponentStyleConfig } from '@chakra-ui/react';
import { createResponsiveStyle } from 'themes/foundations/breakpoints';

export const Tabs: ComponentStyleConfig = {
  variants: {
    noStyle: {
      tablist: {
        justifyContent: 'space-between',
        p: '0',
      },
      tab: {
        borderRadius: 'unset',
        fontWeight: 'bold',
        color: 'secondary',
        fontSize: createResponsiveStyle({ base: 'sm-lg', '2xl': 'lg' }),
        px: '0px',
        py: '0px',
        _selected: {
          color: 'secondary',
          bg: 'unset',
          borderColor: 'unset',
        },
        _focus: {
          borderColor: 'unset',
          boxShadow: 'unset',
        },
      },
      tabpanel: {
        p: '0',
      },
    },
  },
};
