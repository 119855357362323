import React from 'react';
import { Box, Center, Spinner, SpinnerProps, Text, VStack, BoxProps } from '@chakra-ui/react';

export type LoadingProps = {
  loading?: boolean;
  tip?: string;
  spinnerProps?: SpinnerProps;
} & BoxProps;

export const Loading: React.FC<LoadingProps> = ({
  children,
  loading = true,
  tip,
  spinnerProps,
  ...props
}) => {
  const loadingStyles: BoxProps = {
    opacity: 0.5,
    position: 'relative',
    _before: {
      content: '""',
      position: 'absolute',
      inset: 0,
      bgColor: 'tango.100',
      opacity: 0.4,
      zIndex: 'overlay',
    },
  };
  return (
    <Box className="Loading" position="relative" {...props}>
      <Box
        className="loading__opacity-layer"
        boxSize="100%"
        transition="opacity 200ms ease"
        {...(loading ? loadingStyles : undefined)}
      >
        {children}
      </Box>
      {loading && (
        <Box position="absolute" inset={0}>
          <Center boxSize="100%">
            <VStack color={spinnerProps?.color || 'tango.700'}>
              <Spinner
                thickness="3px"
                emptyColor="gray.200"
                speed="0.65s"
                size={'lg'}
                {...spinnerProps}
              />
              {tip && <Text>{tip}</Text>}
            </VStack>
          </Center>
        </Box>
      )}
    </Box>
  );
};
