export const addNumberSymbolPrefix = (value: number) => {
  if (value === 0) return value.toString();

  return value > 0 ? `+${value.toLocaleString('en-US')}` : value.toLocaleString('en-US');
};

export const getFloatPartPercent = (value: number) => {
  const buff = Math.round(value * 100);
  const percentPart = buff.toString().slice(-2);
  return +percentPart;
};
