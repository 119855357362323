import { Button } from './button';
import { Checkbox } from './checkbox';
import { FormControl, FormError, FormLabel } from './form';
import { Input } from './input';
import { Modal } from './modal';
import { Popover } from './popover';
import { Radio } from './radio';
import { Select } from './select';
import { Table } from './table';
import { Tabs } from './tabs';
import { Tag } from './tag';
import { Text } from './text';
import { Textarea } from './text-area';

export const components = {
  Button,
  Text,
  Tag,
  Textarea,
  Select,
  Table,
  Modal,
  Checkbox,
  Tabs,
  Radio,
  FormError,
  FormLabel,
  Input,
  Form: FormControl,
  Popover,
};
