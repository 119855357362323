import axios, { Canceler } from 'axios';
import noop from 'lodash/noop';
import { useEffect, useRef } from 'react';
import { DataResponse } from 'types';

import { useDeepMemo, useMergedState } from 'hooks/common';

type State<T extends object | null> = {
  loading: boolean;
  error: any;
  data: null | T;
};

export const useMutation = <
  D extends object | null = any,
  P extends object = any,
  F extends (...any: any[]) => any = any,
>(
  queryFnc: F,
  queryPayload?: P,
  config?: {
    needCancel?: boolean;
    intervalTime?: number; // ms
    onSuccess?: (values: DataResponse<D>) => void;
    onError?: (values: DataResponse<D>) => void;
  },
) => {
  const { needCancel, intervalTime, onSuccess = noop, onError = noop } = config || {};
  const canceler = useRef<Canceler | null>(null);

  const interval = useRef<number | null>(null);

  const [state, stateController, stateRef] = useMergedState<State<DataResponse<D>>>({
    loading: false,
    error: null,
    data: null,
  });

  const memoizedPayload = useDeepMemo(() => queryPayload, queryPayload);

  const mutate = async (args?: typeof queryPayload): Promise<DataResponse<D>> => {
    stateController.update({ loading: true, error: null });
    try {
      const payload: any = args || queryPayload;
      if (needCancel) {
        payload.cancelToken = new axios.CancelToken(function executor(c) {
          canceler.current = c;
        });
      }
      const res = await queryFnc(payload);
      onSuccess(res);
      stateController.update({ error: null, loading: false, data: res });
      return res;
    } catch (error: any) {
      onError(error);
      stateController.update({ error, loading: false });
      return Promise.reject(error);
    }
  };

  const cancel = (message?: string) => {
    canceler.current && canceler.current(message);
  };

  const stopInterval = () => {
    interval.current && clearInterval(interval.current);
  };

  useEffect(() => {
    if (intervalTime) {
      interval.current = setInterval(mutate, intervalTime, memoizedPayload) as unknown as number;
    }
    return () => {
      if (interval.current !== null) {
        clearInterval(interval.current as any);
        interval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalTime, memoizedPayload]);


  const tool = { ...stateController, cancel, stopInterval, ref: stateRef };

  const result: [F, boolean, typeof state.data, any, typeof tool] = [
    mutate as F,
    state.loading,
    state.data,
    state.error,
    tool,
  ];

  return result;
};
