import { Box, BoxProps, Center, Grid, GridItem, Icon, Portal, useBoolean } from '@chakra-ui/react';
import React, { FC, ReactNode, Fragment, useEffect, useMemo } from 'react';
import { isFirefox } from 'react-device-detect';
import { DeviceMode } from 'types';
import useResizeObserver from 'use-resize-observer';
import { isNotSupportBackdropFilter } from 'utils';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';
import { colors } from 'themes/foundations/colors';
import { zIndices } from 'themes/foundations/z-index';

import { Card } from 'components/atoms';
import { Header } from 'components/organisms/Header';
import { SideBar } from 'components/organisms/SideBar';

import { useDeviceMode } from 'hooks/common';

import { MenuDashCloseIcon, MenuDashIcon } from 'assets/icons';

import { LAYOUT_CONFIG } from 'configs/layout';

import { addHexOpacity } from 'utils/opacity';

export const MainLayoutContext = React.createContext<{
  visibleMenu?: boolean;
}>({});

interface MainLayoutProps {
  children?: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = (props) => {
  const device = useDeviceMode();
  const { ref, height: sidebarHeight = 0 } = useResizeObserver();
  const [visibleMenu, { off: hideMenu, toggle: toggleMenu }] = useBoolean();
  const floatMenuWidth = device === DeviceMode.Mobile ? '100vw' : '400px';

  const layoutMinHeight = useMemo(() => {
    if (device === DeviceMode.Desktop) return '100vh';
    if (sidebarHeight < window.screen.height) return '100vh';
    return `${sidebarHeight + 80}px`;
  }, [device, sidebarHeight]);

  const floatMenuProps: BoxProps = {
    className: 'float-menu',
    w: floatMenuWidth,
    position: 'absolute',
    minHeight: device === DeviceMode.Mobile ? '100vh' : undefined,
    top: 0,
    left: `-${floatMenuWidth}`,
    // zIndex: 'modal',
    zIndex: 1800,
    pt: '80px',
    borderRadius: device === DeviceMode.Tablet ? '0 0 20px 20px' : undefined,
    transition: 'transform  0.2s ease-in',
    transform: visibleMenu ? `translateX(${floatMenuWidth})` : 'translateX(0)',
  };

  const NavMenuWrapper = device !== DeviceMode.Desktop ? Portal : Fragment;

  const context = useMemo(() => {
    return {
      visibleMenu,
    };
  }, [visibleMenu]);

  const handlePreventPropagation: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('click', hideMenu);
    return () => {
      document.removeEventListener('click', hideMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayoutContext.Provider value={context}>
      <Grid
        className="Main"
        pl={{ base: 0, lg: LAYOUT_CONFIG.gap }}
        pr={{ base: 0, lg: '1.25rem', '2xl': '1.5rem' }}
        pb={{ base: 0, lg: '22px' }}
        minHeight={layoutMinHeight}
        maxHeight={device === DeviceMode.Mobile && visibleMenu ? `${sidebarHeight}px` : undefined}
        overflow={{ base: 'hidden', lg: 'initial' }}
        templateColumns={{ base: '100%', lg: `repeat(${LAYOUT_CONFIG.columns}, 1fr)` }}
        templateRows={{
          base: 'min-content 0px 1fr',
          md: 'min-content 0px 1fr',
          lg: '75px 1fr',
        }}
        columnGap={{ lg: LAYOUT_CONFIG.gap }}
        position="relative"
      >
        <GridItem
          colSpan={{ base: 1, lg: LAYOUT_CONFIG.columns }}
          py={device !== DeviceMode.Desktop ? '80px' : undefined}
          pb={{ base: '52px', md: '40px', lg: 'initial' }}
        >
          <Header />
        </GridItem>
        <GridItem
          colSpan={LAYOUT_CONFIG.sidebar.colSpan}
          as="aside"
          position="static"
          zIndex="docked"
        >
          <NavMenuWrapper>
            <Box
              onClick={handlePreventPropagation}
              ref={device !== DeviceMode.Desktop ? ref : undefined}
              {...(device !== DeviceMode.Desktop ? floatMenuProps : undefined)}
            >
              {device !== DeviceMode.Desktop && (
                <Box
                  className="menu-backdrop"
                  position="absolute"
                  inset="0"
                  zIndex="hide"
                  backdropFilter="blur(40px) brightness(110%)"
                  bg={addHexOpacity('#ffffff', isNotSupportBackdropFilter ? 100 : 70)}
                  boxShadow={
                    isNotSupportBackdropFilter
                      ? `0 3px 6px 0 ${addHexOpacity(colors.tango[500], 20)}`
                      : undefined
                  }
                  borderRadius={device === DeviceMode.Tablet ? '0 0 20px 20px' : undefined}
                />
              )}
              <SideBar onChangeMenu={device !== DeviceMode.Desktop ? hideMenu : undefined} />
              {device !== DeviceMode.Desktop && (
                <Center
                  onClick={toggleMenu}
                  position="absolute"
                  top="80px"
                  right="35px"
                  height={device === DeviceMode.Tablet ? '33px' : undefined}
                  transition={'transform  0ms ease-in'}
                  transitionDelay={!visibleMenu ? '0.2s' : undefined}
                  transform={visibleMenu ? 'translateX(0)' : `translateX(95px)`}
                >
                  {visibleMenu ? (
                    <Icon as={MenuDashCloseIcon} boxSize="25px" />
                  ) : (
                    <Icon as={MenuDashIcon} w="30px" h="21px" />
                  )}
                </Center>
              )}
            </Box>
          </NavMenuWrapper>
        </GridItem>
        <GridItem
          colSpan={LAYOUT_CONFIG.main.colSpan}
          as="main"
          position="static"
          zIndex={visibleMenu ? zIndices.docked - 1 : zIndices.docked - 1}
          width={isFirefox ? '100%' : 'initial'}
        >
          <Card
            className="main__content"
            w="100%"
            h="100%"
            p="0"
            bg="tango.100"
            boxShadow={{
              base: `-3px 0px 6px ${colors.tangoOpacity[20]}`,
              md: `-3px -1px 10px ${colors.tangoOpacity[20]}`,
            }}
            borderRadius={createResponsiveStyle({ base: '16px', md: '20px', lg: '0 30px 30px 0' })}
          >
            {props.children}
          </Card>
        </GridItem>
      </Grid>
    </MainLayoutContext.Provider>
  );
};
