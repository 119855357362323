import { createAsyncThunk } from '@reduxjs/toolkit';

import { dashboardApi } from 'apis/dashboard';

export const getAdminLogo = createAsyncThunk('dashboard/getAdminLogo', async () => {
  const res = await dashboardApi.getAdminLogo();
  return res.data.admin_logo_url;
});

export const getCreditOverview = createAsyncThunk('dashboard/getCreditOverview', async () => {
  const res = await dashboardApi.getCreditOverview();
  return res.data;
});
