import { Circle, HStack, StackProps } from '@chakra-ui/react';
import React from 'react';

import { TextProps, Typography } from '.';

export type LegendProps = {
  color: string;
  label: React.ReactNode;
  textProps?: TextProps;
} & StackProps;

export const Legend: React.FC<LegendProps> = ({ color, label, textProps, ...props }) => {
  return (
    <HStack spacing="15px" {...props}>
      <Circle size={{ base: '12px', '2xl': '16px' }} bg={color} />
      <Typography.Text variant="bold" {...textProps}>
        {label}
      </Typography.Text>
    </HStack>
  );
};
