import axios, { Canceler } from 'axios';
import noop from 'lodash/noop';
import { useCallback, useEffect, useRef } from 'react';
import { AsyncReturnType, DataResponse } from 'types';

import { SystemPermission } from 'types';

import { useDeepMemo, useMergedState } from 'hooks/common';
import { useHasPermissions } from 'hooks/components/auth/useHasPermissions';

type State<T> = {
  loading: boolean;
  error: any;
  data: T | null;
};

export const useQuery = <
  D extends object | null = any,
  P extends object | null | string | number = any,
  Func extends (...any: any[]) => any = any,
>(
  defaultPayload: P,
  queryFnc: Func,
  config?: {
    initialData?: D | null;
    preventInitCall?: boolean;
    needCancel?: boolean;
    intervalTime?: number; // ms
    onChange?: (data: D) => void;
    onSuccess?: (res: AsyncReturnType<Func>) => void;
    permission?: SystemPermission | SystemPermission[];
  },
) => {
  const {
    initialData,
    preventInitCall,
    needCancel,
    intervalTime,
    onChange = noop,
    onSuccess = noop,
    permission,
  } = config || {};
  const hasPermission = useHasPermissions(permission);
  const canceler = useRef<Canceler | null>(null);

  const interval = useRef<number>(0);

  const [state, stateController, stateRef] = useMergedState<State<D>>({
    loading: false,
    error: null,
    data: initialData ?? null,
  });

  const memoizedPayload = useDeepMemo(() => defaultPayload, defaultPayload);

  const refetch = async (args: typeof defaultPayload): Promise<DataResponse<D>> => {
    try {
      if (!hasPermission) throw new Error('You do not have permission to access this feature!');
      stateController.update({ loading: true, error: null });
      const payload: any = args;
      if (needCancel) {
        payload.cancelToken = new axios.CancelToken(function executor(c) {
          canceler.current = c;
        });
      }
      const res = await queryFnc(payload);
      onSuccess(res);
      stateController.update({ error: null, data: res.data as D, loading: false });
      return res;
    } catch (error: any) {
      stateController.update({ error, loading: false });
      return error;
    }
  };

  const cancel = (message?: string) => {
    canceler.current && canceler.current(message);
  };

  const stopInterval = () => {
    interval.current && clearInterval(interval.current);
  };

  const updateData = useCallback((updates: Partial<D>) => {
    stateController.update((prev) => ({
      ...prev,
      data: prev.data ? { ...prev.data, ...updates } : null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const replaceData = useCallback((updates: D) => {
    stateController.update((prev) => ({
      ...prev,
      data: updates,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(state.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  useEffect(() => {
    if (!preventInitCall) {
      refetch(defaultPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (intervalTime) {
      interval.current = setInterval(refetch, intervalTime, memoizedPayload) as unknown as number;
    }
    return () => {
      if (interval.current !== null) {
        clearInterval(interval.current);
        interval.current = null as any;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalTime, memoizedPayload])

  const tool = {
    ...stateController,
    cancel,
    stopInterval,
    refetch: refetch as Func,
    ref: stateRef,
    updateData,
    replaceData,
  };

  const result: [typeof state.data, boolean, any, typeof tool] = [
    state.data,
    state.loading,
    state.error,
    tool,
  ];

  return result;
};
