import { Center, GridItem } from '@chakra-ui/react';
import { httpClient } from 'apis';
import { ENV } from 'configs';

import { locationManagerApi } from 'apis/location-manager';

import { useExportQuery, useTranslate } from 'hooks/common';

import i18next from 'configs/i18n';

import { PrimaryButton } from './PrimaryButton';
import { Typography } from './Typography';

export const TermsAndConditionsDownload = () => {
  const t = useTranslate();
  const [exportTermsAndConditions, exportLoadingTermsAndConditions] = useExportQuery(
    locationManagerApi.exportTermsAndConditions,
    () =>
      i18next.language === 'en'
        ? `APTUS-Technologies-Terms-Of-Use-for-Customers.pdf`
        : 'Điều-khoản-&-Điều-kiện-sử-dụng-của-Jod-dành-cho-khách-hàng.pdf',
  );

  return (
    <>
      <GridItem mt={{ base: '15px' }}>
        <Typography.Text
          textAlign={{ base: 'left', lg: 'center' }}
          fontSize="md"
          w="100%"
          maxWidth="fit-content"
          _hover={{ color: 'primary', textDecoration: 'underline' }}
          onClick={async () => {
            try {
              const res = await httpClient.get(
                `portal/dashboard/download-aptus-technologies-terms`,
                {
                  responseType: 'arraybuffer',
                },
              );
              const blob = new Blob([res.data], { type: res.headers['content-type'] });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.target = '_blank';
              a.click();
            } catch (error) {}
          }}
        >
          {ENV.INSTANCE.VIETNAM
            ? t('label.aptusTermsOfUseAndConditionsForCustomers').replace('APTUS', 'Jod')
            : t('label.aptusTermsOfUseAndConditionsForCustomers')}
        </Typography.Text>
      </GridItem>
      <GridItem w={{ base: '100%' }} justifySelf="flex-end">
        <Center h="100%">
          <PrimaryButton
            bg="secondary"
            w={{ base: '100%', md: '148px', '2xl': '140px', lg: '148px', xl: '100px' }}
            onClick={exportTermsAndConditions}
            isLoading={exportLoadingTermsAndConditions}
          >
            {t('button.download')}
          </PrimaryButton>
        </Center>
      </GridItem>
    </>
  );
};
