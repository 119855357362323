import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { firstLocationSelector, locationActions } from 'store/modules/location';
import { AppDispatch } from 'store/root-reducer';
export const useFirstLocation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useSelector(firstLocationSelector);

  useEffect(() => {
    dispatch(locationActions.getFirstLocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return location;
};
