import { Box, Circle, HStack, Stack, StackProps, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { Typography } from 'components/atoms';

type Dataset = { value: number; color?: string; label: string };
export type DonutProps = {
  width: number;
  radius?: number;
  thickness?: number;
  dataset: [Dataset, Dataset];
  animationDuration?: number;
} & StackProps;

const calcStrokeDashOffset = (percent: number, circumference: number) => {
  const diff = percent * circumference;
  return circumference - diff;
};

export const Donut: React.FC<DonutProps> = ({
  thickness = 20,
  dataset,
  animationDuration = 200,
  width,
  ...props
}) => {
  const [wrest, rest] = dataset;

  const radius = Math.min(width * 0.4, props.radius || Number.MAX_SAFE_INTEGER);
  const total = dataset.reduce((acc, curr) => acc + curr.value, 0);
  let wrestPercent = wrest.value / total;
  wrestPercent = isNaN(wrestPercent) ? 0 : wrestPercent;
  const circumference = 2 * Math.PI * radius;

  const [dashOffset, setDashOffset] = useState<number>(calcStrokeDashOffset(0, circumference));

  useEffect(() => {
    setDashOffset(calcStrokeDashOffset(wrestPercent, circumference));
  }, [circumference, wrestPercent]);

  return (
    <Stack direction={{ base: 'column', md: 'row', lg: 'column' }} {...props}>
      <Box className="donut" as="svg" w={width} h={width} transform="rotate(90deg) rotateY(180deg)">
        <Tooltip
          label={`${rest.label}: ${rest.value}`}
          textTransform="capitalize"
          bgColor="vulcanOpacity.90"
          offset={[0, 15]}
        >
          <Box
            as="circle"
            cx="50%"
            cy="50%"
            r={radius}
            stroke="secondary"
            _hover={{
              stroke: 'vulcanOpacity.90',
            }}
            strokeWidth={thickness - 1}
            fill="none"
          />
        </Tooltip>
        <Tooltip
          label={`${wrest.label}: ${wrest.value}`}
          textTransform="capitalize"
          bgColor="tango.600"
          offset={[0, 15]}
        >
          <Box
            as="circle"
            cx="50%"
            cy="50%"
            r={radius}
            stroke="primary"
            _hover={{
              stroke: 'tango.600',
            }}
            strokeWidth={thickness}
            strokeLinecap="round"
            transition={`stroke-dashoffset ${animationDuration}ms linear`}
            strokeDasharray={circumference}
            strokeDashoffset={dashOffset}
            fill="none"
          />
        </Tooltip>
      </Box>

      <Stack
        flex={1}
        width={{ base: '100%', md: 'fit-content', lg: '100%' }}
        direction={{ base: 'row', md: 'column', lg: 'row' }}
        justifyContent={{
          base: 'space-between',
          md: 'center',
          lg: 'space-between',
        }}
        px={{ base: '15px', xl: '20px', '2xl': '10px' }}
        spacing={{ base: '30px', md: '20px', lg: '0' }}
      >
        {dataset.map((item, index) => (
          <HStack key={index} spacing={{ base: '10px', lg: '2px', '2xl': '4px' }}>
            <Circle
              size={{ base: '10px', md: '16px', lg: '12px', '2xl': '16px' }}
              bg={item.color}
            />
            <Typography.Text
              textAlign={{ md: 'start', lg: 'center' }}
              variant="bold"
              fontSize={{ base: 'sm', md: 'md', lg: 'sm-md', '2xl': 'md' }}
              // width={{ base: 'max-content', md: 'min-content' }}
              textTransform="capitalize"
            >
              {item.label}
            </Typography.Text>
          </HStack>
        ))}
      </Stack>
    </Stack>
  );
};