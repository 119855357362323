import { TFunction } from 'i18next';
import { EnglishTranslation } from 'locales';

export const pluralize = (count: number, noun: string, suffix?: string) => {
  return `${noun}${count > 1 ? suffix ?? 's' : ''}`;
};

export const translatedPluralize = (
  count: number,
  key: keyof EnglishTranslation,
  t: TFunction,
  suffix?: string,
) => {
  const string: string = count > 1 ? `${key}${suffix ?? 's'}` : key
  return t(string);
};
