export const createFormData = (
  data: Record<string, string | number | FileList | File | null | undefined>,
) => {
  const formData = new FormData();
  for (const key in data) {
    const value = data[key];
    if (value === undefined || value === null) {
      continue;
    }
    if (typeof value === 'number') {
      formData.append(key, String(value));
    } else if (value instanceof FileList) {
      for (let i = 0; i < value.length; i++) {
        const file = value.item(i);
        if (file) {
          formData.append(key, file);
        }
      }
    } else {
      formData.append(key, value);
    }
  }

  return formData;
};
