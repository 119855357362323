import { PaymentStatus } from 'models/payment';

import { useTranslate } from 'hooks/common';

export const usePaymentStatusLabel = () => {
  const t = useTranslate();
  const PAYMENT_STATUS_LABEL: Record<PaymentStatus, string> = {
    [PaymentStatus.Unprocessed]: t('value.unprocessed'),
    [PaymentStatus.Processed]: t('value.processed'),
    [PaymentStatus.InProgress]: t('value.inProgress'),
  };
  return PAYMENT_STATUS_LABEL;
};
