import { createSlice } from '@reduxjs/toolkit';

import {
  getAllTemplates,
  getAssignedLocationOptions,
  getTotalJobs,
  getTotalJobsHistory,
} from './action';
import { JobState } from './type';

const initialState: JobState = {
  templateOptions: null,
  fetchTemplateStatus: null,
  locationOptions: null,
  fetchLocationStatus: null,
  totalJobs: 0,
  fetchTotalJob: null,
  totalJobsHistory: 0,
  fetchTotalJobHistory: null,
};

const { actions, reducer: jobReducer } = createSlice({
  name: 'job',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTemplates.fulfilled, (state, action) => {
      state.templateOptions = action.payload;
      state.fetchTemplateStatus = action.meta.requestStatus;
    });
    builder.addCase(getAllTemplates.pending, (state, action) => {
      state.fetchTemplateStatus = action.meta.requestStatus;
    });
    builder.addCase(getAllTemplates.rejected, (state, action) => {
      state.fetchTemplateStatus = action.meta.requestStatus;
      if (!state.templateOptions) {
        state.templateOptions = [];
      }
    });

    builder.addCase(getAssignedLocationOptions.fulfilled, (state, action) => {
      state.locationOptions = action.payload;
      state.fetchLocationStatus = action.meta.requestStatus;
    });
    builder.addCase(getAssignedLocationOptions.pending, (state, action) => {
      state.fetchLocationStatus = action.meta.requestStatus;
    });
    builder.addCase(getAssignedLocationOptions.rejected, (state, action) => {
      state.fetchLocationStatus = action.meta.requestStatus;

      if (!state.locationOptions) {
        state.locationOptions = [];
      }
    });

    builder.addCase(getTotalJobs.fulfilled, (state, action) => {
      state.totalJobs = action.payload;
      state.fetchTotalJob = action.meta.requestStatus;
    });
    builder.addCase(getTotalJobs.pending, (state, action) => {
      state.fetchTotalJob = action.meta.requestStatus;
    });
    builder.addCase(getTotalJobs.rejected, (state, action) => {
      state.fetchTotalJob = action.meta.requestStatus;
    });

    builder.addCase(getTotalJobsHistory.fulfilled, (state, action) => {
      state.totalJobsHistory = action.payload;
      state.fetchTotalJobHistory = action.meta.requestStatus;
    });
    builder.addCase(getTotalJobsHistory.pending, (state, action) => {
      state.fetchTotalJobHistory = action.meta.requestStatus;
    });
    builder.addCase(getTotalJobsHistory.rejected, (state, action) => {
      state.fetchTotalJobHistory = action.meta.requestStatus;
    });
  },
});

const jobActions = {
  ...actions,
  getAllTemplates,
  getAssignedLocationOptions,
  getTotalJobs,
  getTotalJobsHistory,
};
export { jobReducer, jobActions };
export * from './type';
export * from './selector';
export * from './action';
