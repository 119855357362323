import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { JSXElementConstructor, ReactElement } from 'react';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';

export const ControlledCheckBox: <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>(
  props: Omit<ControllerProps<TFieldValues, TName>, 'render'> & CheckboxProps,
) => ReactElement<any, string | JSXElementConstructor<any>> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  onChange,
  ...props
}) => {
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => {
        return (
          <Checkbox
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e);
            }}
            isChecked={field.value}
            {...props}
          />
        );
      }}
    />
  );
};
