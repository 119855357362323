import { useMemo } from 'react';

import { DashboardApi, dashboardApi } from 'apis/dashboard';

import { IHiringOverview } from 'models/dashboard';

import { useQuery } from 'hooks/common';

export const useHiringOverview = () => {
  const [data, , , control] = useQuery<IHiringOverview, null, DashboardApi['getHiringOverview']>(
    null,
    dashboardApi.getHiringOverview,
    {
      preventInitCall: false,
    },
  );

  const {
    total_today_hired,
    total_hired_of_current_month,
    total_completed_jobs_of_month,
    hiring_charts,
  } = data || {};

  const hiringData = useMemo(() => {
    return hiring_charts?.map((data) => data.total_hired_staffs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const timeData = useMemo(() => {
    return hiring_charts?.map((data) => [data.month, data.year]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const reFetch = async (locationId?: number) => {
    control.refetch(locationId);
  };

  const result: [
    {
      total_today_hired?: number;
      total_hired_of_current_month?: number;
      total_completed_jobs_of_month?: number;
      hiringData?: number[];
      timeData?: string[][];
    },
    typeof reFetch,
  ] = [
    {
      total_today_hired,
      total_hired_of_current_month,
      total_completed_jobs_of_month,
      hiringData,
      timeData,
    },
    reFetch,
  ];

  return result;
};
