import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  IconButton,
  IconButtonProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';

import { Typography } from 'components/atoms';

export type FunctionIconButtonProps = {
  label?: string;
  px?: BoxProps['px'];
  wrapperProps?: BoxProps;
  labelProps?: CenterProps;
} & IconButtonProps;

export const FunctionIconButton: React.FC<FunctionIconButtonProps> = ({
  label,
  px,
  wrapperProps,
  labelProps,
  ...props
}) => {
  const wordSpacing = useBreakpointValue({ base: 'initial', lg: '-2px' });

  return (
    <Box
      position="relative"
      // pb={label && { base: '20px', lg: '22px' }}
      px={px}
      textAlign="center"
      {...wrapperProps}
    >
      <IconButton colorScheme="tango" variant="iconOutline" {...props} />
      {label && (
        <Center
          // position="absolute"
          // left="0"
          // right="0"
          // bottom="0"
          mt={2}
          {...labelProps}
        >
          <Typography.Text
            variant="italic"
            // textAlign="center"
            // whiteSpace="nowrap"
            lineHeight="1"
            style={{ wordSpacing: wordSpacing }}
          >
            {label}
          </Typography.Text>
        </Center>
      )}
    </Box>
  );
};
