import React, { useEffect, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { RouteConfig } from 'routes';
import { configActions } from 'store/modules/config';
import { AppDispatch } from 'store/root-reducer';
export type ConfigProps = {
  pageTitle?: string;
  pageBreakLineTitle?: RouteConfig['breakLineTitle'];
  children?: ReactNode;
};
export const Config: React.FC<ConfigProps> = ({ pageTitle = '', pageBreakLineTitle, ...props }) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      configActions.setHeaderTitle({
        headerTitleBreakLine: pageBreakLineTitle,
        headerTitle: pageTitle,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{props.children}</>;
};
