import { RootState } from 'store/root-reducer';

export const jobTemplateOptionsSelector = ({ job }: RootState) => job.templateOptions;
export const assignedLocationOptionsSelector = ({ job }: RootState) => job.locationOptions;
export const totalJobsSelector = ({ job }: RootState) => job.totalJobs;
export const totalJobsHistory = ({ job }: RootState) => job.totalJobsHistory;
export const isFetchingAssignedLocationOptionsSelector = ({ job }: RootState) =>
  job.fetchLocationStatus === 'pending';
export const isFetchingJobTemplateOptionsSelector = ({ job }: RootState) =>
  job.fetchTemplateStatus === 'pending';
