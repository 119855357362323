/* eslint-disable no-control-regex */

/* eslint-disable no-useless-escape */
import { isFirefox } from 'react-device-detect';
import isNumeric from 'validator/es/lib/isNumeric';
import isAlpha from 'validator/lib/isAlpha';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

export const isEmpty = (data: string) => {
  return data === '';
};
export const isNullable = (data: any, config?: { includeEmpty?: boolean }) => {
  const condition: any[] = [null, undefined];
  if (config?.includeEmpty) {
    condition.push('');
  }
  return condition.includes(data);
};

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/.test(navigator.userAgent)
  );
};

export const isOdd = (number: number) => Math.abs(number) % 2 === 1;
export const isEven = (number: number) => number % 2 === 0;

export const isDollar = (number: number | string) => {
  const regex = /^-?[0-9]\d*((\.\d{0,2})?)$/;
  return regex.test(number.toString());
};

export const hasWhiteSpace = (value: string) => {
  const regex = /\s/;
  return regex.test(value);
};

export const isSingaporeIdentityId = (ic: string) => {
  let icArray = new Array(9);
  for (let i = 0; i < 9; i++) {
    icArray[i] = ic.charAt(i);
  }
  icArray[1] *= 2;
  icArray[2] *= 7;
  icArray[3] *= 6;
  icArray[4] *= 5;
  icArray[5] *= 4;
  icArray[6] *= 3;
  icArray[7] *= 2;
  let weight = 0;
  for (let i = 1; i < 8; i++) {
    weight += parseInt(icArray[i], 10);
  }
  const offset = icArray[0] === 'T' || icArray[0] === 'G' ? 4 : icArray[0] === 'M' ? 3 : 0;
  let temp = (offset + weight) % 11;
  if (icArray[0] === 'M') temp = 10 - temp;
  const st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  const fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
  const m = ['K', 'L', 'J', 'N', 'P', 'Q', 'R', 'T', 'U', 'W', 'X'];
  let theAlpha;
  if (icArray[0] === 'S' || icArray[0] === 'T') theAlpha = st[temp];
  else if (icArray[0] === 'F' || icArray[0] === 'G') theAlpha = fg[temp];
  else if (icArray[0] === 'M') theAlpha = m[temp];
  return icArray[8] === theAlpha;
};

export const isFIN = (value: string) => {
  const regex = /^[FGM]\d{7}[A-Z]$/i;
  return regex.test(value);
};
export const isNRIC = (value: string) => {
  const regex = /^[ST]\d{7}[A-Z]$/i;
  return regex.test(value);
};

export const isAlphaAndSpace = (value: string) => {
  const regex = /^[a-zA-Z\s]*$/;
  return regex.test(value);
};

export const isNotSupportBackdropFilter = isFirefox;

// export const userNameRegex =
//   /^[a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ ,-/'@]+$/gi;
export const userNameRegex =
  /^[a-záàảãạăắằẳẵặâấầẩẫậđéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵáàảãạăắằẳẵặâấầẩẫậđéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵ ,-/'@]+$/gi;

export const companyNameRegex =
  /^[a-záàảãạăắằẳẵặâấầẩẫậđéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵáàảãạăắằẳẵặâấầẩẫậđéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵ ,-/'@()\d]+$/gi;

export const isEmail = (email: string) => {
  const regex =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

  if (!email) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  const domainParts = address.split('.');
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  if (!regex.test(email)) return false;

  return true;
};

export { isNumeric, isAlphanumeric, isAlpha };
