import { Order, SortParams } from 'types';

import i18next from 'configs/i18n';

export const DEFAULT_PAGE_SIZE = 10;

export const DATE_BE = 'YYYY-MM-DD';
export const MONTH_BE = 'MM-YYYY';
export const DATE_FILE_NAME = 'DD-MM-YYYY';
export const DATE_TIME_BE = 'YYYY-MM-DD HH:mm:ss';

// export const DATE_DISPLAY = 'DD MMM YYYY';

export const dateDisplay = () => {
  return i18next.language === 'en' ? 'DD MMM YYYY' : 'DD/MM/YYYY';
};
// export const DATE_HOUR_A_DISPLAY = 'DD MMM YYYY hh:mm A';
export const dateHourADisplay = () => {
  return i18next.language === 'en' ? 'DD MMM YYYY hh:mm A' : 'DD/MM/YYYY hh:mm A';
};
// export const DAY_DISPLAY = 'DD';
export const HOUR_DISPLAY = 'hh:mm';
export const FULL_HOUR_DISPLAY = 'HH:mm';
export const HOUR_A_DISPLAY = 'hh:mm A';
// export const DATE_HOUR_DISPLAY = 'DD MMM YYYY - hh:mm';
// export const DATE_FULL_HOUR_DISPLAY =
//   i18next.language === 'en' ? 'DD MMM YYYY - HH:mm' : 'DD/MM/YYYY - HH:mm';

export const dateFullHourDisplay = () => {
  return i18next.language === 'en' ? 'DD MMM YYYY - HH:mm' : 'DD/MM/YYYY - HH:mm';
};
export const dateTimeDisplay = () =>
  i18next.language === 'en' ? 'DD MMM YYYY - HH:mm:ss' : 'DD/MM/YYYY - HH:mm:ss';
// export const MONTH_DISPLAY = 'MMM YYYY';
export const monthDisplay = () => {
  return i18next.language === 'en' ? 'MMM YYYY' : 'MM/YYYY';
};

export const DEFAULT_SORT: SortParams = {
  sort_field: 'created_at',
  sort_order: Order.Descend,
};

export const SEARCH_DEBOUNCE_TIME = 500;

export const ACCEPTED_IMG_FILE_TYPES: string[] = ['png', 'jpg', 'jpeg'];

export const LIMIT_NOTI_WORKING_HOURS = 18;

export const DATE_FORMATS = [
  dateDisplay(),
  'DD.MMM.YYYY',
  'DD-MMM-YYYY',

  'DD.MM.YYYY',
  'DD-MM-YYYY',
  'DD MM YYYY',
];
export const DATE_TIME_FORMATS = [
  dateFullHourDisplay(),
  // MM
  'DD.MM.YYYY - H:m',
  'DD-MM-YYYY - H:m',
  'DD MM YYYY - H:m',
  'DD.MM.YYYY H:m',
  'DD-MM-YYYY H:m',
  'DD MM YYYY H:m',

  'DD.MM.YYYY - HH:m',
  'DD-MM-YYYY - HH:m',
  'DD MM YYYY - HH:m',
  'DD.MM.YYYY HH:m',
  'DD-MM-YYYY HH:m',
  'DD MM YYYY HH:m',

  'DD.MM.YYYY - H:mm',
  'DD-MM-YYYY - H:mm',
  'DD MM YYYY - H:mm',
  'DD/MM/YYYY - H:mm',
  'DD.MM.YYYY H:mm',
  'DD-MM-YYYY H:mm',
  'DD MM YYYY H:mm',

  // MMM
  'DD.MMM.YYYY - H:m',
  'DD-MMM-YYYY - H:m',
  'DD MMM YYYY - H:m',
  'DD.MMM.YYYY H:m',
  'DD-MMM-YYYY H:m',
  'DD MMM YYYY H:m',

  'DD.MMM.YYYY - HH:m',
  'DD-MMM-YYYY - HH:m',
  'DD MMM YYYY - HH:m',
  'DD.MMM.YYYY HH:m',
  'DD-MMM-YYYY HH:m',
  'DD MMM YYYY HH:m',

  'DD.MMM.YYYY - H:mm',
  'DD-MMM-YYYY - H:mm',
  'DD MMM YYYY - H:mm',
  'DD.MMM.YYYY H:mm',
  'DD-MMM-YYYY H:mm',
  'DD MMM YYYY H:mm',
  ...DATE_FORMATS,
];

// export const MONTH_FORMATS = [
//   MONTH_DISPLAY,
//   'MMM.YYYY',
//   'MMM-YYYY',
//
//   'MM.YYYY',
//   'MM-YYYY',
//   'MM YYYY',
//   'MM/YYYY',
// ];
