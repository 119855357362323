export const WORK_EXPERIENCES: Record<
  number,
  {
    label: string;
    value: number;
  }
> = {
  0: { label: 'label.lessThan1Years', value: 0 },
  1: {
    label: 'label.between1To2Years',
    value: 1,
  },
  2: {
    label: 'label.moreThan2Years',
    value: 2,
  },
};

export const getWorkExperience = (duration: number) => {
  const lessOne = WORK_EXPERIENCES[0];
  const moreTwo = WORK_EXPERIENCES[2];

  const experience = WORK_EXPERIENCES[duration];

  if (experience) return experience;

  if (duration < lessOne.value) return lessOne;
  if (duration > moreTwo.value) return moreTwo;

  return {
    label: 'value.unknown',
    value: -1,
  };
};
