import { ComponentStyleConfig } from '@chakra-ui/react';

import { zIndices } from 'themes/foundations/z-index';

export const Popover: ComponentStyleConfig = {
  variants: {
    popover: {
      popper: {
        zIndex: zIndices.popover,
      },
    },
  },
};
