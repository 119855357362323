import { ScreenMode } from 'types';

import { useScreenMode } from '.';

export const useIsSmallDesktop = () => {
  const screen = useScreenMode();
  return (
    screen && [ScreenMode.Desktop, ScreenMode.DesktopXl, ScreenMode.DesktopLxl].includes(screen)
  );
};
