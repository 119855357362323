import { useToast } from '@chakra-ui/react';

export const useToasts = (defaultDuration?: number) => {
  const toast = useToast();

  const notiSuccess = (title?: string, duration?: number) => {
    toast({
      title: title ?? 'Success',
      status: 'success',
      position: 'top-right',
      duration: duration ?? defaultDuration ?? 5000,
    });
  };

  const notiFailed = (title?: string, duration?: number) => {
    toast({
      title: title ?? 'Failed',
      status: 'error',
      position: 'top-right',
      duration: duration ?? defaultDuration ?? 5000,
    });
  };

  return { notiSuccess, notiFailed };
};
