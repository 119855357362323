import {
  Box,
  BoxProps,
  Button,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import React, { ReactElement, useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import { colors } from 'themes/foundations/colors';

import { IApplicant } from 'models/applicant';

import { Typography } from 'components/atoms';

import { useTranslate } from 'hooks/common';

import fallbackImage from 'assets/images/fallback.png';

import { TouchFriendlyTooltip } from './TouchFriendlyTooltip';

type props = {
  badges?: IApplicant['jod_badges'];
  children: ReactElement<any, any>;
  direction?: 'column' | 'row';
  iconSizes?: string;
  w?: string;
  h?: string;
  wrapperStyleProps?: BoxProps;
  popoverStyleProps?: BoxProps;
  label?: string;
};

export const BadgeDropdown: React.FC<props> = ({
  children,
  badges,
  direction = 'row',
  iconSizes,
  w,
  h,
  label,
  wrapperStyleProps,
  popoverStyleProps,
  ...props
}) => {
  const initRef = React.useRef<FocusableElement>(null);
  const t = useTranslate();

  const badgeOptions = useMemo(() => {
    return badges?.filter((badge) => badge.status === 1);
  }, [badges]);

  const showBadges = !!badgeOptions?.length;
  const singleBadge = badgeOptions?.length === 1 && badgeOptions[0];

  const sizeBadgesIcon = {
    width: {
      base: iconSizes ?? '40px !important',
      md: iconSizes ?? '50px !important',
      '2xl': iconSizes ?? '60px !important',
    },
    height: {
      base: iconSizes ?? '40px !important',
      md: iconSizes ?? '50px !important',
      '2xl': iconSizes ?? '60px !important',
    },
  };

  return (
    <Box position="relative" w={w} h={h} {...wrapperStyleProps}>
      {children}
      {showBadges && !singleBadge && (
        <Box position="absolute" bottom="0" right="0" zIndex="100" {...popoverStyleProps}>
          <Popover
            placement="bottom-start"
            initialFocusRef={initRef}
            returnFocusOnClose={false}
            closeOnEsc={true}
            closeOnBlur={isMobile ? false : true}
          >
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <Button
                    minW="0"
                    borderRadius="50%"
                    border={`2px dotted ${colors.tango[500]}`}
                    background={colors.white}
                    color={isOpen ? '#C3CAD2' : 'secondary'}
                    whiteSpace="pre-wrap"
                    fontSize={{ base: '9px', md: '11px', '2xl': 'sm' }}
                    _hover={{
                      color: '#C3CAD2',
                    }}
                    _active={{
                      background: colors.white,
                    }}
                    {...sizeBadgesIcon}
                  >
                    {label ?? t('label.assignBadge')}
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  w="auto"
                  maxW={{ base: 'auto', md: '340px' }}
                  _focus={{
                    outline: '2px solid transparent',
                    outlineOffset: '2px',
                    boxShadow: 'transparent',
                  }}
                >
                  <Stack
                    border={`1px solid ${colors.tango[400]}`}
                    p="10px"
                    direction={direction}
                    spacing="25px"
                    borderRadius="5px"
                    flexWrap="wrap"
                  >
                    {badgeOptions.map((badge, idx) => {
                      const defaultStyles = {
                        opacity: 0,
                        transform: `translateY(-10px)`,
                        transition: 'none',
                      };
                      if (isOpen) {
                        defaultStyles.opacity = 1;
                        defaultStyles.transform = 'translateY(0)';
                        defaultStyles.transition = `all .${idx + 3}s linear`;
                      }

                      return (
                        <TouchFriendlyTooltip
                          key={idx}
                          label={
                            <Typography.Title>
                              {badge.label} - {t('label.badge')} : <span>{badge.tooltip}</span>{' '}
                            </Typography.Title>
                          }
                        >
                          <Image
                            fallbackSrc={fallbackImage}
                            src={badge.icon}
                            objectFit="contain"
                            title={!badge.icon ? badge.label : ''}
                            {...sizeBadgesIcon}
                            {...defaultStyles}
                          />
                        </TouchFriendlyTooltip>
                      );
                    })}
                  </Stack>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Box>
      )}
      {showBadges && singleBadge && (
        <Box
          background={colors.white}
          position="absolute"
          bottom="0"
          right="0"
          borderRadius="50%"
          {...popoverStyleProps}
        >
          <TouchFriendlyTooltip
            label={
              <Typography.Title>
                {singleBadge.label} - {t('label.badge')} : <span>{singleBadge.tooltip}</span>{' '}
              </Typography.Title>
            }
          >
            <Image
              src={singleBadge.icon}
              fallbackSrc={fallbackImage}
              objectFit="contain"
              title={!singleBadge.icon ? singleBadge.label : ''}
              {...sizeBadgesIcon}
            />
          </TouchFriendlyTooltip>
        </Box>
      )}
    </Box>
  );
};
