import React from 'react';
import { Box, StackProps, VStack } from '@chakra-ui/react';

import { CaretDownIcon, CaretUpIcon } from 'assets/icons';

export interface SortProps extends StackProps {
  isAscending: boolean;
  isDescending: boolean;
}
export const Sort: React.FC<SortProps> = ({ isAscending, isDescending, ...props }) => {
  return (
    <VStack spacing="2px" h="100%" justifyContent="center" {...props}>
      <Box color={isAscending ? 'secondary' : 'white'} h="8px">
        <CaretUpIcon className="h-100 w-auto" />
      </Box>
      <Box color={isDescending ? 'secondary' : 'white'} h="8px">
        <CaretDownIcon className="h-100 w-auto" />
      </Box>
    </VStack>
  );
};
