import queryString from 'query-string';
import { useMemo } from 'react';
import { useSearchParams, useParams, useLocation, useNavigate } from 'react-router-dom';

export function useRouter<
  S extends Record<string, any> | null = Record<string, any>,
  P extends Record<string, string | undefined> | string = Record<string, string | undefined>,
  Q extends Record<string, string | string[]> | null = Record<string, string | string[]>,
>() {
  const [searchParams] = useSearchParams();
  const params = useParams<P>();
  const location = useLocation();
  const navigate = useNavigate();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience, add navigate(), pathname at the top level
      navigate: (path: string, state?: any) => {
        const newPath = `${path}?${queryString.stringify(state)}`;
        navigate(newPath, { replace: true });
      },
      replace: (path: string, state?: any) => {
        const newPath = `${path}?${queryString.stringify(state)}`;
        navigate(newPath, { replace: true });
      },
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      query: {
        ...(queryString.parse(searchParams.toString()) as Q), // Convert searchParams to object
      },
      // Include location object so we have access to extra React Router functionality if needed.
      location,
      state: location.state as S | undefined,
      params,
    };
  }, [params, searchParams, location, navigate]);
}
