import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';

import { jobApi } from 'apis/job';

export const getRejectedApplicantTotal = createAsyncThunk(
  'job-applicant/getRejectedApplicantTotal',
  async (jobId?: string | number) => {
    const res = await jobApi.getRejectedApplicantTotal(jobId);
    return res.data;
  },
  {
    condition: (_, { getState }) => {
      const { jobApplicant } = getState() as RootState;
      if (jobApplicant.isFetchingRejectedApplicantTotal === 'pending') {
        return false;
      }
    },
  },
);
