import { Box, Flex, HStack, Spacer, Stack, VStack, useBreakpointValue } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { PermissionGuard } from 'guards';
import { withPermission } from 'hocs/withPermission';
import { useEffect, useState } from 'react';
import { Option } from 'types';
import useResizeObserver from 'use-resize-observer';

import { colors } from 'themes/foundations/colors';

import { JobStatusText } from 'models/job';
import { Role } from 'models/user';

import { PartnerPermission } from 'types';

import { BarChar, Card, Legend, PrimaryButton, SelectBox, Typography } from 'components/atoms';

import { useRouter, useScreenMode, useTranslate } from 'hooks/common';
import { useCheckAllowedRole } from 'hooks/components/auth';
import { useJobOverview } from 'hooks/components/dashboard/useJobOverview';
import { useJobStatusTextList } from 'hooks/components/job';
import { useLocationOptions } from 'hooks/components/location';

import { LAYOUT_CONFIG } from 'configs/layout';

export const JobOverviewWidget = () => {
  const router = useRouter();
  const screen = useScreenMode();
  const t = useTranslate();
  const [location, setLocation] = useState<Option | null>(null);
  const chartFixedHeight = useBreakpointValue(
    {
      base: 235,
      md: 283,
      lg: 283,
      xl: 283,
      lxl: 283,
      '2xl': 283,
    },
    screen,
  );

  const [jobOverview, reFetch] = useJobOverview();

  const {
    total_active_jobs,
    total_open_jobs,
    total_completed_jobs_of_current_month,
    jobData,
    timeData,
  } = jobOverview;
  const translatedTimeData = timeData?.map((item) => {
    const translatedString = String(t(('value.' + item[0].toLowerCase()) as any));
    return [
      translatedString.length > 3
        ? translatedString.slice(0, 2) + translatedString.split(' ').at(-1)
        : translatedString,
      item[1],
    ];
  });
  const canFilterByLocation = useCheckAllowedRole([Role.AreaManager, Role.HqManager]);
  const [locationOptions, loadingOptions] = useLocationOptions(!canFilterByLocation);
  const defaultOption: Option = {
    label: t('placeholder.allLocations'),
    value: 'all',
  };
  const displayOptions: Option[] = [defaultOption, ...(locationOptions ?? [])];

  const { ref, width: chartWidth, height: chartWrapperHeight } = useResizeObserver();

  const jobStatusTextList = useJobStatusTextList();

  const chartHeight = Math.max(chartFixedHeight ?? 0, chartWrapperHeight ?? 0) || undefined;

  const handleGoJobPosting = (status: JobStatusText) => () => {
    router.navigate(PATH_NAME.JOB, {
      jobStatus: jobStatusTextList.find((item) => item.value === status),
    });
  };

  const handleChangeLocation = (value: Option | null) => setLocation(value);

  useEffect(() => {
    if (location) reFetch(location?.value === 'all' ? undefined : location.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Card
      round={LAYOUT_CONFIG.dashboard.round}
      boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
      p={{ base: '20px', lg: '20px 15px 0px 15px', '2xl': '20px 15px 0px 35px' }}
      fitParent
    >
      <VStack align="stretch" h="100%">
        <Stack
          spacing={{ lg: '15px', '2xl': '45px' }}
          direction={{ base: 'column', lg: 'row' }}
          flexWrap={{ lg: 'wrap', '2xl': 'initial' }}
          alignItems={{ md: 'flex-start', lg: 'initial' }}
          justify={{ '2xl': 'space-between' }}
        >
          <Stack
            mb={{ base: '20px', lg: '0' }}
            width={{ base: 'auto', md: '100%', '2xl': 'auto' }}
            direction={{ base: 'column', lg: 'row', '2xl': 'column' }}
            justifyContent={{
              base: 'initial',
              md: 'space-between',
              lg: canFilterByLocation ? 'space-between' : 'center',
              '2xl': canFilterByLocation ? 'initial' : 'center',
            }}
            spacing={{ md: '10px', lg: '25px', '2xl': '0px' }}
          >
            <Typography.Title
              flex={1}
              alignSelf="flex-start"
              fontSize={{ base: 'lg', lg: 'xl' }}
              uppercase
            >
              {t('title.jobOverview')}
            </Typography.Title>
            {canFilterByLocation && (
              <Box maxWidth={{ xl: '200px' }} flexBasis={{ lg: '165px', xl: 'unset' }}>
                <SelectBox
                  width="100%"
                  options={displayOptions ?? undefined}
                  onChange={handleChangeLocation}
                  loading={loadingOptions}
                  initialValue={defaultOption}
                  allowClear={false}
                />
              </Box>
            )}
          </Stack>
          <HStack
            spacing={{ base: '20px', lg: '30px', '2xl': '40px' }}
            justify={{ lg: 'center', '2xl': 'initial' }}
            w={{ lg: '100%', '2xl': 'initial' }}
            pb="10px"
            alignSelf="center"
            alignItems="flex-start"
          >
            <VStack textAlign="center">
              <Typography.Title
                fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                lineHeight="1"
              >
                {total_open_jobs}
              </Typography.Title>
              <Typography.Text
              // whiteSpace="nowrap"
              >
                {t('label.openJobs')}
              </Typography.Text>
            </VStack>
            <VStack textAlign="center">
              <Typography.Title
                fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                lineHeight="1"
              >
                {total_active_jobs}
              </Typography.Title>
              <Typography.Text
              // whiteSpace="nowrap"
              >
                {t('label.activeJobs')}
              </Typography.Text>
            </VStack>
            <VStack
              // ml={{ lg: '20px' }}
              textAlign="center"
            >
              <Typography.Title
                fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                lineHeight="1"
              >
                {total_completed_jobs_of_current_month}
              </Typography.Title>
              {/*<Typography.Text*/}
              {/*  // whiteSpace="nowrap"*/}
              {/*  textAlign="center"*/}
              {/*  position="relative"*/}
              {/*>*/}
              {/*  {t('label.completedJobs')}*/}
              {/*  <Typography.Text position="absolute" left="0" right="0" bottom="-100%">*/}
              {/*    {t('label.currentMonth')}*/}
              {/*  </Typography.Text>*/}
              {/*</Typography.Text>*/}
              <Typography.Text>
                {t('label.completedJobs')}
                <br />
                {t('label.currentMonth')}
              </Typography.Text>
            </VStack>
          </HStack>
        </Stack>
        <Flex
          display={{ base: 'flex', lg: 'none' }}
          flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
          gridGap={{ base: '10px', md: '20px', lg: '20px' }}
          w={{ base: '100%' }}
        >
          <PrimaryButton
            mb={{ base: '5px', lg: 'initial' }}
            w="100%"
            onClick={handleGoJobPosting(JobStatusText.Open)}
          >
            {t('label.openJobs')}
          </PrimaryButton>
          <PrimaryButton w="100%" bg="#6F9C3D" onClick={handleGoJobPosting(JobStatusText.Active)}>
            {t('label.activeJobs')}
          </PrimaryButton>
        </Flex>
        <HStack flex="1" flexDirection={{ base: 'column', lg: 'row' }} align="stretch">
          <Box flex="1" position="relative" ref={ref}>
            <BarChar
              datasets={[
                {
                  name: t('label.completedJobs'),
                  data: jobData ?? [],
                },
              ]}
              labels={translatedTimeData ?? []}
              colors={[colors.primary]}
              stacked
              numOfColumns={1}
              width={chartWidth}
              height={chartHeight}
              hightLightEndLabel
              tooltip={{
                y: {
                  formatter: (val) => {
                    return val.toLocaleString('en-US');
                  },
                },
              }}
            />
          </Box>
          <Stack
            flexDirection={{ base: 'column-reverse', lg: 'column' }}
            w={{ lg: '135px', '2xl': '150px' }}
          >
            <PermissionGuard permissions={PartnerPermission.VIEW_JOB}>
              <Spacer />
              <Flex
                mt={{ base: '15px' }}
                flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
                gridGap={{ base: '10px', md: '20px', lg: '20px' }}
                w={{ base: '100%' }}
                display={{ base: 'none', lg: 'flex' }}
              >
                <PrimaryButton
                  mb={{ base: '5px', lg: 'initial' }}
                  w="100%"
                  onClick={handleGoJobPosting(JobStatusText.Open)}
                >
                  {t('label.openJobs')}
                </PrimaryButton>
                <PrimaryButton
                  w="100%"
                  // bg="secondary"
                  bg="#6F9C3D"
                  whiteSpace="normal"
                  onClick={handleGoJobPosting(JobStatusText.Active)}
                >
                  {t('label.activeJobs')}
                </PrimaryButton>
              </Flex>
            </PermissionGuard>
            <Spacer />
            <Stack
              direction={{ base: 'row', lg: 'column' }}
              spacing={{ base: '10px', '2xl': '10px' }}
              align={{ base: 'center', lg: 'stretch' }}
              py="15px"
              pb={{ lg: '20px' }}
            >
              <Legend
                color={colors.primary}
                label={t('label.completedJobs')}
                spacing={{ base: '5px', lg: '15px' }}
                textAlign={{ base: 'center', md: 'initial' }}
              />
            </Stack>
          </Stack>
        </HStack>
      </VStack>
    </Card>
  );
};

export const JobOverviewWidgetWithPermission = withPermission(
  PartnerPermission.VIEW_JOB_OVERVIEW,
  JobOverviewWidget,
);
